import { axiosInstance, setAccessToken } from "../axios";
import server from "../server";
import { supportsLocalStorage } from "../utils";
import { storage } from "../utils/storage";

const DEFAULT_LANDING_DEBUG = "playhub";

// TODO: remover
// function getDataFromParameters() {
//     const search = window.location.search;
//     const dict = {};

//     search
//         .replace("?", "")
//         .split("&")
//         .forEach((args) => {
//             let split = args.split("=");
//             dict[split[0]] = split[1];
//         });

//     return dict;
// }

// function loadUserFromData(data) {
//     const user = {};
//     const dict = {
//         celular: "celular",
//         cpf: "cpf",
//         email: "email",
//         nome: "full_name",
//         foto: "image"
//     };

//     Object.entries(data).forEach(([k, v]) => {
//         if (k === "token") return;

//         try {
//             user[dict[k]] = decodeURI(v);
//         } catch {
//             console.warn("Invalid URI, forcing replace method;");
//             user[dict[k]] = v.replace("%20", " ").replace("%", "");
//         }
//     });

//     server.token = data["token"];
//     server.user = user;
//     setAccessToken(data["token"]);

//     // Saving
//     if (supportsLocalStorage()) {
//         localStorage.setItem("token", server.token);
//         localStorage.setItem("user", JSON.stringify(user));
//     }

//     window.history.pushState({}, document.title, "/rede");
// }

// function loadUserFromLocalStorage() {
//     try {
//         let token = storage.getToken();
//         let user = JSON.parse(storage.getUser());

//         Object.entries(user).forEach(([k, v]) => {
//             try {
//                 user[k] = decodeURI(v);
//             } catch {
//                 console.warn("Invalid URI, forcing replace method;");
//                 user[k] = v.replace("%20", " ").replace("%", "");
//             }
//         });

//         if (token && user) {
//             server.token = token;
//             server.user = user;

//             setAccessToken(token);
//         }
//     } catch {
//         if (supportsLocalStorage()) {
//             localStorage.removeItem("token");
//             localStorage.removeItem("auto-login");
//             localStorage.removeItem("user");
//         }
//     }
// }

// export function autoLogin() {
//     const data = getDataFromParameters();

//     if ("token" in data) {
//         // Received token in parameters
//         loadUserFromData(data);
//     } else if (supportsLocalStorage()) {
//         // Try to load token from localStorage
//         loadUserFromLocalStorage();
//     }
// }

export function login(agreement_id, username, password) {
    return axiosInstance.post("/users/tokens/?agreement_id=" + agreement_id, {
        username,
        password,
        agreement_id
    })
}
