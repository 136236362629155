import React, { useContext, useEffect, useState } from "react";
import server from "../../../server";
import { axiosInstance } from "../../../axios";
import { useTheme } from "../../../context/index";
import { RateExperience } from "./RateExperienceModal";
import {
    Avatar,
    Heading,
    Image,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalOverlay,
    Progress,
    Spacer,
    Text,
    useToast,
    VStack
} from "@chakra-ui/react";
import { useProfile } from "../../../context/user";
import { APP_EVENTS, spawnAppEvent } from "../../../utils/app";
import Divider from "./subComponents/divider";
import Waves from "../../../components/atoms/Waves";
import TextBox from "./subComponents/textBox";
import { useNavigate } from "react-router-dom";

export const VirtualCard = (props) => {
    let [data, setData] = useState({});
    let [code, setCode] = useState("Gerando");
    let [generatedAt, setGeneratedAt] = useState("");
    let [showRateExperience, setShowRateExperience] = useState(false);
    const btnRef = React.useRef();
    const { primaryColor, primaryFontColor, logo } = useTheme();
    const { photo, name, cpf } = useProfile();

    const toast = useToast()
    const navigate = useNavigate()

    const getCode = () => {
        axiosInstance
            .get("/virtual_card/?discount=" + props.discount.id.toString())
            .then(({ data }) => {
                setCode(data.code);
                setData(data);

                setGeneratedAt(
                    new Date(data.created_at).toLocaleString("pt-BR", {
                        hour12: false
                    })
                );
            })
            .catch(({ response }) => {
                if (response.status === 402) {
                    let a = document.createElement("a");
                    document.body.appendChild(a);
                    a.href = "/sem-vinculo";
                    a.click();
                    document.body.removeChild(a);
                }
            });
    };
    
    const handleClose = (props) => {
        props.onClose();
        setShowRateExperience(false);
        toast({
            title: "Cupom utilizado!",
            description:
                "O valor do desconto foi computado ao seu enconômetro.",
            status: "success"
        });
        navigate({ pathname: '/econometro' })
    }

    useEffect(() => {
        if (!props.isOpen) return;

        getCode();
    }, [props.isOpen]);

    useEffect(() => {
        spawnAppEvent(APP_EVENTS.TOGGLE_SCREENSHOT, false);

        return () => spawnAppEvent(APP_EVENTS.TOGGLE_SCREENSHOT, true);
    }, []);

    if (!props.isOpen) return <div />;

    if (showRateExperience) {
        return (
            <RateExperience
                discount={props.discount}
                company={props.company}
                isOpen={props.isOpen}
                initialValue={props.initialValue}
                virtualCardCode={data.code}
                onClose={() => handleClose(props)}
            />
        );
    }

    if (code === "Gerando") {
        getCode();
    }

    return (
        <Modal
            isCentered
            ref={btnRef}
            isOpen={props.isOpen}
            onClose={() => setShowRateExperience(true)}
            id="virtual-card-modal"
        >
            <ModalOverlay
                backgroundColor="rgba(0,0,0,0.8)"
                style={{
                    backdropFilter: "blur(5px)",
                    "-webkit-backdrop-filter": "blur(5px)"
                }}
            />
            <ModalContent width="fit-content">
                <ModalCloseButton />
                <ModalBody padding="0px">
                    <VStack spacing={5} padding="20px">
                        <Divider/>
                        <Image width="150px" src={logo} />
                        <Avatar size="2xl" src={photo} rounded={false} />
                        <Heading fontSize="16pt">
                            {decodeURIComponent(name)}
                        </Heading>
                        <Heading
                            transform="translateY(-10px)"
                            fontWeight="500"
                            opacity="0.4"
                            fontSize="10pt"
                            zIndex={999}
                        >
                            CPF: {cpf}
                        </Heading>
                        <VStack spacing={4}>
                            <Text
                                fontWeight="bold"
                                color="black"
                                fontSize="10pt"
                                textShadow="0 0 2px #fff"
                                zIndex={999}
                            >
                                Apresente este código no estabelecimento:
                            </Text>
                            <Heading
                                backgroundColor={primaryColor}
                                color={primaryFontColor}
                                fontSize="28pt"
                                borderRadius="5px"
                                padding="10px"
                                zIndex={999}
                            >
                                {code}
                            </Heading>
                            <Heading
                                color="black"
                                fontSize="10pt"
                                fontWeight="bold"
                                zIndex={999}
                                mb="10px"
                            >
                                Cartão gerado em: {generatedAt}
                            </Heading>
                            <Image
                                transform="translateY(-8px)"
                                width="80px"
                                filter="grayscale()"
                                opacity="0.5"
                                src="https://partiu-static.s3.amazonaws.com/Logos/logo.png"
                            />
                        </VStack>
                    </VStack>
                    <Progress
                        colorScheme="green"
                        height="10px"
                        width="100%"
                        isIndeterminate
                    />
                </ModalBody>
            </ModalContent>
        </Modal>
    );
};
