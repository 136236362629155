import {
    Box,
    Button,
    Divider,
    Drawer,
    DrawerBody,
    DrawerCloseButton,
    DrawerContent,
    DrawerHeader,
    DrawerOverlay,
    HStack,
    Image,
    Skeleton,
    Text,
    useDisclosure,
    VStack
} from "@chakra-ui/react";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { useInfiniteQuery, useMutation } from "react-query";
import { Link } from "react-router-dom";
import { useTheme } from "../../context";
import { toBRLDate } from "../../utils/dates";
import { Title } from "../atoms/Title";
import { getNotifications } from "../../api/notifications";

export function MyNotifications() {
    const { primaryColor, localModule, ecommerceModule } = useTheme();
    const { isOpen, onOpen, onClose } = useDisclosure();
    const {
        fetchNextPage,
        isSuccess,
        hasNextPage,
        data,
    } = useInfiniteQuery({
        queryKey: ['my_notifications'], 
        queryFn: ({ pageParam = 1 }) => getNotifications({page: pageParam}),
        getNextPageParam: (lastPage, allPages) => lastPage.length > 0 ? allPages.length + 1 : allPages.length,
        refetchOnWindowFocus: false,
        enabled: false, // TODO: enable when customer team requests it.
    });

    return (
        <>
            <Drawer isOpen={isOpen} onClose={onClose}>
                <DrawerOverlay />
                <DrawerContent>
                    <DrawerHeader paddingTop="50px">
                        Minhas Notificações
                    </DrawerHeader>
                    <DrawerCloseButton paddingTop="60px" />
                    <DrawerBody id="notification-scroller">
                        <Box display="block">
                            <InfiniteScroll
                                scrollableTarget="notification-scroller"
                                dataLength={data && data.pages ? data.pages.flat() : []} //This is important field to render the next data
                                next={fetchNextPage}
                                hasMore={hasNextPage}
                                loader={
                                    <VStack>
                                        {new Array(5).fill(0).map(() => (
                                            <Skeleton
                                                width="100%"
                                                height="100px"
                                            />
                                        ))}
                                    </VStack>
                                }
                                endMessage={
                                    <Title>Não há mais notificações</Title>
                                }
                            >
                                <VStack width="100%" spacing={5}>
                                    {isSuccess && data && data.pages.flat().length === 0 && data.pages.flat().map((d) => (
                                        <VStack
                                            spacing={5}
                                            width="100%"
                                            key={'notifications_' + d.id}
                                        >
                                            <Link
                                                style={{ width: "100%" }}
                                                to={
                                                    d.discount
                                                        ? `/detalhes/${
                                                            d.discount.mode
                                                        }/${
                                                            d.discount
                                                                .mode ===
                                                            "online"
                                                                ? d.discount
                                                                    .company
                                                                    .id
                                                                : d.discount
                                                                    .stores[0]
                                                                    .id
                                                        }/`
                                                        : "#"
                                                }
                                            >
                                                <HStack
                                                    justify="flex-start"
                                                    align="flex-start"
                                                    width="100%"
                                                >
                                                    <Image
                                                        width="90px"
                                                        objectFit="cover"
                                                        height="90px"
                                                        borderRadius="5px"
                                                        src={d.discount.image}
                                                    />
                                                    <VStack justify="flex-start">
                                                        <Title
                                                            fontSize="10pt"
                                                            textAlign="justify"
                                                        >
                                                            {d.title}
                                                        </Title>
                                                        <Text
                                                            width="100%"
                                                            fontSize="7.5pt"
                                                            opacity="0.6"
                                                        >
                                                            {toBRLDate(
                                                                new Date(
                                                                    d.created_at
                                                                )
                                                            )}
                                                        </Text>
                                                    </VStack>
                                                </HStack>
                                            </Link>
                                            <Divider />
                                        </VStack>
                                    ))}
                                </VStack>
                                {isSuccess && data && data.pages.flat().length === 0 && (
                                    <Text>
                                        Não há mais notificações
                                    </Text>
                                )}

                            </InfiniteScroll>
                        </Box>
                    </DrawerBody>
                </DrawerContent>
            </Drawer>
            {localModule || ecommerceModule ?
                <Button onClick={onOpen} variant="solid" backgroundColor={primaryColor} size="sm">
                    <FontAwesomeIcon
                        color="white"
                        opacity="1"
                        style={{ fontSize: "16pt" }}
                        icon={faEnvelope}
                    />
                </Button> : <></>}
        </>
    );
}
