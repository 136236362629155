import { ListItem, UnorderedList } from '@chakra-ui/react';
import React from 'react';

export function ValidationError({error}) {
  const getContent = () => {



    if (typeof(error) === 'string'){
      return (
        <ListItem color={"red"}>
          {error}
        </ListItem>
      )
    }
    
    if (typeof(error) === 'object'){
      return Object.keys(error).map(k => (
        <ListItem key={k} color={"red"}>
          {`${k}: ${Array.isArray(error[k]) ? error[k].join('; ') : error[k]}`}
        </ListItem>
      ))
    }
  }

  return (
    <UnorderedList>
      {getContent()}
    </UnorderedList>
  )
}