import axios from "axios";
import { axiosInstance } from "../axios";
import { withParams } from "./discounts";

export const getState = () => {
    return axiosInstance.get("/states/").then(({ data }) => data);
};

export const getCity = (state = "") => {
    return axiosInstance
        .get(`/cities/?state=${state}`)
        .then(({ data }) => data);
};

export const getNeighborhood = (state = "", city = "") => {
    if (city === "") return {};
    return axiosInstance
        .get(withParams(/neighborhoods/, { state, city }))
        .then(({ data }) => data);
};

export const getCEP = (cep) =>{
    return fetch("https://viacep.com.br/ws/" + cep + "/json/", {
        method: "GET"
    }).then((request) => request.json().then((data) => data))
}

export const getCurrentAddress = (
    latitude,
    longitude
  ) => {
    return axios
      .get(
        `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=AIzaSyB5tXrkiapXmzmvkkW0-7MQvfx1LL9VScQ`
      )
      .then(({ data: address }) => formatAddress(address));
  }

  const formatAddress = (address) => {
    
    const mappedAddress = {
      street: '',
      country: '',
      city: '',
      state: '',
      neighborhood: '',
      cep: '',
      formatted_address: address.results[0].formatted_address
    };

    for (const root of address.results) {
      for (const component of root.address_components) {
          if (mappedAddress.street === '' && component.types.includes('street_number') || component.types.includes('route')) {
              mappedAddress.street = component.long_name;
          } else if (mappedAddress.country === '' && component.types.includes('country')) {
              mappedAddress.country = component.short_name;
          } else if (mappedAddress.city === '' && component.types.includes('locality')) {
              mappedAddress.city = component.long_name;
          } else if (mappedAddress.state === '' && component.types.includes('administrative_area_level_1')) {
              mappedAddress.state = component.short_name;
          } else if (mappedAddress.neighborhood === '' && component.types.includes('sublocality')) {
              mappedAddress.neighborhood = component.long_name;
          } else if (mappedAddress.cep === '' && component.types.includes('postal_code')) {
              mappedAddress.cep = component.long_name;
          } else if (!Object.values(mappedAddress).includes('')) return mappedAddress;
      }
    }

    return mappedAddress;
}