import { axiosInstance, axiosInstanceV2 } from "../axios";

export const checkCPF = async (cpf, agreementId) => {
    return await axiosInstanceV2
        .post(`/agreement/vinculate/?agreement_id=${agreementId}`, {
            cpf: cpf.replace(/\./g, "").replace("-", "").replace("/", "")
        })
};

export function cleanCPFCNPJ(value) {
    return value.replace(/\./g, "").replace("-", "").replace("/", "");
}

export const checkPromocode = (code, agreementId) => {
    return axiosInstance.post(`/validate_promocode/?agreement_id=${agreementId}`, { promocode: code });
};

export const vinculatePromocode = (code) => {
    return axiosInstance.post(`/vinculate_promocode/`, { promocode: code });
};

export const registerUser = (user) => {
    return axiosInstance.post("/users/", user);
};

export const updateRegistration = (user) => {
    return axiosInstance.put("/users/", user);
};

export const sendPartnerEnquiry = (data) => {
    return axiosInstance.post("/enquiry/", data, {
        headers: {
            "Content-Type": "multipart/form-data"
        }
    });
};

export const removeAccount = () => {
    return axiosInstance.delete("/profile/delete_account/");
};
