import React, { useState } from "react";
import { VStack, Box, Image, Button, HStack } from "@chakra-ui/react";
import { useLocation, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose, faChevronLeft, faChevronRight } from "@fortawesome/free-solid-svg-icons";
import AudioPlayer from 'react-h5-audio-player';
import 'react-h5-audio-player/lib/styles.css';
import { useSettings, useTheme } from "../../context";
import { Typography } from "@material-ui/core";

export const DrmAudioBook = () => {
    const { state } = useLocation();
    const navigate = useNavigate();
    const { primaryColor, titleColor } = useTheme();
    const { isApp, isIOS } = useSettings();
    const [currentIndex, setCurrentIndex] = useState(0);

    const audiobooks = state?.audiobooks || [];
    
    const sortedAudiobooks = audiobooks.sort((a, b) => a.position - b.position);

    const handlePrev = () => {
        setCurrentIndex((prevIndex) => Math.max(prevIndex - 1, 0));
    };

    const handleNext = () => {
        setCurrentIndex((prevIndex) => Math.min(prevIndex + 1, sortedAudiobooks.length - 1));
    };

    return (
        <VStack style={{
            height: '100vh',
            overflow: 'scroll',
        }}>
            <Box style={{
                zIndex: 99999,
                left: 0,
                top: 0,
                width: '100vw',
                height: '4vh',
                display: 'flex',
                backgroundColor: primaryColor,
                padding: 4,
            }}
            >
                <Typography style={{
                    width: '100vw',
                    textAlign: 'center',
                    fontFamily: "Open Sans",
                    fontSize: "13pt",
                    fontWeight: "600",
                    color: titleColor,
                    letterSpacing: "0.03rem",
                    userSelect: 'none'
                }}>
                    {state?.title}
                </Typography>
                <Typography
                    onClick={() => navigate(-1)}
                    style={{
                        textAlign: 'center',
                        fontFamily: "Open Sans",
                        fontSize: "13pt",
                        fontWeight: "600",
                        paddingRight: 16,
                        color: titleColor,
                        letterSpacing: "0.03rem",
                        userSelect: 'none',
                        cursor: 'pointer'
                    }}>
                    <FontAwesomeIcon
                        color="rgba(0,0,0,0.75)"
                        size="lg"
                        icon={faClose}
                    />
                </Typography>
            </Box>
            <Image
                src={state?.cover}
                maxH={"400px"}
                maxW={"400px"}
            />
            <Box
                style={{
                    position: 'relative',
                    width: '80vw',
                    height: ((isApp && isIOS)) ? '96vh' : '89vh',
                    marginTop: '4vh',
                    overflowX: 'hidden',
                    display: 'flex',
                    flexDirection: 'column',
                    '&::WebkitScrollbar': {
                        width: '0px',
                        background: 'transparent'
                    },
                    '&::WebkitScrollbarThumb': {
                        background: '#ddd',
                        borderRadius: '10px'
                    },
                    '&::WebkitScrollbarThumb:hover': {
                        background: '#ccc'
                    },
                    '&::WebkitScrollbarTrack': {
                        background: 'transparent'
                    },
                }}
            >
                <AudioPlayer
                    autoPlay
                    src={sortedAudiobooks[currentIndex]?.file}
                    showJumpControls={true}
                    showSkipControls={false}
                    showFilledVolume={true}
                    controlslist={"noremoteplayback"}
                />
                <HStack spacing={4} justifyContent="center" marginTop="2vh">
                    <Button onClick={handlePrev} disabled={currentIndex === 0}>
                        <FontAwesomeIcon icon={faChevronLeft} />
                    </Button>
                    <Typography style={{
                        fontFamily: "Open Sans",
                        fontSize: "13pt",
                        fontWeight: "600",
                        color: titleColor,
                        letterSpacing: "0.03rem",
                        userSelect: 'none'
                    }}>
                        {"Parte " + currentIndex}
                    </Typography>
                    <Button onClick={handleNext} disabled={currentIndex === sortedAudiobooks.length - 1}>
                        <FontAwesomeIcon icon={faChevronRight} />
                    </Button>
                </HStack>
            </Box>
            <Box
                position="fixed"
                zIndex="99999"
                bottom="0"
                left="0"
                width="100%"
                height="4vh"
                display="flex"
                alignItems="center"
                justifyContent="center"
                bgColor="primaryColor"
                padding="4"
            >
            </Box>
        </VStack>
    );
};
