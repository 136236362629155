import { Flex } from "@chakra-ui/react";
import React, { useContext } from "react";
import { useTheme } from "../../context/index";

export function Gradient({
    children,
    Component = Flex,
    forcePrimaryColor,
    forceSecondaryColor,
    ...props
}) {
    const { primaryColor, secondaryColor } = useTheme();

    return (
        <Component
            background={
                "linear-gradient(135deg, " +
                (forceSecondaryColor || secondaryColor) +
                " 0%," +
                (forcePrimaryColor || primaryColor) +
                " 100%)"
            }
            {...props}
        >
            {children}
        </Component>
    );
}
