import { Flex, Image, Tag, VStack } from "@chakra-ui/react";
import React from "react";
import { Title } from "../../../components/atoms/Title";

export function Magazine({ id, articleTitle, cover, onClick}) {
  if(articleTitle === null || cover === null) {
    return <></>
  }
    return (
        <VStack onClick={onClick} align="flex-start" width="100%" cursor="pointer">
            <Flex
                width="100%"
                boxSizing="border-box"
                overflow="hidden"
                className="layered-box"
                backgroundColor="white"
                borderRadius="5px"
                position="relative"
                maxWidth={{ base: "165px", lg: "205px", "2xl": "260px" }}
                height={{ base: "270px", lg: "310px", "2xl": "410px" }}
            >
                <Image
                    src={cover}
                    objectFit={'cover'}
                    objectPosition={"center"}
                    transition="0.5s"
                    width="100%"
                    height="100%"
                    backgroundColor="white"
                    flexShrink="0"
                    _hover={{
                        transform: "scale(1.05)"
                    }}
                />
                {/* <Tag fontWeight={700} colorScheme={"green"} borderRadius={"0px"} position={"absolute"} right={0} bottom={0}>Gratuito</Tag> */}
            </Flex>
            <VStack align="flex-start" paddingTop="5px" spacing={1}>
                <Title fontWeight="bold" fontSize={{ base: "9.5pt", lg: "12pt" }}>{articleTitle.length > 25 ? `${articleTitle?.slice(0, 25).toUpperCase()}...` : articleTitle?.toUpperCase()}</Title>
            </VStack>
        </VStack>
    );
}
