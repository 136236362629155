import { HStack, Spinner, Switch, Text, VStack, toast } from "@chakra-ui/react";
import React, { useState } from "react";
import { useProfile } from "../../../context/user";
import { useMutation } from "react-query";
import { updateMyProfile } from "../../../api/profile";

export function Preferences() {
    const {
        allowedPushNotifications,
        allowedPromotionalEmails,
        allowedWhatsapp,
    } = useProfile();
    

    return (
        <VStack width={{ base: "100%", lg: "92%" }} spacing={5}>
            <Text
                color="#495057"
                fontSize="15px"
                opacity="0.7"
                textAlign="left"
                width="100%"
            >
                Altere regras de notificação e outras configurações extras.
            </Text>
            <TogglePreferences 
                preferenceKey={"allowed_push_notifications"}
                initialState={allowedPushNotifications}
                title={"Push Notification"}
            />
            <TogglePreferences 
                preferenceKey={"allowed_promotional_emails"}
                initialState={allowedPromotionalEmails}
                title={"Emails Partiu"}
            />
            <TogglePreferences 
                preferenceKey={"allowed_whatsapp"}
                initialState={allowedWhatsapp}
                title={"WhatsApp"}
            />
            
            <Text
                align="flex-start"
                width="100%"
                textAlign="left"
                opacity="0.5"
                fontSize="8pt"
            >
                V 3.3.0
            </Text>
        </VStack>
    );
}


const TogglePreferences = ({initialState, preferenceKey, title}) =>{

  const [isChecked, setIsChecked] = useState(initialState);

  const updatePreferenceMutation = useMutation(updateMyProfile, {
      onSuccess: (data) => {
          console.log(data)
          setIsChecked((prev) => !prev)
          // queryClient.invalidateQueries("profile");
      },
      onError: (error) => {
          toast({
              title: "Erro ao atualizar preferências",
              status: "error",
          });
      }
  });

  return (
      <HStack paddingTop="10px" width="100%" spacing={8}>
          {updatePreferenceMutation.isLoading ? (<Spinner size="md" />) : (
            <Switch
              isChecked={isChecked}
              size="md"
              margin="0px"
              onChange={() =>
                  updatePreferenceMutation.mutate({
                      [preferenceKey]: !isChecked
                  })
              }
            />
          )}
          <Text fontFamily="Open Sans" fontSize="18px" fontWeight="500">
              {title}
          </Text>
      </HStack>
  )
}