import React from "react";
import { VStack } from "@chakra-ui/layout";

export const Card = ({ children, hover, ...props }) => (
    <VStack
        padding="20px"
        _hover={
            hover
                ? {
                      boxShadow: "0px 6px 9px rgba(0, 0, 0, 0.25)",
                      transform: "translateY(-3px)"
                  }
                : {}
        }
        alignItems="flex-start"
        borderRadius="5px"
        backgroundColor="#FAFAFA"
        transition="0.2s"
        cursor={hover ? "pointer" : "initial"}
        width="100%"
        boxShadow="0px 3px 6px rgba(0, 0, 0, 0.20)"
        {...props}
    >
        {children}
    </VStack>
);
