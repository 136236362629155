export const APP_EVENTS = {
    OPEN_LINK: "href",
    START: "start",
    SET_USER_ID: "userid",
    TOGGLE_SCREENSHOT: "screenshot",
    REFRESH: "refresh"
};

export function spawnAppEvent(event, data = "") {
    if (window.ReactNativeWebView)
        window.ReactNativeWebView.postMessage(`${event}:::${data}`);
}
