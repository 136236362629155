import {
    CircularProgress,
    OrderedList,
    Popover,
    PopoverArrow,
    PopoverBody,
    PopoverCloseButton,
    PopoverContent,
    PopoverHeader,
    PopoverTrigger,
    Table,
    Tbody,
    Td,
    Text,
    Th,
    Thead,
    Tr,
    VStack
} from "@chakra-ui/react";
import { faQuestionCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Title } from "../../../components/atoms/Title";
import { numberToBRL } from "../../../utils/money";

export function Receipts({ econometer }) {
    if (econometer.isLoading) {
        return <></>;
    }

    const data = econometer.data.progress.reduce((a, b) => {
        const progress = [...b.progress_sales];
        progress.reverse();
        return a.concat(progress);
    }, []);

    data.reverse();

    return (
        <VStack width="100%">
            <Title
                paddingTop="10px"
                paddingLeft="5%"
                width="100%"
                textAlign="left"
            >
                Extrato
            </Title>
            <Popover>
                <PopoverTrigger>
                    <Text
                        textAlign="left"
                        width="100%"
                        color="#495057"
                        opacity="0.5"
                        paddingBottom="10px"
                        fontSize="10pt"
                        paddingLeft="5%"
                        style={{ textDecoration: "underline" }}
                    >
                        Saiba como é calculada sua economia.
                        <FontAwesomeIcon
                            style={{ paddingLeft: "5px" }}
                            icon={faQuestionCircle}
                        />
                    </Text>
                </PopoverTrigger>
                <PopoverContent>
                    <PopoverArrow />
                    <PopoverCloseButton />
                    <PopoverHeader>Cálculo de Economia</PopoverHeader>
                    <PopoverBody fontSize="10pt">
                        <Text textAlign="left" paddingBottom="10px">
                            Sua economia é calculada de 3 formas:
                        </Text>
                        <OrderedList
                            textAlign="left"
                            spacing={2}
                            paddingLeft="5px"
                        >
                            <li>
                                Pelo valor informado do estabelecimento parceiro
                                quando você utilizou o desconto.
                            </li>
                            <li>
                                Pelo valor que você informa (se sobrepõe ao
                                valor dele).
                            </li>
                            <li>
                                Se nenhum dos dois informaram o valor,
                                registramos no Econômetro a economia média do
                                estabelecimento.
                            </li>
                        </OrderedList>
                    </PopoverBody>
                </PopoverContent>
            </Popover>
            <Table>
                <Thead>
                    <Th>Empresa</Th>
                    <Th>Data</Th>
                    <Th>Economizado</Th>
                </Thead>
                <Tbody fontSize="14px">
                    {data.map((d) => (
                        <Tr>
                            <Td>{d.company}</Td>
                            <Td>
                                {new Date(d.created_at).toLocaleDateString(
                                    "pt-BR"
                                )}
                            </Td>
                            <Td textAlign="right">{numberToBRL(d.saved)}</Td>
                        </Tr>
                    ))}
                </Tbody>
            </Table>
        </VStack>
    );
}
