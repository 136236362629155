import { Code, HStack, OrderedList, Text, VStack } from "@chakra-ui/react";
import React from "react";
import { AuthenticationCode } from "./code/Authentication";
import { AutoLoginCode } from "./code/AutoLogin";
import { IFrameCode } from "./code/IFrame";
import { RegistrationCode } from "./code/Registration";
import { SmallTitle, SubTitle } from "./components/Headers";
import { DocumentationMenu } from "./components/Menu";
import { RequestTable } from "./components/RequestTable";
import { DocumentationSection } from "./components/Section";
import { CPFCode } from "./code/CPF";
import { UserWebhook } from "./code/UserWebhook";

export function Documentation() {
    return (
        <HStack>
            <DocumentationMenu />

            <VStack paddingLeft="260px" spacing={0}>
                <DocumentationSection id="api-partiu" title="API Partiu">
                    <Text>
                        Bem vindo à documentação da Partiu Vantagens. O objetivo
                        dessa API é embutir o Clube de Vantagens dentro do seu
                        aplicativo ou site.
                    </Text>
                    <Text>
                        A API da Partiu usa chamadas HTTPS no formato REST
                        codificados em JSON. Dessa forma, é possível consumir a
                        API com qualquer linguagem que tenha capacidade de
                        realizar chamadas HTTPS.
                    </Text>
                    <Text>
                        Para qualquer dúvida, ou para obter seu Token de Acesso,
                        entre em contato com o nosso suporte:
                        suporte@partiu.com.br
                    </Text>
                </DocumentationSection>
                <DocumentationSection
                    id="api-authentication"
                    codeChildren={<AuthenticationCode />}
                    title="Autenticação"
                >
                    <Text>
                        A autenticação é feita por meio de um Token que é
                        fornecido pelo nosso suporte.
                    </Text>
                    <Text>
                        O Token deve ser passado no header da requisição, na
                        chave 'Authorization'
                    </Text>
                </DocumentationSection>
                <DocumentationSection id="api-homologation" title="Homologação">
                    <Text>
                        A Partiu disponibiliza uma ambiente de testes para que
                        os desenvolvedores possam testar as funcionalidades
                        antes de subir para produção. Para usar este ambiente de
                        testes basta trocar os links de
                        https://api.partiu.com.br para
                        https://api.staging.partiu.com.br.
                    </Text>
                    <Text>
                        Cada ambiente necessita de um Token de Acesso diferente,
                        tenha certeza de que foram passados todos os Tokens na
                        fase de acompanhamento.
                    </Text>
                </DocumentationSection>
                <DocumentationSection
                    id="api-verification"
                    title="API de Verificação"
                    codeChildren={<CPFCode />}
                >
                    <Text>
                        A API de Verificação é <b>voltada para sistemas PDV</b>{" "}
                        que querem verificar se um CPF está vinculado ao clube do verificador.{" "}
                        <b>
                            Para acessar esse endpoint, é necessário uma conta
                            com permissões de checagem de CPF.
                        </b>
                    </Text>
                    <SubTitle paddingTop="30px">Verificar CPF</SubTitle>
                    <Text>
                        O CPF deverá ser passado na URL. Caso o CPF não seja
                        encontrado, a chamada retornará o status 404. Também é
                        possível passar o valor da compra no corpo da chamada,
                        criando uma utilização no sistema.
                    </Text>
                    <Text paddingBottom="5px">
                        GET https://api.partiu.com.br/api/v2/agreement_integration/cpf/
                        {"{{cpf_aqui}}"}/
                    </Text>
                    <SmallTitle>Chamada</SmallTitle>
                    <SmallTitle>Resposta</SmallTitle>
                    <RequestTable
                        fields={[
                            {
                                name: "is_valid",
                                type: "string",
                                description: "CPF do usuário."
                            }
                        ]}
                    />
                </DocumentationSection>
                <DocumentationSection
                    id="webhook-usuarios"
                    title="WebHook Usuários"
                    codeChildren={<UserWebhook />}
                >
                    <Text>
                        <Text>
                            Webhook de envio de usuários do convênio para um determinado endpoint
                        </Text>
                    <b>
                        Para acessar esse endpoint, é necessário uma conta
                        com permissões de admin do convênio.
                    </b>
                    </Text>
                    <SmallTitle>Chamada</SmallTitle>
                    <Code paddingBottom="5px">
                        POST https://api.partiu.com.br/api/v2/agreement_integration/webhooks/users/
                    </Code>
                    <SmallTitle>Resposta</SmallTitle>
                    <RequestTable
                        fields={[
                            {
                                name: "url",
                                type: "string",
                                description: "URL para realizar uma chamada POST com os usuários"
                            },
                            {
                                name: "bearer_token",
                                type: "string",
                                description: "Token autorizado a performar POST na URL provisionada"
                            },
                            {
                                name: "expected_response_code",
                                type: "number",
                                description: "Resposta esperada"
                            }
                        ]}
                    />
                    <Text paddingBottom="5px">
                        Internamente realizaremos uma chamada POST ao endereco provido ("URL") com uma lista de usuarios no seguinte formato:
                    </Text>
                    <Code paddingBottom="5px">
                        {"[{'email': 'dummy-email', 'full_name': 'dummy-name', 'cpf': 'dummy-cpf'}]"}
                    </Code>
                </DocumentationSection>
                <DocumentationSection
                    id="api-registration"
                    codeChildren={<RegistrationCode />}
                    title="API de Cadastro"
                >
                    <Text>
                        A API de Cadastro é responsável por adicionar e/ou
                        desativar usuários do seu Clube.
                    </Text>
                    <Text>
                        Ao adicionar um usuário, ele automaticamente terá acesso
                        ao Clube, para retirar o acesso, é necessário remover o
                        usuário.
                    </Text>
                    <Text>
                        <b>
                            Para acessar esses endpoints, é necessário uma conta
                            com permissões de Convênio.
                        </b>
                    </Text>
                    <SubTitle paddingTop="30px">
                        Adicionar novo cadastro
                    </SubTitle>
                    <Text>
                        Caso o usuário não exista na plataforma, ele receberá um
                        email com os passos para criar uma senha.
                    </Text>
                    <Text>
                        POST
                        https://api.partiu.com.br/api/v1/agreement_integration/vinculate/
                    </Text>
                    <RequestTable
                        fields={[
                            {
                                name: "email",
                                type: "string",
                                description: "Email do usuário",
                                isRequired: true
                            },
                            {
                                name: "cpf",
                                type: "string",
                                description: "CPF do usuário",
                                isRequired: true
                            },
                            {
                                name: "full_name",
                                type: "string",
                                description: "Nome completo do usuário",
                                isRequired: true
                            },
                            {
                                name: "value_of_plan",
                                type: "float",
                                description:
                                    "Valor que o cliente paga pelo combo contratado. (Ex: Valor da conta do provedor de internet, Valor do Sócio Torcedor, etc..",
                                isRequired: true
                            },
                            {
                                name: "image_url",
                                type: "string",
                                description: "URL para uma foto do usuário"
                            },
                            {
                                name: "telephone",
                                type: "string",
                                description: "Telefone do usuário"
                            },
                            {
                                name: "groups",
                                type: "string[]",
                                description:
                                    "Lista com nome de grupos do usuário. Grupo pode ser o condomínio do usuário, tipo de contrato, etc..."
                            }
                        ]}
                    />
                    <SubTitle paddingTop="30px">Desativar cadastro</SubTitle>
                    <Text>
                        Ao desativar um usuário, ele não é deletado no banco de
                        dados, apenas perde acesso ao seu clube. Ao adicionar um
                        usuário que já foi cadastrado previamente, ele apenas é
                        reativado.
                    </Text>
                    <Text>
                        POST
                        https://api.partiu.com.br/api/v1/agreement_integration/remove/
                    </Text>
                    <RequestTable
                        fields={[
                            {
                                name: "cpf",
                                type: "string",
                                description: "CPF do usuário",
                                isRequired: true
                            }
                        ]}
                    />
                    <SubTitle paddingTop="30px">
                        Ativar/Desativar Grupo
                    </SubTitle>
                    <Text>
                        Você pode desativar ou ativar o acesso de vários
                        usuários do clube ao mesmo tempo usando os grupos
                        adicionados na chamada de Cadastro.
                    </Text>
                    <Text>
                        POST
                        https://api.partiu.com.br/api/v1/agreement_integration/groups/status/
                    </Text>
                    <RequestTable
                        fields={[
                            {
                                name: "status",
                                type: "boolean",
                                description:
                                    "Qual será o status do clube. True para ativado, False para desativado.",
                                isRequired: true
                            },
                            {
                                name: "groups",
                                type: "string[]",
                                description:
                                    "Quais grupos serão afetados pela mudança de status.",
                                isRequired: true
                            }
                        ]}
                    />
                </DocumentationSection>
                <DocumentationSection
                    id="api-auto-login"
                    codeChildren={<AutoLoginCode />}
                    title="API de Login Automático"
                >
                    <Text>
                        No fluxo padrão, o cliente deve entrar no site do Clube
                        e fazer o login. Para fornecer uma melhor experiência
                        para o usuário, esta API gera um link automático que
                        abre a página do Clube com o cliente já logado. Desta
                        forma ele apenas precisa realizar um login, o do seu
                        site ou app.{" "}
                        <b>
                            Para chamar esta API, é necessário que o cliente
                            tenha sido cadastrado por meio da{" "}
                            <a
                                style={{ textDecoration: "underlined" }}
                                href="#api-cadastro"
                            >
                                API de Cadastro
                            </a>
                        </b>
                    </Text>
                    <SubTitle paddingTop="30px">Gerar link automático</SubTitle>
                    <Text>
                        Além de gerar o link automático, também é possível
                        atualizar os dados do cliente.
                    </Text>
                    <Text>
                        POST
                        https://api.partiu.com.br/api/v1/agreement_integration/login/
                    </Text>
                    <SmallTitle>Chamada</SmallTitle>
                    <RequestTable
                        fields={[
                            {
                                name: "cpf",
                                type: "string",
                                description: "CPF do usuário",
                                isRequired: true
                            },
                            {
                                name: "email",
                                type: "string",
                                description: "Email do usuário"
                            },
                            {
                                name: "full_name",
                                type: "string",
                                description: "Nome completo do usuário"
                            },
                            {
                                name: "city",
                                type: "string",
                                description: "Cidade do usuário"
                            },
                            {
                              name: "plano",
                              type: "string",
                              description: "Plano do usuário"
                            },
                            {
                                name: "value_of_plan",
                                type: "float",
                                description:
                                    "Valor que o cliente paga pelo combo contratado. (Ex: Valor da conta do provedor de internet, Valor do Sócio Torcedor, etc.."
                            },
                            {
                                name: "image_url",
                                type: "string",
                                description: "URL para uma foto do usuário"
                            },
                            {
                                name: "telephone",
                                type: "string",
                                description: "Telefone do usuário"
                            }
                        ]}
                    />
                    <SmallTitle>Resposta</SmallTitle>
                    <RequestTable
                        fields={[
                            {
                                name: "cpf",
                                type: "string",
                                description: "CPF do usuário"
                            },
                            {
                                name: "link",
                                type: "string",
                                description: "Link automático"
                            }
                        ]}
                    />
                </DocumentationSection>
                <DocumentationSection
                    title="IFrames e Webviews"
                    id="iframes-webviews"
                    codeChildren={<IFrameCode />}
                >
                    <Text>
                        <b>
                            Recomendamos sempre abrir o link em uma nova aba do
                            navegador de preferência do usuário.
                        </b>{" "}
                        Mas em algumas circunstâncias, é necessário que o site
                        do clube esteja inteiramente dentro da página do cliente
                        ou app. Para isso, esteja atento à alguns pontos abaixo:
                    </Text>
                    <OrderedList paddingLeft="20px">
                        <li>
                            Tenha certeza que o IFrame/Webview tem acesso à
                            localização do cliente. No caso das Webviews,{" "}
                            <b>
                                é obrigatório que o App esteja autorizado a
                                utilizar a localização
                            </b>
                            .
                        </li>
                        <li>
                            Ao utilizar um desconto E-commerce, é aberto uma
                            nova aba, ou seja,{" "}
                            <b>
                                o IFrame/Webview deve estar habilitado à abrir
                                novas abas
                            </b>
                            .
                        </li>
                    </OrderedList>
                </DocumentationSection>
                <DocumentationSection
                    id="api-users-usage"
                    title="API de Utilizações de Usuários"
                    codeChildren={<CPFCode />}
                >
                    <Text>
                        A API de Utilização de Usuários é <b>voltada para sistemas do convênio</b>{" "}
                        que querem listar as utilizações dos seus usuários na Partiu.{" "}
                        <b>
                            Para acessar esse endpoint, é necessário uma conta
                            com permissões de convênio (Staff Agreement).
                        </b>
                    </Text>
                    <SubTitle paddingTop="30px">Utilização de Usuários</SubTitle>
                    <Text paddingBottom="5px">
                        GET https://api.partiu.com.br/api/v2/agreement_integration/usages/
                    </Text>
                    <SmallTitle>Chamada</SmallTitle>
                    <RequestTable
                        fields={[
                            {
                                name: "page",
                                type: "number",
                                description: "Página",
                            },
                            {
                                name: "page_size",
                                type: "number",
                                description: "Tamanho da página",
                            },
                        ]}
                    />
                    <SmallTitle>Resposta</SmallTitle>
                    <RequestTable
                        fields={[
                            {
                                name: "id",
                                type: "number",
                                description: "Id da utilização."
                            },
                            {
                                name: "user_fullname",
                                type: "string",
                                description: "Nome completo do usuário."
                            },
                            {
                                name: "discount_name",
                                type: "string",
                                description: "Nome do desconto."
                            },
                            {
                                name: "created_at",
                                type: "string",
                                description: "Data da utilização."
                            },
                            {
                                name: "discount_company_name",
                                type: "string",
                                description: "Nome da empresa do desconto."
                            },
                            {
                                name: "saved",
                                type: "number",
                                description: "Valor economizado."
                            }
                        ]}
                    />
                </DocumentationSection>
            </VStack>
        </HStack>
    );
}
