import { Flex } from "@chakra-ui/react";
import React from "react";
import { useCheckMobile } from "../../utils";
import { SearchBar } from "../atoms/Searchbar";

export const FilterBar = ({ search, onSearchChange = () => {}, children }) => {
    const mobile = useCheckMobile();

    return (
        <Flex
            borderTop="0px"
            borderBottom="1px"
            borderColor="inherit"
            direction={{ base: "column", lg: "row" }}
            width="100%"
            background="white"
        >
            <SearchBar value={search} onChange={onSearchChange} />
            {children}
        </Flex>
    );
};
