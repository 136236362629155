import React from "react";
import { BannerPageLayout } from "../../templates/BannerPageLayout";
import { Flex } from "@chakra-ui/react";

const Legal = (props) => (
    <BannerPageLayout title="Recursos Legais">
        <Flex className="legal">
        </Flex>
    </BannerPageLayout>
);

export default Legal;
