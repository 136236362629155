import { Image, UnorderedList, VStack } from "@chakra-ui/react";
import { Title } from "../components/atoms/Title";
import React from "react";

export function HowToUse() {
    return (
        <VStack
            width="100%"
            align="center"
            padding="20px"
            paddingBottom="100px"
        >
            <Title alignSelf="center" paddingBottom="20px" fontSize="20pt">
                Como Usar
            </Title>
            <Title>Lojas Físicas</Title>
            <Image src="https://partiu-static.s3.amazonaws.com/images/ComoUtilizar+-Lojas+F%C3%ADsicas.png" />
            <UnorderedList spacing={4} paddingLeft="30px">
                <li>
                    Ative e confirme a sua localização, para que o GPS mostre as
                    lojas parceiras da região.
                </li>
                <li>
                    No menu “Lojas Físicas” escolha o estabelecimento que
                    deseja.
                </li>
                <li>
                    Na página do desconto você pode conferir o regulamento da
                    promoção. Após é só clicar em “Usar o desconto”.
                </li>
                <li>
                    Pronto! Agora é só informar o código gerado em sua tela no
                    estabelecimento.
                </li>
            </UnorderedList>
            <Title paddingTop="50px">Lojas Online</Title>
            <Image src="https://partiu-static.s3.amazonaws.com/images/ComoUtilizar+-+Lojas+Online.png" />
            <UnorderedList spacing={4} paddingLeft="30px">
                <li>
                    No menu “Lojas E-commerce” escolha o estabelecimento que
                    você desejar.
                </li>
                <li>
                    Na página do desconto você pode conferir o regulamento da
                    promoção. Após é só clicar em “Usar o desconto”.
                </li>
                <li>
                    Pronto! Você será direcionado ao site exclusivo da parceria
                    já cuponado (com descontos ativos) ou basta inserir o cupom
                    ao final da sua compra.
                </li>
            </UnorderedList>
        </VStack>
    );
}
