import { Box, Code, Text, VStack } from "@chakra-ui/react";
import React from "react";

export function AutoLoginCode() {
    return (
        <VStack
            paddingTop="250px"
            textAlign="left"
            alignSelf="center"
            width="90%"
        >
            <Code padding="10px 25px" backgroundColor="#212429" color="white">
                curl -X POST
                https://api.partiu.com.br/api/v1/agreement_integration/login/ \
                <br /> -H 'Authorization: Token sua chave aqui' \<br /> -H
                'Content-Type: application/json' \<br /> -d{" "}
                {'{"cpf": "565.138.937-71"}'}
            </Code>
        </VStack>
    );
}
