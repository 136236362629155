export const portugueseMonthLabels = [
    "Janeiro",
    "Fevereiro",
    "Março",
    "Abril",
    "Maio",
    "Junho",
    "Julho",
    "Agosto",
    "Setembro",
    "Outubro",
    "Novembro",
    "Dezembro"
];

export function toBRLDate(date) {
    return Intl.DateTimeFormat("pt-BR", {
        dateStyle: "full",
        timeStyle: "short"
    }).format(date);
}
