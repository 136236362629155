import {
    Button,
    CircularProgress,
    Code,
    Image,
    Text,
    VStack
} from "@chakra-ui/react";
import React from "react";
import { Title } from "./components/atoms/Title";

export class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false, errorInfo: "" };
    }

    static getDerivedStateFromError(error) {
        // Update state so the next render will show the fallback UI.
        return { hasError: true };
    }

    componentDidCatch(error, errorInfo) {
        // You can also log the error to an error reporting service

        this.setState({ hasError: true, errorInfo });

        if (process.env.NODE_ENV === "production")
            setTimeout(() => {
                window.location.replace("/");
            }, 300000);
    }

    render() {
        const { hasError, errorInfo } = this.state;
        const error = JSON.stringify(errorInfo).toString();

        const handleClick = () => {
          console.log("clicked");
          window.location.replace("/");
        }

        if (hasError)
            return (
                <VStack padding="0px 5%" paddingTop="10%" spacing={8}>
                    <Image src="https://partiu-static.s3.amazonaws.com/images/DesenhoBuracos.png" />
                    <Title fontSize="30pt">Oops!</Title>
                    <Text textAlign={"center"}>
                        Parece que aconteceu um erro! 
                        <br/>
                        Não se preocupe, iremos corrigir em breve. 
                        <br/>
                        Clique no botão abaixo para ir para a home.
                    </Text>
                    {/* <Code height="150px" overflowY="scroll">
                        {error}
                    </Code> */}
                    <Button isFullWidth="100%" onClick={handleClick}>Ir para a home</Button>
                </VStack>
            );
        return <>{this.props.children}</>;
    }
}
