import { VStack } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { useProfile } from "../../context/user";
import { Receipts } from "./components/Receipts";
import { Summary } from "./components/Summary";
import { UserDatePicker } from "./components/UserDatePicker";
import { TopMenu } from "../../components/organisms/TopMenu";
import { getEconometer } from "../../api/profile";

export function EconometerPage() {
    const econometer = useQuery("econometer", getEconometer);

    return (
        <>
            <TopMenu />
            <VStack
                direction="column"
                align="center"
                justify="center"
                paddingBottom="100px"
                paddingTop={{ base: "70px", lg: "0px" }}
                width={{ base: "100%", lg: "50%" }}
                margin="auto"
                overflow="none"
            >

                <UserDatePicker />
                <Summary econometer={econometer} />
                <Receipts econometer={econometer} />
            </VStack></>

    );
}
