import React, { useEffect, useState } from "react";
import {
    Drawer,
    DrawerCloseButton,
    DrawerContent,
    DrawerOverlay,
    useDisclosure
} from "@chakra-ui/react";
import { Button } from "../../../components/atoms/Button";

import { UseDiscount } from "./UseDiscounts";
import { useSettings } from "../../../context/index";
import { Title } from "../../../components/atoms/Title";
import { useNavigate } from "react-router-dom";

export const UseDiscountDrawer = (props) => {
    const _repeatShake = useState(0);
    const navigate = useNavigate();
    const disclosure = useDisclosure();
    const { isApp } = useSettings();

    useEffect(() => {
        const interval = setInterval(() => {
            _repeatShake[1](_repeatShake[0] + 1);
        }, 4000);

        return () => clearInterval(interval);
    }, [_repeatShake]);

    return (
        <>
            <Button
                display={{ base: "flex", lg: "none" }}
                borderRadius="0px"
                position="fixed"
                bottom={isApp ? "73px" : "0px"}
                left="0px"
                right="0px"
                margin="auto"
                zIndex="999" //It's more than 9000!
                width="100%"
                padding="27px 20px"
                alignItems="center"
                onClick={disclosure.onOpen}
                // onClick={props.category === "Cinema" ? () => navigate("compra") : disclosure.onOpen}
                className="layered-box-strong"
                leftIcon={
                    <i
                        className="fas fa-chevron-up"
                        style={{ marginRight: 10, color: "white" }}
                    />
                }
            >
                <Title fontSize="11pt" fontFamily="Montserrat" color="white">
                    Usar desconto
                </Title>
            </Button>
            <Drawer {...disclosure} placement="bottom">
                <DrawerOverlay />
                <DrawerContent backgroundColor="transparent">
                    <DrawerCloseButton
                        onClick={disclosure.onClose}
                        zIndex="10000"
                    />
                    <UseDiscount {...props} />
                </DrawerContent>
            </Drawer>
        </>
    );
};
