import { SimpleGrid, Text, VStack } from "@chakra-ui/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { useTheme } from "../../context";
import { Title } from "../atoms/Title";

function Step({ icon, index, title, description }) {
    const { titleColor } = useTheme();
    return (
        <VStack
            padding="0px 10px"
            spacing={3}
            color={titleColor}
            alignItems="center"
        >
            <FontAwesomeIcon
                color={titleColor}
                size="3x"
                icon={icon}
                alignmentBaseline="middle"
            />
            <Title textAlign="center" fontWeight="700" paddingTop="10px">
                {index + 1 + ". "}
                {title}
            </Title>
            <Text fontSize="11pt" textAlign="center">
                {description}
            </Text>
        </VStack>
    );
}

function Steps({ id, title, small, steps }) {
    return (
        <VStack paddingBottom="50px" spacing={10} id={id} width="100%">
            <Title
                paddingTop="30px"
                paddingBottom="10px"
                fontWeight="700"
                fontSize="16pt"
            >
                {title}
            </Title>
            <SimpleGrid
                columns={{ base: 1, md: steps.length }} // Define o número de colunas dinamicamente
                spacing={{
                    base: 10,
                    md: 5
                }}
                alignContent={"center"}
                width="90%"
            >
                {steps.map((step, index) => (
                    <Step
                        key={index}
                        index={index}
                        small={small}
                        title={step.title}
                        description={step.description}
                        icon={step.icon}
                        size={12 / steps.length}
                    />
                ))}
            </SimpleGrid>
        </VStack>
    );
}

export default Steps;
