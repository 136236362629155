import { Image } from "@chakra-ui/image";
import { Divider, HStack, Text, VStack } from "@chakra-ui/layout";
import { Box, Button, Checkbox, Container, FormControl, FormLabel, IconButton, Input, Spinner, useToast } from "@chakra-ui/react";
import { faMinus, faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useMemo, useState } from "react";
import Cards from 'react-credit-cards-2';
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import { retrievePromotion } from "../../../api/discounts";
import { axiosInstance } from "../../../axios";
import { Title } from "../../../components/atoms/Title";
import { TopMenu } from "../../../components/organisms/TopMenu";
import { numberToBRL } from "../../../utils/money";
import ReactInputMask from "react-input-mask";
import 'react-credit-cards-2/dist/es/styles-compiled.css';



export const PromotionPurchase = () => {

  const { mode, id } = useParams();

  const navigate = useNavigate();
  const toast = useToast();
  const queryClient = useQueryClient();


  const [acceptedRules, setAcceptedRules] = useState(false);
  const [quantity, setQuantity] = useState(1);
  const [creditCard, setCreditCard] = useState({
    number: '',
    expiry: '',
    cvc: '',
    name: '',
    focus: '',
    companyCode: ''
  });

  const { data: company, isLoading, isError } = useQuery(
    `details-${id}`,
    () => retrievePromotion(`${mode ? mode + "_" : ""}${id}`),
    {
      refetchOnMount: true,
    }
  );

  const createBillMutation = useMutation(
    (data) => axiosInstance.post("/sales/buy-with-credit-card/", data), {
      onSuccess: () => {
        toast({
          title: "Pedido realizado com sucesso!",
          description: "Tenha um bom filme. Aproveite!",
          status: "success",
          duration: 5000,
          isClosable: true,
        });
        queryClient.invalidateQueries("my-vouchers");
        navigate('/minhas-compras')
      },
      onError: (error) => {
        toast({
          title: "Erro ao realizar o pedido",
          description: `Houve um erro ao efetuar o seu pedido.`,
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      }
    }
  );


  const promotion = useMemo(
    () => company && company.promotions[0],
    [company]
  );
  const discount = useMemo(
    () => company && company.promotions[0],
    [company]
  );


  const handleInputChange = (evt) => {
    const { name, value } = evt.target;
    setCreditCard((prev) => ({ ...prev, [name]: value }));
  }

  const handleInputFocus = (evt) => {
    setCreditCard((prev) => ({ ...prev, focus: evt.target.name }));
  }

  const incrementQuantity = (evt) => {
    if (quantity < 4) {
      setQuantity((prev) => (prev + 1));
    }
  }

  const decrementQuantity = (evt) => {
    if (quantity > 1) {
      setQuantity((prev) => (prev - 1));
    }
  }

  const handleButtonClick = () => {
    createBillMutation.mutate({
      discount_id: discount.id,
      quantity: quantity,
      credit_card: {
        holder_name: creditCard.name,
        card_expiration: creditCard.expiry,
        card_number: creditCard.number.replaceAll(" ", ""),
        card_cvv: creditCard.cvc,
        payment_method_code: "credit_card",
        payment_company_code:  creditCard.companyCode
      },
    });
  };

  if (isLoading || isError) {
    return (
      <VStack>
        <Spinner size={"xl"} />
      </VStack>
    );
  }


  return (
      <VStack
        alignItems="center"
        flexDirection="column"
        justifyContent="center"
        background={"#f4f4f4"}
        boxSizing="border-box"
        paddingBottom={"120px"}
        width="100%"
      >
        <TopMenu />
        <Container maxWidth={"1100px"} mx="80px">
          <Text fontSize="3xl">Cinema</Text>
          <VStack align="flex-start" width="100%" padding="10px 0px" backgroundColor={"white"} borderRadius={"10px"} marginTop={"40px"}>
            <VStack padding={"16px"}>
              <Box>
                <Image
                  src={company.logo}
                  minWidth="100px"
                  objectFit="contain"
                  minHeight="100px"
                  maxWidth="120px"
                  maxHeight={"80px"}
                ></Image>
              </Box>
              <Title>
                {company.show_name}
              </Title>
                
            </VStack>
            <Divider />
            <VStack padding="20px" alignItems={"flex-start"} 
              width={"100%"}>
              <HStack 
                display={"flex"} 
                flexDirection={{
                  base: 'column',
                  md: 'row',
                }}
                width={"100%"}
              >
                <Box maxWidth={"500px"} marginBottom={{base: "24px", md: 0}} marginRight={{base: "0", md: "24px"}}>
                  <Text fontSize={14}><b>Regulamento</b>:</Text>
                  <Text textAlign="left" fontSize={14}>{promotion.rules}</Text>
                </Box>
                <HStack  
                  alignItems={"center"} 
                  justifyContent={"space-between"} 
                  width="100%"
                  >
                  <VStack>
                    <Box display={"flex"}>
                      <IconButton onClick={decrementQuantity} disabled={quantity <= 1}>
                        <FontAwesomeIcon
                          color="#000"
                          opacity="1"
                          icon={faMinus}
                        />
                      </IconButton>
                      <VStack height={"100%"} width={"48px"} borderRadius={"5px"} alignItems={"center"}>
                        <Text fontSize={"2xl"}>{quantity}</Text>
                      </VStack>
                      <IconButton onClick={incrementQuantity} disabled={quantity >= 4}>
                        <FontAwesomeIcon
                          color="#000"
                          opacity="1"
                          icon={faPlus}
                        />
                      </IconButton>
                    </Box>
                  </VStack>
                  <Box>
                    <Text fontSize={"1xl"}><s>{numberToBRL(promotion.original_price)}</s></Text>
                    <Text fontSize={"3xl"}>{numberToBRL(promotion.buy_price)}</Text>
                  </Box>
                </HStack>
              </HStack>
            </VStack>            
          </VStack>
          <VStack 
            align="flex-start" 
            width="100%" 
            backgroundColor={"white"} 
            borderRadius={"10px"} 
            marginTop={"40px"} 
            padding={"16px"}
            >
            <Box width="100%" >
                <Title>Resumo do Pedido</Title>
                <Divider marginY={"16px"} />
                <Box display={"flex"} justifyContent={"space-between"}>
                  <Text fontSize={"1xl"} fontWeight={"bold"} color="#777">{`Ingressos (${quantity})`}</Text>
                  <Text fontSize={"1xl"} fontWeight={"bold"} color="#777">{numberToBRL(promotion.original_price)}</Text>
                </Box>
                <Box display={"flex"} justifyContent={"space-between"}>
                  <Text fontSize={"1xl"} fontWeight={"bold"} color="#777">Descontos</Text>
                  <Text fontSize={"1xl"} fontWeight={"bold"} color="#777">- {numberToBRL(quantity * (Number(promotion.original_price) - Number(promotion.sale_price)))}</Text>
                </Box>
                <Divider marginY={"16px"} />
                <Box display={"flex"} justifyContent={"space-between"}>
                  <Text fontSize={"1xl"} fontWeight={"bold"} color="#777">Total</Text>
                  <Text fontSize={"2xl"} fontWeight={"bold"} color="green">{numberToBRL(quantity * Number(promotion.sale_price))}</Text>
                </Box>
              </Box>
          </VStack>

          <VStack align="flex-start" width="100%" padding="10px 0px" backgroundColor={"white"} borderRadius={"10px"} marginTop={"40px"}>
            <VStack padding={"16px"}>
              <Title>
                Dados do pagamento
              </Title>
            </VStack>
            <Divider />
            <HStack padding="20px" justifyContent={"space-between"} width={"100%"} flexDirection={{
                  base: 'column',
                  md: 'row',
                }}>
              <Box maxWidth={"400px"} marginBottom={"16px"}>
                <Input
                  name="number"
                  placeholder="Número do Cartão"
                  value={creditCard.number}
                  onChange={handleInputChange}
                  onFocus={handleInputFocus}
                  mask="9999999999999999"
                  maskChar={"-"} 
                  as={ReactInputMask}
                />
                <Input
                  marginTop={"8px"}
                  type="text"
                  name="name"
                  placeholder="Nome no cartão"
                  value={creditCard.name}
                  onChange={handleInputChange}
                  onFocus={handleInputFocus}
                />
                <Input
                  marginTop={"8px"}
                  type="text"
                  name="expiry"
                  placeholder="Data de vencimento"
                  value={creditCard.expiry}
                  onChange={handleInputChange}
                  onFocus={handleInputFocus}
                  mask="99/9999"
                  maskChar={"-"} 
                  as={ReactInputMask}
                />
                <Input
                  marginTop={"8px"}
                  name="cvc"
                  placeholder="CVC"
                  value={creditCard.cvc}
                  onChange={handleInputChange}
                  onFocus={handleInputFocus}
                  mask="999"
                  maskChar={"-"} 
                  as={ReactInputMask}
                />
                
                {createBillMutation.isError && (
                  <Box marginTop={"16px"} color="red">
                    {createBillMutation.error.response.data.error}
                  </Box>  
                )}
              </Box>
              <Box>
                <Cards
                  number={creditCard.number}
                  expiry={creditCard.expiry}
                  cvc={creditCard.cvc}
                  name={creditCard.name}
                  focused={creditCard.focus}
                  callback={(params) => {
                    if (typeof params === 'object' && params && params.issuer !== "unknown"){
                      if (params.issuer && creditCard.companyCode.toLowerCase() !== params.issuer.toLowerCase() && params.issuer.toLowerCase() !== "unknown") {
                        setCreditCard((prevState) => ({
                          ...prevState,
                          companyCode: params.issuer
                        }))
                      }

                    }
                  }}  
                />
              </Box>
            </HStack>
            
          </VStack>
          <HStack paddingTop="20px" align="center" marginBottom={"16px"}>
            <Checkbox margin={0} onChange={(e) => setAcceptedRules(e.target.checked)} />
            <Text fontSize={14}><b>Li o regulamento e estou de acordo.</b></Text>
          </HStack>
          <HStack>
            <Button onClick={() => navigate(-1)} width="100%" colorScheme="gray">
              Voltar
            </Button>
            <Button
              width="100%"
              disabled={!acceptedRules || createBillMutation.isLoading}
              colorScheme="green"
              onClick={handleButtonClick}
              isLoading={createBillMutation.isLoading}
            >
              Comprar
            </Button>
          </HStack>
        </Container>
        
    </VStack>
  );
};
