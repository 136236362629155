import { useMediaQuery } from "@chakra-ui/react";

export function getDataFromParameters() {
    const search = window.location.search;
    const dict = {};

    search
        .replace("?", "")
        .split("&")
        .forEach((args) => {
            let split = args.split("=");
            dict[split[0]] = split[1];
        });

    return dict;
}

export const sanitizeObject = (obj) => {
    return Object.keys(obj).reduce((acc, key) => {
        if (obj[key] === null || obj[key] === undefined  || obj[key] === "" ){
            return acc;
        }
        return {
            ...acc,
            [key]: obj[key]
        }
    }, {})
}

export function verificateCPF(strCPF) {
    let Soma;
    let Resto;
    let i;

    Soma = 0;
    if (strCPF === "00000000000") return false;

    for (i = 1; i <= 9; i++)
        Soma = Soma + parseInt(strCPF.substring(i - 1, i)) * (11 - i);
    Resto = (Soma * 10) % 11;

    if (Resto === 10 || Resto === 11) Resto = 0;
    if (Resto !== parseInt(strCPF.substring(9, 10))) return false;

    Soma = 0;
    for (i = 1; i <= 10; i++)
        Soma = Soma + parseInt(strCPF.substring(i - 1, i)) * (12 - i);
    Resto = (Soma * 10) % 11;

    if (Resto === 10 || Resto === 11) Resto = 0;
    if (Resto !== parseInt(strCPF.substring(10, 11))) return false;
    return true;
}

const regexCNPJ = /^\d{2}.\d{3}.\d{3}\/\d{4}-\d{2}$/

export function verificateCnpj(cnpj) {
    if (!cnpj) return false

  // Aceita receber o valor como string, número ou array com todos os dígitos
  const isString = typeof value === 'string'

  // Filtro inicial para entradas do tipo string
  if (isString) {
    // Teste Regex para veificar se é uma string apenas dígitos válida
    const digitsOnly = /^\d{14}$/.test(cnpj)
    // Teste Regex para verificar se é uma string formatada válida
    const validFormat = regexCNPJ.test(cnpj)
    // Verifica se o valor passou em ao menos 1 dos testes
    const isValid = digitsOnly || validFormat

    // Se o formato não é válido, retorna inválido
    if (!isValid) return false
  }

  // Elimina tudo que não é dígito
  const numbers = matchNumbers(cnpj)

  // Valida a quantidade de dígitos
  if (numbers.length !== 14) return false

  // Elimina inválidos com todos os dígitos iguais
  const items = [...new Set(numbers)]
  if (items.length === 1) return false

  // Separa os 2 últimos dígitos verificadores
  const digits = numbers.slice(12)

  // Valida 1o. dígito verificador
  const digit0 = validCalc(12, numbers)
  if (digit0 !== digits[0]) return false

  // Valida 2o. dígito verificador
  const digit1 = validCalc(13, numbers)
  return digit1 === digits[1]
}

function validCalc(x, numbers) {
    const slice = numbers.slice(0, x)
    let factor = x - 7
    let sum = 0
  
    for (let i = x; i >= 1; i--) {
      const n = slice[x - i]
      sum += n * factor--
      if (factor < 2) factor = 9
    }
  
    const result = 11 - (sum % 11)
  
    return result > 9 ? 0 : result
  }
  
  // Elimina tudo que não é dígito
  function matchNumbers(value) {
    const match = value.toString().match(/\d/g)
    return Array.isArray(match) ? match.map(Number) : []
  }

export function useCheckMobile() {
    return useMediaQuery("(max-width: 1024px)")[0];
}

export function supportsLocalStorage() {
    try {
        return "localStorage" in window && window["localStorage"] !== null;
    } catch (e) {
        return false;
    }
}

export function inIframe() {
    let url = new URL(window.location.href);
    let showMenu;

    try {
        showMenu =
            url.searchParams.get("showMenu") ||
            sessionStorage.getItem("showMenu");
    } catch {
        showMenu = false;
    }

    if (showMenu) {
        sessionStorage.setItem("showMenu", true);
        return false;
    }

    try {
        return window.self !== window.top;
    } catch (e) {
        return true;
    }
}

export const snakeToCamel = (str) => {
    if (!str || str.indexOf("_") === -1) return str;
    return str
        .toLowerCase()
        .replace(/([-_][a-z])/g, (group) =>
            group.toUpperCase().replace("-", "").replace("_", "")
        );
};


export const sanitizeCpfOrCnpj = (value) => {
  if (!value) return value;
  
  const sanitized = value.replace(/\D/g, "");

  return sanitized;
};