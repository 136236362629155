import { Heading, VStack } from "@chakra-ui/layout";
import React, { useContext } from "react";
import { useTheme } from "../../context/index";
import { Card } from "../atoms/Card";
import { Title } from "../atoms/Title";

const Feature = (props) => {
    const { primaryColor, primaryFontColor } = useTheme();

    return (
        <Card
            hover
            backgroundColor={primaryColor}
            w={{ base: "100%", lg: "600px" }}
            padding="15px"
            alignItems="center"
        >
            <Heading color={primaryFontColor} fontSize="16px">
                {props.feature}
            </Heading>
        </Card>
    );
};

const Features = (props) => (
    <VStack
        margin="auto"
        alignItems="center"
        width="100%"
        spacing={10}
        paddingTop="40px"
        paddingBottom="50px"
        borderTop="1px solid #eee"
        borderBottom="1px solid #eee"
    >
        <VStack width="80%" spacing={8}>
            <Title fontSize="16pt" fontWeight="700">
                {props.title}
            </Title>
            {props.features.map((feature) => (
                <Feature small={props.small} feature={feature} />
            ))}
        </VStack>
    </VStack>
);

export default Features;
