import { Flex } from "@chakra-ui/react";
import React from "react";
import { BannerPageLayout } from "../../templates/BannerPageLayout";
import { useTheme } from "../../context";

function Privacy(props) {
    const { privacyPolicy } = useTheme();

    if (privacyPolicy) return (
        <BannerPageLayout title="Política de Privacidade">
            <Flex width={"100%"} className="legal" paddingBottom={10}>
                <p style={{ flex: 1 }} dangerouslySetInnerHTML={{ __html: privacyPolicy }}></p>
            </Flex>
        </BannerPageLayout>
    );

    return(
    <BannerPageLayout title="Política de Privacidade">
        <Flex className="legal" paddingBottom={10}>
            <div className="text">
                <p>
                    A Política de Privacidade e Segurança da Informação Eletrônica foi
                    elaborada para reafirmar nosso compromisso com a segurança e a
                    privacidade das informações que coletamos de nossos usuários no
                    nosso site e aplicativo, serviços oferecidos pela Partiu Tecnologia!.
                </p>
                <p>
                    O site e aplicativo poderão fornecer acesso a links para outros
                    sites externos cujos conteúdos e políticas de privacidades e
                    segurança da informação não são de responsabilidade da Partiu Tecnologia!.
                    Assim, recomendamos aos usuários que, ao serem redirecionados para
                    sites externos, consultem sempre as respectivas políticas de
                    privacidades antes de fornecer seus dados ou informações.
                    <br />
                    <br />
                    Essa política está sujeita a constantes melhorias e aprimoramentos.
                    <br />
                    <br />
                    Desse modo, recomendamos sua periódica consulta.
                    <br />
                    <br />
                    Contudo, caso a Partiu Tecnologia!, em algum momento, promova mudança
                    substancial na maneira de usar as informações pessoais coletadas, as
                    novas condições de privacidade e segurança da informação serão
                    informadas:
                    <br />
                    a) ao público em geral, mediante anúncio em destaque em nossa página
                    principal; e<br />
                    b) aos usuários cadastrados, mediante comunicação eletrônica e
                    disponível no site.
                </p>
                <ol>
                    <li>
                        A Partiu Tecnologia! coleta informações pessoais, capazes de identificar os
                        usuários, quando estes: a) cadastram-se em nosso site; b)
                        realizam uma compra em nossa loja virtual; c) entram em contato
                        conosco pelos canais de atendimento ao usuário; d) preenchem
                        formulário de contato.
                    </li>
                    <li>
                        Para cada uma das modalidades de coleta de dados poderão ser
                        solicitadas diferentes informações, de acordo com a finalidade
                        de cada uma. Assim, os usuários serão sempre informados sobre os
                        dados que estão sendo coletados, ficando a seu critério
                        fornecê-lo.
                    </li>
                    <li>
                        A Partiu Tecnologia! também pode receber e armazenar automaticamente, por
                        meio de "cookies", informações em seus servidores sobre as
                        atividades advindas do navegador, incluindo endereço IP e a
                        página acessada.
                    </li>
                    <li>
                        O login nas áreas restritas do site e aplicativo está
                        condicionado ao fornecimento de dados pessoais do usuário, bem
                        como a aceitação de cookies de navegação. Ao decidir fornecer
                        seus dados pessoais, o usuário declara conhecer e aceitar os
                        termos da presente política.
                    </li>
                    <li>
                        Todas as informações coletadas dos usuários trafegam de forma
                        segura, utilizando processo de criptografia padrão de Internet.
                    </li>
                    <li>
                        Qualquer informação fornecida pelos usuários será coletada e
                        guardada de acordo com os mais rígidos padrões de segurança e
                        confiabilidade.
                    </li>
                    <li>
                        Todos os dados pessoais coletados serão incorporados ao banco de
                        dados da Partiu Tecnologia!.
                    </li>
                    <li>
                        A Partiu Tecnologia! utiliza as informações coletadas para os seguintes
                        propósitos gerais: (i) informar a respeito de novos produtos da
                        empresa e de parceiros; (ii) manter atualizados os cadastros dos
                        usuários para fins de contato telefônico, por e-mail ou mala
                        direta.
                    </li>
                    <li>
                        O acesso às informações coletadas é restrito aos empregados e
                        pessoas autorizadas pela Partiu Tecnologia!. Aqueles que se utilizarem
                        indevidamente dessas informações ferindo nossa política de
                        privacidade, estarão sujeitos às penalidades previstas em nosso
                        processo disciplinar, sem exclusão das demais medidas legais
                        cabíveis.
                    </li>
                    <li>
                        A não ser por determinação legal ou judicial, as informações dos
                        usuários cadastrados no ambiente deste aplicativo ou site
                        (www.partiu.com.br) jamais serão transferidas a
                        terceiros ou usadas para finalidades diferentes daquelas para as
                        quais foram coletadas pela Partiu Tecnologia!.
                    </li>
                    <li>
                        O usuário garante a veracidade e exatidão dos dados pessoais que
                        fornecer neste site, pelo que assume a correspondente
                        responsabilidade. A Partiu Tecnologia! não assume qualquer responsabilidade
                        no caso de inexatidão dos dados pessoais introduzidos pelo
                        usuário neste site.
                    </li>
                    <li>
                        O usuário que introduzir seus dados pessoais identificáveis
                        poderá, a qualquer momento, solicitar que lhe seja informado o
                        conteúdo desses dados, podendo, inclusive, realizar as
                        retificações que julgue conveniente, bastando, para tanto,
                        fazê-las através de seu próprio cadastro no site.
                    </li>
                    <li>
                        Ao tomar conhecimento de alguma promoção realizada pela Partiu Tecnologia!,
                        consulte o site e/ou aplicativo para verificar a veracidade da
                        mesma. Caso a informação seja equivocada ou não conste de nosso
                        site, desconsidere-a. Se você tem perguntas ou sugestões, por
                        favor, envie um e-mail para ajuda@partiu.com.br.
                    </li>
                </ol>
            </div>
        </Flex>
    </BannerPageLayout>)
}

export default Privacy;