import {
    Button,
    Divider,
    Drawer,
    DrawerBody,
    DrawerCloseButton,
    DrawerContent,
    DrawerHeader,
    DrawerOverlay,
    HStack,
    Image,
    Skeleton,
    Spacer,
    Text,
    UnorderedList,
    VStack
} from "@chakra-ui/react";
import React, { useEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import { useTheme } from "../../context";
import { useProfile } from "../../context/user";
import { ExternalLink } from "../atoms/ExternalLink";
import { Title } from "../atoms/Title";
import jwt from "jsonwebtoken";

function Plan({ title, price, link, priceByMonth, token }) {
    const { primaryColor } = useTheme();
    const { name, email, cpf } = useProfile();
    const payload = useMemo(
        () =>
            jwt.sign(
                {
                    customer_email: email,
                    customer_name: name,
                    customer_code: cpf
                },
                token
            ),
        []
    );

    if (!payload) return <Skeleton width="100%" height="200px" />;

    return (
        <VStack
            width="100%"
            border="1px solid rgba(0,0,0,0.15)"
            borderRadius="5px"
            align="flex-start"
            padding="15px"
            spacing={3}
        >
            <Title fontSize="18pt">{title}</Title>
            <UnorderedList
                spacing={1}
                fontSize="10pt"
                opacity="0.5"
                paddingLeft="7%"
            >
                <li>Acesso à todos os descontos.</li>
                <li>Acompanhamento pelo Econômetro</li>
            </UnorderedList>
            <Divider width="100%" />
            <HStack width="100%">
                <HStack align="flex-end">
                    <Title
                        letterSpacing="0.01rem"
                        whiteSpace="nowrap"
                        fontSize="14pt"
                    >
                        R$ {price}
                    </Title>
                    {priceByMonth ? (
                        <Text whiteSpace="nowrap" fontSize="8pt" opacity="0.5">
                            R${priceByMonth} por mês.
                        </Text>
                    ) : (
                        <></>
                    )}
                </HStack>
                <Spacer />
                <ExternalLink
                    textDecoration="none"
                    to={link + `?payload=${payload}`}
                >
                    <Button color={primaryColor} variant="ghost">
                        Assinar
                    </Button>
                </ExternalLink>
            </HStack>
        </VStack>
    );
}

export function PlanDrawer({ isOpen, onClose }) {
    const { logo } = useTheme();
    return (
        <Drawer placement="bottom" isOpen={isOpen} onClose={onClose}>
            <DrawerOverlay />
            <DrawerContent>
                <DrawerCloseButton />
                <DrawerHeader>Compre recorrente Já!</DrawerHeader>

                <DrawerBody>
                    <VStack spacing={8} width="100%" textAlign="justify">
                        <Image height="30px" src={logo} />
                        <Text>
                            Para utilizar os descontos da Partiu, você precisa
                            estar vinculado à um convênio ou pagar uma
                            assinatura.{" "}
                            <Link
                                style={{ textDecoration: "underline" }}
                                to={{ pathname: "/perfil" }}
                                state={{ openPromocode: true }}
                            >
                                Tenho um promocode
                            </Link>
                            .
                        </Text>
                        <Plan
                            title="Plano Mensal"
                            price="29,99"
                            link="https://partiu.app.vindi.com.br/customer/pages/5cc9cc9b-3835-40c6-a3ba-2e99a900741b/subscriptions/new"
                            token="aTK4iVPKoTHHUEI8b_MYJF5dQVb5aa1t"
                        />
                        <Plan
                            title="Plano Anual"
                            price="238,80"
                            priceByMonth="19.90"
                            link="https://partiu.app.vindi.com.br/customer/pages/04456bf6-9010-448c-bb8a-a2830ddb9f3f/subscriptions/new"
                            token="458YSSoUFEYszYv021wOQ6oggQKzOXLq"
                        />
                        <Spacer />
                    </VStack>
                </DrawerBody>
            </DrawerContent>
        </Drawer>
    );
}
