import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import { QueryClient, QueryClientProvider } from "react-query";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import { App } from "./App";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import Chart from "chart.js/auto";
import { BarElement, CategoryScale, Legend, LinearScale, Title, Tooltip } from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { ReactQueryDevtools } from 'react-query/devtools'

Sentry.init({
    dsn: "https://89106a430d6c451a972e9adba6506207@o1028246.ingest.sentry.io/6418557",
    integrations: [new BrowserTracing()],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 0.1
});
const queryConfig = {
    refetchAllOnWindowFocus: false,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    retry: 0,
    staleTime: 0
};


const queryClient = new QueryClient({
    defaultOptions: {
        queries: queryConfig
    }
});


Chart.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
    ChartDataLabels
);

window.history.scrollRestoration = "auto";

ReactDOM.render(
    <QueryClientProvider client={queryClient}>
        <App />
        <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>,
    document.getElementById("root")
);

serviceWorkerRegistration.unregister();
