import { css, TabList } from "@chakra-ui/react";
import React from "react";

export function CustomTabList({ children, ...props }) {
    return (
        <TabList
            width="100%"
            overflowX="scroll"
            css={css({
                scrollbarWidth: "none",
                "::-webkit-scrollbar": { display: "none" },
                WebkitOverflowScrolling: "touch",
                boxShadow: "inset 0 -2px 0 rgba(0, 0, 0, 0.1)",
                border: "0 none"
            })}
            position="relative"
            whiteSpace="nowrap"
            paddingLeft={{ sm: "0%", lg: "10%" }}
            {...props}
        >
            {children}
        </TabList>
    );
}
