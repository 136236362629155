import { Flex, VStack } from "@chakra-ui/react";
import React from "react";
import { BannerPageLayout } from "../../templates/BannerPageLayout";
import { useTheme } from "../../context";

export function Terms() {
    const { legalText } = useTheme();

    if (legalText) return (
        <BannerPageLayout title="Termo de Uso">
            <Flex width={"100%"} className="legal" paddingBottom={10}>
                <p style={{ flex: 1 }} dangerouslySetInnerHTML={{ __html: legalText }}></p>
            </Flex>
        </BannerPageLayout>
    );

    return (
        <BannerPageLayout title="Recursos Legais">
            <Flex className="legal" paddingBottom={10}>
                <VStack alignItems="flex-start" spacing={3} width="100%">
                    <h2>Termos de Uso</h2>
                    <h3>Contrato Virtual de Adesão ao Serviço</h3>
                    <h4>Cláusula I - Objeto</h4>
                    <p>
                        O objeto do presente Contrato é estabelecer os termos e condições que regerão, no âmbito do
                        programa de concessão de descontos e vantagens (adiante denominado "Partiu!") de propriedade de
                        PARTIU DESENVOLVIMENTO DE SOFTWARE LTDA , a relação do usuário com a Partiu!, cabendo ao usuário
                        pagar quantia em dinheiro pelos serviços de intermediação da Partiu! estabelecidas neste
                        Contrato. </p>
                    <h4>Cláusula II - Inscrição</h4>
                    <p>
                        Para inscrição e concessão do serviço, o usuário deverá cadastrar-se no site ou no aplicativo.
                        Em seguida, deverá completar o formulário de inscrição com todos os dados solicitados,
                        autorizando o uso e cessão dos dados da forma como melhor entender a Partiu!. A Partiu! se
                        reserva o direito de solicitar algum comprovante e/ou dado adicional com o fim de conferir os
                        dados pessoais do usuário submetido ao cadastro, bem como recusar, suspender temporária ou
                        definitivamente usuário cujos dados não tenham podido ser confirmados, mesmo que o cadastro
                        inicial do usuário já tenha sido aceito, decisão esta que independerá de motivos ou será
                        geradora de qualquer direito ao usuário. A Partiu! poderá considerar rescindido este Contrato a
                        qualquer tempo, sem necessidade de prévio aviso, bem como cancelar o cadastro de usuário quando
                        os atos do usuário não sejam adequados no todo ou em parte às políticas de relacionamento da
                        Partiu! ou à legislação local, incluindo, mas não se limitando, à suspeita de comportamentos
                        fraudulentos ou em atentado contra a imagem e bom nome comercial de Partiu!, ou em
                        descumprimento de alguma obrigação prevista neste contrato ou que atente contra seus princípios,
                        objeto, objetivo e natureza, incluindo, mas não se limitando, a prática de alguma das restrições
                        mencionadas no presente contrato. </p>
                    <h4>Cláusula III - Licença Limitada</h4>
                    <p>
                        Todos os direitos intelectuais e industriais sobre o Site, operações, software, hardware,
                        domínio, logomarcas, emblemas, logotipos, design de páginas e peças publicitárias, estrutura,
                        conteúdos, informações, Ferramentas do Programa, Símbolos e Expressões que caracterizem os
                        serviços a marca Partiu! são de propriedade de Partiu! e em nenhum caso se entenderá que ao
                        usuário é concedido algum tipo de direito sobre os mesmos ou o seu uso sem autorização expressa
                        e por escrito de representante legal da Partiu!. </p>
                    <h4>Cláusula IV - Modificação</h4>
                    <p>
                        A Partiu! poderá modificar a qualquer momento os termos e condições deste contrato e notificará
                        as alterações ao usuário através de publicação de aviso com uma versão atualizada dos ditos
                        termos e condições em seu site e/ou aplicativo. </p>
                    <h4>Cláusula V - Limitação de Responsabilidade</h4>
                    <p>
                        A Partiu! não garante o acesso e uso continuado ou ininterrupto de seu site ou aplicativo móvel
                        instalável no telefone celular. O site e qualquer outra plataforma digital ou alguma de suas
                        funcionalidades podem, eventualmente, não estar disponíveis devido a problemas técnicos ou
                        falhas de Internet nos links ou Ferramentas, não sendo lícito aos usuários imputar
                        responsabilidade, exigir indenização de qualquer natureza em virtude da impossibilidade de
                        acesso ao site, bem como por danos indiretos que surjam em conexão com o presente Contrato.
                        Acordam as partes que a responsabilidade por danos de qualquer origem que decorram deste
                        Contrato e da adesão e uso do cartão Partiu! não excederão o valor pago pelo usuário para
                        adesão, de forma a manter sempre equilibrada a relação contratual. </p>
                    <h4>Cláusula VI - Aceitação das Condições do Contrato</h4>
                    <p>
                        Ao pressionar o botão no campo indicado ao lado, o usuário declara que leu, compreendeu e
                        aceitou o presente Contrato em todos seus termos e condições, de forma livre e independente de
                        qualquer dolo, coação, fraude ou reserva mental quanto a qualquer dos termos ou condições e que
                        sua intenção de aderir o cartão e utilizar os serviços da Partiu! não está relacionada com
                        alguma outra manifestação, garantia, ou declaração que não sejam as estabelecidas neste
                        Contrato. </p>
                    <h4>Cláusula VII - Uso do Cartão e Site</h4>
                    <p>
                        Todas as ofertas oferecidas pelos parceiros da Partiu! só poderão ser obtidas pelos usuários
                        através de um login válido, no formato virtual (gerado pelo próprio aplicativo móvel), dentro do
                        prazo de validade e que, portanto, será sempre verificado em cada estabelecimento parceiro. A
                        Partiu! se compromete a promover todos os esforços razoáveis para atualizar o site e aplicativo
                        e mostrar as indicações atuais de estabelecimento parceiros participantes e os termos de sua
                        disponibilidade para a participação no programa. Os estabelecimentos parceiros participantes
                        podem, no entanto, retirar-se do regime ou mudar os termos e condições da sua disponibilidade
                        depois de se tornar um membro e a Partiu! não terá nenhuma responsabilidade por quaisquer
                        retiradas pelos estabelecimentos parceiros participantes ou quaisquer alterações em seus termos
                        e condições ou disponibilidade, que deverá ser sempre verificada pelo usuário antes de ir ao
                        local e usar seu cartão Partiu!. Os usuários terão o benefício de quaisquer outros
                        estabelecimentos parceiros que aderirem ao sistema em uma data posterior e qualquer aumento na
                        disponibilidade de estabelecimentos participantes, que poderão ser verificados em qualquer
                        plataforma digital ou física da Partiu!, como por exemplo, sites, aplicativos ou materiais
                        impressos. A informação contida nos materiais impressos e em qualquer plataforma digital serve
                        apenas e tão somente para fins ilustrativos e de informação. A Partiu! não oferece nenhuma
                        garantia quanto à exatidão ou adequação a propósito de qualquer material impresso ou em qualquer
                        plataforma digital (site ou aplicativo) ou a confiabilidade do acesso, não se responsabilizando
                        por eventuais danos decorrentes de ou em conexão com o uso do site ou aplicativo ou dos serviços
                        dos estabelecimentos parceiros anunciantes. A presente limitação de responsabilidade é global e
                        se aplica a todos os danos e prejuízos de qualquer tipo ou natureza, incluindo, sem limitação de
                        compensação, diretos, indiretos ou consequentes, perda de dados, renda ou lucros, perda ou danos
                        à propriedade e reivindicações de terceiros. Todas as promoções estão sujeitas a capacidade do
                        estabelecimento anunciante.
                        <ol>
                            <li>É obrigatória a apresentação do cartão virtual Partiu! nos estabelecimentos para obter
                                os benefícios anunciados.
                            </li>
                            <li>Nenhum material impresso ou aplicativo, por sí só não dão o direito as promoções.
                                Somente o cartão virtual Partiu! válido dá o direito aos descontos anunciados.
                            </li>
                            <li>O uso de mais de um cartão não acumula o desconto oferecido.</li>
                            <li>O seu cartão Partiu! garante acesso a todas as ofertas para uso quantas vezes quiser até
                                a validade do mesmo.
                            </li>
                            <li>O uso do cartão virtual Partiu! em mais de um parceiro por dia é permitido.</li>
                            <li>É possível, exceto se o contrário estiver especificado nas regras da promoção, a
                                utilização do cartão Partiu! para obtenção de todas as ofertas anunciadas por um
                                estabelecimento, no mesmo dia. As ofertas, entretanto, devem ser solicitadas em uma
                                única conta.
                            </li>
                            <li>Os preços informados podem sofrer pequenas alterações durante o período de validade do
                                anúncio. Nesses casos, fica valendo os descontos anunciados sobre os preços atualizados.
                            </li>
                            <li>Em caso de fechamento das operações dos parceiros anunciantes, as respectivas ofertas
                                são automaticamente invalidadas.
                            </li>
                            <li>Não é permitido usar o mesmo cartão mais de uma vez no mesmo dia na mesma oferta, exceto
                                se a condição de permissão for devidamente publicada pelo parceiro anunciante.
                            </li>
                            <li>Para utilização dos serviços da Partiu! no aplicativo, é fundamental e imprescindível o
                                uso da internet para consulta de informações atualizadas. As promoções estão sujeitas a
                                atualizações frequentes. Portanto, se o usuário consultar o aplicativo no modo off-line,
                                ou seja, sem o acesso à internet, por qualquer que seja o motivo, o serviço da Partiu!
                                passa a ser automaticamente invalidado. Com o único objetivo de garantir a veracidade
                                das promoções anunciadas pelos parceiros comerciais credenciados.
                            </li>
                        </ol>
                    </p>
                    <h4>Cláusula VIII - Responsabilidade Pelas Promoções dos estabelecimentos parceiros anunciantes ou
                        Ato Destes</h4>
                    <p>
                        É de responsabilidade exclusiva do usuário do serviço verificar qual a modalidade de descontos,
                        datas, horários e toda e quaisquer especificidades oferecidas pelo parceiro de aos usuários e
                        descritos na revista e qualquer outra plataforma digital Partiu! que descrevem as respectivas
                        ofertas vigentes, não tendo a Partiu! qualquer responsabilidade pela negativa da promoção, uso
                        inadequado do cartão pelo usuário ou qualquer ato praticado pelos restaurantes, tendo o usuário
                        relação jurídico direta com estes na qualidade de consumidor dos serviços que lhe serão
                        prestados com os descontos negociados pela Partiu! aos seus usuários.
                    </p>
                    <h4>CLÁUSULA IX – Proteção de Dados Pessoais</h4>
                    <p>
                        9.1. As Partes se obrigam a envidar todos os esforços necessários para proteger os dados
                        pessoais e sensíveis de clientes que tenha conhecimento em razão da natureza da contratação, se
                        submetendo integralmente aos termos da Lei 13.709/2018 (Lei Geral de Proteção de Dados), bem
                        como demais leis e regulamentos em vigor que tratam de proteção de dados;<br />
                        9.2. A proteção de dados pessoais e sensíveis e de informações requer o uso de recursos quer
                        sejam técnicos ou organizacionais para buscar proteger os dados pessoais e sensíveis de clientes
                        e colaboradores contra perda, processamento e/ou acesso não autorizado e alterações, nos termos
                        da Lei 13.079/2018 (Lei Geral de Proteção de Dados-LGPD).<br></br>
                        9.3. As Partes declaram que não haverá troca de dados pessoais além daqueles necessários para o
                        extrair o cumprimento do objeto deste Contrato. As Partes declaram que, qualquer eventual
                        tratamento de dados pessoais que ocorra durante a execução deste Contrato deverá limitar-se ao
                        cumprimento das obrigações contratuais e respeitar às disposições da Lei Geral de Proteção de
                        Dados Pessoais (Lei no 13.709/2018), comprometendo-se a só tratar os dados pessoais para as
                        finalidades relacionadas ao Contrato.
                    </p>
                    <h4>Cláusula X - Foro e Legislação Aplicável</h4>
                    <p>
                        Este Contrato é regido pelas leis vigentes no Brasil ao tempo da adesão do usuário e, qualquer
                        controvérsia decorrente de seus termos, condições ou cláusulas, terá como foro competente o Foro
                        da Barra da Tijuca, da cidade e estado do Rio de Janeiro, renunciando as partes a qualquer
                        outro, por mais privilegiado que seja.
                    </p>
                </VStack>
            </Flex>
        </BannerPageLayout>
    );
}

export default Terms;