import React, { useContext } from "react";
import { Button as ChakraButton } from "@chakra-ui/react";
import { useTheme } from "../../context/index";

export const Button = ({ isPrimary = false, ...props }) => {
    const {
        primaryColor,
        primaryFontColor,
        secondaryColor,
        secondaryFontColor
    } = useTheme();
    return (
        <ChakraButton
            backgroundColor={isPrimary ? primaryColor : secondaryColor}
            color={isPrimary ? primaryFontColor : secondaryFontColor}
            height="50px"
            fontSize="10pt"
            fontFamily="Montserrat"
            fontWeight="700"
            letterSpacing="0.05rem"
            className="layered-box-strong"
            _hover={{
                backgroundColor: secondaryColor,
                color: secondaryFontColor
            }}
            {...props}
        >
            {props.children.toUpperCase
                ? props.children.toUpperCase()
                : props.children}
        </ChakraButton>
    );
};
