import React from "react";

import { Box, VStack } from "@chakra-ui/react";

import { TopMenu } from "../../components/organisms/TopMenu";

import { getAgreementMagazines } from "../../api/books";
import { MagazineInfiniteList } from "./components/MagazineInfiniteList";
import { useTheme } from "../../context";
import { MagazineModal } from "./components/MagazineModal";

export default function MyMagazines() {

    const [selectedMagazine, setSelectedMagazine] = new React.useState(null)

    const {id} = useTheme();
    
    return (
        <VStack paddingBottom={{ base: "50px", lg: "70px", "2xl": "80px" }}>
            <TopMenu />
            <MagazineModal isOpen={selectedMagazine !== null} onClose={() => setSelectedMagazine(null)} {...selectedMagazine} agreement={id} />
            <Box padding="5%"  paddingTop={{ base: "48px" }} width={"100%"}>
                <MagazineInfiniteList getFunc={getAgreementMagazines}
                    params={{ agreement: id }} onSelectBook={setSelectedMagazine}/>
            </Box>
        </VStack>
    );
}
