import React from "react";
import { Drawer, DrawerCloseButton, DrawerContent, DrawerOverlay, VStack } from "@chakra-ui/react";
import { MenuLink } from "../../Profile/components/Menu";
import { faHouse, faLock } from "@fortawesome/free-solid-svg-icons";
import { useAvailableLinks } from "../../../context/links";

export const SideMenu = ({
    isOpen,
    onClose
}) => {
    const links = useAvailableLinks();

    return (
        <Drawer isOpen={isOpen} placement="right" onClose={onClose}>
            <DrawerOverlay />
            <DrawerContent paddingLeft={2}>

                <DrawerCloseButton height={"48px"} width={"48px"}/>

                <VStack spacing={0} width={{ base: "100%", lg: "92%" }} paddingTop={10}>
                    <MenuLink to="/" icon={faHouse}>
                        Home
                    </MenuLink>
                    {links.map(l => <MenuLink key={l.name} id={l.name} to={l.link} icon={l.iconClass}>
                        {l.name}
                    </MenuLink>)}

                    <MenuLink to="/legal/politica-de-privacidade" icon={faLock}>
                        Politicas de privacidade
                    </MenuLink>
                </VStack>
            </DrawerContent>
        </Drawer>
    );
};
