import React, { useEffect, useState } from "react";
import { Box, Skeleton, useToast } from "@chakra-ui/react";
import { useMutation } from "react-query";

import { Magazine } from "./Magazine";
import { MagazineGrid } from "./MagazineGrid";
import { NoDiscountFound } from "../../../components/atoms/NoDiscountFound";
import { useTheme } from "../../../context";

export function MagazineInfiniteList({ getFunc, params, onSelectBook }) {
  const [results, setResults] = useState([]);
  const { id } = useTheme();
  const toast = useToast();
  const [paramHistory, setParamHistory] = useState(null);

  const loadMutation = useMutation(() => getFunc(params.search, id), {
    onSuccess: (data) => {
      setResults(data);
    },
    onError: () => {
      toast({
        title: "Não foi possível encontrar revistas.",
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    },
  });

  useEffect(() => {
    if (JSON.stringify(params) !== JSON.stringify(paramHistory)) {
      setResults([]);
      loadMutation.mutate();
      setParamHistory(params);
    }
  }, [params, paramHistory, loadMutation]);

  const isEmpty = () => results.length === 0 && !loadMutation.isLoading;

  return (
    <Box style={{ height: "100%", paddingBottom: "160px", paddingTop: "50px" }}>
      {loadMutation.isLoading && (
        <MagazineGrid paddingTop="20px">
          {new Array(10).fill(0).map((_, i) => (
            <Skeleton
              key={i}
              borderRadius="5px"
              width={{ base: "205px", lg: "205px", "2xl": "260px" }}
              height={{ base: "310px", lg: "310px", "2xl": "410px" }}
            />
          ))}
        </MagazineGrid>
      )}
      <MagazineGrid>
        {results
          .filter((b) => b.cover !== null)
          .map((b) => (
            <Box key={b.id}>
              <Magazine onClick={() => onSelectBook(b)} id={b.id} articleTitle={b.article_title} cover={b.cover} />
            </Box>
          ))}
      </MagazineGrid>
      {isEmpty() && (
        <NoDiscountFound
          subtext="Parece que não encontramos nenhuma revista com esses filtros!"
          text="Use outros filtros ou adicione uma revista à sua biblioteca."
          showButton={false}
        />
      )}
    </Box>
  );
}
