import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useToast } from "@chakra-ui/react";
import { axiosInstance } from "../../axios";

export function SSOCallback() {
  const navigate = useNavigate();
  const toast = useToast();

  useEffect(() => {
    const handleSSOCallback = async () => {
      const queryParams = new URLSearchParams(window.location.search);
      let callbackData = Object.fromEntries(queryParams.entries());
      callbackData["origin"] = window.location.origin;
      try {
        const response = await axiosInstance.post(
          "/integration/brisa/sso/login/",
          callbackData
        );
        const { token, user } = response.data;
        localStorage.setItem("token", token);
        navigate("/"); 
      } catch (error) {
        toast({
          title: "Erro",
          description: "Não foi possível completar o login SSO. Tente novamente mais tarde.",
          status: "error"
        });
      }
    };

    handleSSOCallback();
  }, [navigate, toast]);

  return (
    <div>Carregando...</div>
  );
}
