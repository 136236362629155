import {
    Flex,
    Heading,
    HStack,
    Select,
    Spacer,
    Text,
    VStack
} from "@chakra-ui/react";
import { faCalendar } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useContext, useEffect, useState } from "react";
import { Title } from "../../../components/atoms/Title";
import { useTheme } from "../../../context/index";
import { useProfile } from "../../../context/user";
import { portugueseMonthLabels } from "../../../utils/dates";

export function UserDatePicker({ onDateChange = () => {} }) {
    const [availableDates, setAvailableDates] = useState([]);
    const { name, createdAt } = useProfile();
    const { primaryColor, name: agreementName } = useTheme();

    useEffect(() => {
        const today = new Date();
        const dates = [today];

        for (let i = 1; i < 6; i++) {
            const _date = new Date();
            _date.setMonth(today.getMonth() - i);
            dates.push(_date);
        }

        setAvailableDates(dates);
    }, []);

    return (
        <VStack spacing={1} align="flex-start" width="90%">
            <HStack
                spacing={0}
                justify="center"
                align="center"
                width="100%"
                paddingTop="10px"
            >
                <VStack width="100%" align="flex-start" textAlign="left">
                    <Title
                        whiteSpace="nowrap"
                        paddingTop="20px"
                        fontSize="15pt"
                    >
                        Olá{" "}
                        <span style={{ fontSize: "15pt", fontWeight: "700" }}>
                            {name}
                        </span>
                        ,
                    </Title>
                    <Text color="#495057" opacity="0.5" fontSize="10pt">
                        Aqui detalhamos a sua economia aproximada. Vamos fazer a
                        sua mensalidade da <b>{agreementName}</b> sair de graça!
                    </Text>
                </VStack>
            </HStack>
        </VStack>
    );
}
