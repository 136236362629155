import React, { useEffect, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { BookGrid } from "./BookGrid";
import { Box, Skeleton } from "@chakra-ui/react";
import { Book } from "./Book";
import { NoDiscountFound } from "../../../components/atoms/NoDiscountFound";
import { useMutation } from "react-query";
import { useTheme } from "../../../context";

export function BookInfiniteList({ getFunc, params, onSelectBook }) {
    const [page, setPage] = useState(1);
    const [paramHistory, setParamHistory] = useState(null);
    const [hasMore, setHasMore] = useState(true);
    const [results, setResults] = useState([]);
    const { id, bookstoreType } = useTheme();

    const loadMutation = useMutation(() => getFunc(page, params.search, params.category, id));

    const onSuccess = (data) => {
        setHasMore(true);
        if (data.length === 0) setHasMore(false);

        if (page === 1) setResults(data);
        else setResults([...results, ...data]);
    };

    const onError = () => {
        setHasMore(false);
    };

    function refresh() {
        setPage(1);
        setResults([]);
        loadMutation.mutate(null, {
            onSuccess,
            onError
        });
    }

    function isEmpty() {
        return results.length === 0 && !loadMutation.isLoading;
    }

    useEffect(() => {
        if (JSON.stringify(params) === JSON.stringify(paramHistory)) return;

        refresh();
        setParamHistory(params);
    }, [params]);

    useEffect(() => {
        loadMutation.mutate(null, {
            onSuccess,
            onError
        });
    }, [page]);

    return (
        <InfiniteScroll
            dataLength={results} //This is important field to render the next data
            style={{
                overflow: "unset",
                height: "100%",
                paddingBottom: "160px",
                paddingTop: "50px"
            }}
            next={() => {
                if (loadMutation.isLoading) return;
                setPage(page + 1);
            }}
            hasMore={hasMore}
            loader={
                <BookGrid paddingTop="20px">
                    {new Array(10).fill(0).map((_, i) => (
                      <Box key={i}>
                        <Skeleton borderRadius="5px" maxWidth={{ base: "165px", lg: "205px", "2xl": "260px" }}
                height={{ base: "270px", lg: "310px", "2xl": "410px" }} />
                      </Box>
                    ))}
                </BookGrid>
            }
            endMessage={<></>}
        >
            {loadMutation.isLoading && <BookGrid paddingTop="20px">
                {new Array(10).fill(0).map((_, i) => (
                    <Skeleton key={i} borderRadius="5px" width={{ base: "205px", lg: "205px", "2xl": "260px" }}
                        height={{ base: "310px", lg: "310px", "2xl": "410px" }} />
                ))}
            </BookGrid>}
            <BookGrid>
                {results.map((b) => (
                    <Box key={b.id}>
                        <Book onClick={() => onSelectBook(b)} {...b} />
                    </Box>
                ))}
            </BookGrid>
            {isEmpty() ? <NoDiscountFound
                subtext={bookstoreType === "book" ? "Parece que não encontramos nenhum livro com esses filtros!": "Parece que não encontramos nenhuma revista com esses filtros!"}
                text={bookstoreType === "book" ? "Use outros filtros ou adicione um livro à sua biblioteca." : "Use outros filtros ou adicione uma revista à sua banca."}
                showButton={false} /> : <></>}
        </InfiniteScroll>
    );
}