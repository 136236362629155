import { Button } from "@chakra-ui/button";
import { HStack } from "@chakra-ui/layout";
import { Modal, ModalCloseButton, ModalHeader, ModalOverlay, ModalBody, ModalFooter, ModalContent } from "@chakra-ui/modal";
import React, { useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom"

export const CPFModal = ({
  isOpen,
  onClose
}) => {
  const btnRef = useRef();
  const navigate = useNavigate();
  const location = useLocation();



  const onSubmit = () => {
    navigate(`/perfil?selectedTab=profile&redirectUrl=${location.pathname}`)
  }


  return (
    <Modal
      isCentered
      isOpen={isOpen}
      onClose={onClose}
      ref={btnRef}
      id="cpf-modal"
    >
      <ModalOverlay
        backgroundColor="rgba(0,0,0,0.8)"
        style={{
          backdropFilter: "blur(5px)",
          "-webkit-backdrop-filter": "blur(5px)"
        }}
      />
      <ModalContent width="90%">
      
        <ModalHeader>Atualize seus dados</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          Olá, notamos que você não possui CPF cadastrado. Por favor, atualize seus dados pessoais, antes de continuar
        </ModalBody>

        <ModalFooter>
          <HStack>
            <Button onClick={onSubmit} width="100%" variant="ghost">
              Atualizar dados
            </Button>
          </HStack>
        </ModalFooter>
      </ModalContent> 
    </Modal>
  );
};
