import React, { useEffect, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { BookGrid } from "./BookGrid";
import { Box, Skeleton, Center, Spinner, VStack } from "@chakra-ui/react";
import { Book } from "./Book";
import { NoDiscountFound } from "../../../components/atoms/NoDiscountFound";
import { useMutation } from "react-query";
import { useTheme } from "../../../context";
import { Title } from "../../../components/atoms/Title";

const monthNames = ["Janeiro", "Fevereiro", "Março", "Abril", "Maio", "Junho",
    "Julho", "Agosto", "Setembro", "Outubro", "Novembro", "Dezembro"
]

export function MyBooksInfiniteListByMonth({ getFunc, params, onSelectBook }) {
    const [page, setPage] = useState(1);
    const [paramHistory, setParamHistory] = useState(null);
    const [hasMore, setHasMore] = useState(true);
    const [results, setResults] = useState([]);
    const { id, name, bookstoreType } = useTheme();

    const [library, setLibrary] = useState([]);

    const loadMutation = useMutation(() => getFunc(page, params.search, id));

    const onSuccess = (data) => {
        setHasMore(true);
        if (data.length === 0) setHasMore(false);


        if (page === 1) setResults(data);
        else setResults([...results, ...data]);
    };

    useEffect(() => {

        const data = [...results]
        let months = []
        let books = []

        data.map((item) => {
            const group = `${monthNames[item.month_featured]} de ${item.month_featured_year}`

            if (item.month_featured !== null) {
                if (months.includes(group)) {
                    const index = books.findIndex(item => item.group === group)

                    books[index] = {
                        books: [...books[index].books, item],
                        group: books[index].group
                    }
                } else {
                    months.push(group)
                    books.push({
                        group,
                        books: [item]
                    })
                }
            }
        })
        setLibrary(books)

    },[results]);

    const onError = () => {
        setHasMore(false);
    };

    function refresh() {
        setPage(1);
        setResults([]);
        loadMutation.mutate(null, {
            onSuccess,
            onError
        });
    }

    function isEmpty() {
        return results.length === 0 && !loadMutation.isLoading;
    }

    useEffect(() => {
        if (JSON.stringify(params) === JSON.stringify(paramHistory)) return;

        refresh();
        setParamHistory(params);
    }, [params]);

    useEffect(() => {
        loadMutation.mutate(null, {
            onSuccess,
            onError
        });
    }, [page]);

    return isEmpty() ? (
        <Center minHeight="70vh">
            <VStack>
                <NoDiscountFound
                    subtext={bookstoreType === "book" ? "Parece que não encontramos nenhum livro com esses filtros!": "Parece que não encontramos nenhuma revista com esses filtros!"}
                    text={bookstoreType === "book" ? "Use outros filtros ou adicione um livro à sua biblioteca." : "Use outros filtros ou adicione uma revista à sua banca."}
                    showButton={false}
                />
            </VStack>
        </Center>
    ) : (
        <InfiniteScroll
            dataLength={library} //This is important field to render the next data
            style={{
                overflow: "unset",
                height: "100%",
                paddingBottom: "160px"
            }}
            next={() => {
                if (loadMutation.isLoading) return;
                setPage(page + 1);
            }}
            hasMore={hasMore}
            loader={
                <Center marginTop={16} >
                    <Spinner
                        thickness='4px'
                        speed='0.65s'
                        emptyColor='gray.200'
                        color='blue.500'
                        size='xl'
                    />
                </Center>
            }
            endMessage={<></>}
        >
            <Title whiteSpace="break-spaces" paddingTop="20px">
                Olá
                <br />
                {`${bookstoreType === "book" ? `Esses livros são seus para sempre! Todo mês, até o dia 05, você receberá novos livros. Todos esses títulos estarão disponíveis na aba "Livros Recebidos".` : `Essas revistas são suas para sempre! Todo mês, até o dia 31, você receberá novas revistas. Todos esses títulos estarão disponíveis na aba "Revistas Recebidas".`} `}
            </Title>
            {loadMutation.isLoading &&
                <Box marginTop={16}>
                    <Title fontSize={20} alignSelf="flex-start" paddingBottom="10px">Carregando...</Title>
                    <BookGrid paddingTop="20px">
                        {new Array(10).fill(0).map((_, i) => (
                            <Skeleton key={i} borderRadius="5px" width={{ base: "205px", lg: "205px", "2xl": "260px" }}
                                height={{ base: "310px", lg: "310px", "2xl": "410px" }} />

                        ))}
                    </BookGrid>
                </Box>
            }
            {
                library.map((books) => (
                    <Box marginTop={16} key={'mybooks__' + books.group}>
                        <Title fontSize={20} alignSelf="flex-start" paddingBottom="10px">{books.group}</Title>
                        <InfiniteScroll
                            dataLength={books.books} //This is important field to render the next data
                            style={{
                                overflow: "unset",
                                height: "100%",
                                paddingTop: "10px"
                            }}
                            next={() => {
                                if (loadMutation.isLoading) return;
                            }}
                            hasMore={false}
                            loader={
                                <Center marginTop={16} >
                                    <Spinner
                                        thickness='4px'
                                        speed='0.65s'
                                        emptyColor='gray.200'
                                        color='blue.500'
                                        size='xl'
                                    />
                                </Center>
                            }
                            endMessage={<></>}
                        >
                            <BookGrid>
                                {books.books.map(b => <Book key={'mybooks__' + b.id} onClick={() => onSelectBook(b)} {...b} />)}
                            </BookGrid>
                        </InfiniteScroll>
                    </Box>
                ))
            }
        </InfiniteScroll >
    );
}
