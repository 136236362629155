import { Box, Flex, Heading, Text } from "@chakra-ui/react";
import React, { useContext } from "react";
import { useTheme } from "../../context/index";
import { Title } from "./Title";
import { faStar } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const Tag = ({
    children,
    width = "60px",
    height = "60px",
    ...props
}) => {
    const { primaryColor } = useTheme();
    return (
        <Flex
            background={primaryColor}
            width={width}
            height={height}
            marginTop="0px"
            marginRight="0px"
            justify="center"
            align="center"
            fontWeight="100"
            opacity="0.9"
            {...props}
        >
            {children}
        </Flex>
    );
};
export const TextTag = ({ children, containerProps = {}, ...props }) => (
    <Tag width="47px" height="47px" {...containerProps}>
        <Flex padding="0px 5px" align="flex-end">
            <Title textAlign="left" fontSize="5pt" color="white" {...props}>
                {children}
            </Title>
        </Flex>
    </Tag>
);

export const ExclusiveTag = ({ children, containerProps = {}, ...props }) => (
    <Tag width="80px" height="47px" {...containerProps}>
        <Flex padding="0px 5px" align="flex-end">
            <FontAwesomeIcon
                color="white"
                opacity="1"
                size="20"
                icon={faStar}
            />
            <Title textAlign="left" fontSize="5pt" color="white" {...props}>
                {children}
            </Title>
        </Flex>
    </Tag>
);

export const PercentageTag = ({ percentage }) => {
    const { primaryColor } = useTheme();
    return (
        <Tag width="47px" height="47px">
            <Flex textAlign="center" fontFamily="Open Sans" align="center">
                <Text
                    letterSpacing="-1px !important"
                    color="white"
                    transform="translateX(-4px)"
                    fontSize="1.8em"
                >
                    {percentage}
                </Text>
                <Text
                    position="absolute"
                    backgroundColor={primaryColor}
                    color="white"
                    borderRadius="50%"
                    right="0px"
                    bottom="5px"
                    fontSize="8pt"
                    fontWeight="bolder"
                    width="18px"
                    height="18px"
                >
                    %
                </Text>
                <Text
                    fontWeight="bold"
                    right="-2px"
                    top="13px"
                    position="absolute"
                    transform="rotate(-90deg)"
                    color="white"
                    fontSize="5pt"
                >
                    OFF
                </Text>
            </Flex>
        </Tag>
    );
};
