import { axiosInstance } from "../axios";

export const getSaleStatus = async (id) => {
    if (!id) return [];
    const url = `/sales?vindi_bill_id=${id}`;

    return await axiosInstance.get(url).then(({ data }) => {
        if (data.results.length > 0) {
            return data.results.at(0)
        }
    });
};

export const createSale = (data) => {
    return axiosInstance
        .post('/sales/', data);
};
