import React, { useContext, useState } from "react";
import {
    Button,
    HStack,
    Box,
    Center,
    Text,
    Container,
    VStack,
    Heading
} from "@chakra-ui/react";
import { inIframe } from "../../utils";
import { useTheme } from "../../context/index";

export const DownloadComponent = () => {
    const [closed, setClosed] = useState(false);
    var isMobile = /Android|webOS|iPhone|iPad|iPod|Opera Mini/i.test(
        navigator.userAgent
    );
    const { inAppLanding } = useTheme();
    var isIphone = /iPhone|iPad/i.test(navigator.userAgent);

    function getStoreName() {
        return isIphone ? "Apple Store" : "Google Play Store";
    }

    function getStoreLink() {
        return isIphone
            ? "https://apps.apple.com/br/app/partiu-vantagens/id996531860"
            : "https://play.google.com/store/apps/details?id=br.com.partiuvantagens&hl=pt_BR&gl=US";
    }

    if (inAppLanding) return <></>;

    return (
        <>
            {isMobile && !inIframe() ? (
                <Container
                    borderTop="1px solid #eee"
                    bg="#FAFAFA"
                    w="100%"
                    h="58px"
                    position="fixed"
                    bottom="0"
                    left="0"
                    zIndex="100"
                    display={closed ? "none" : null}
                >
                    <HStack
                        justifyContent="space-between"
                        alignItems="center"
                        pt={2}
                    >
                        <i
                            onClick={() => setClosed(true)}
                            className="fas fa-times"
                        ></i>
                        <img
                            src="/partiusmalllogo.png"
                            height="40px"
                            width="40px"
                        />
                        <VStack spacing={0} alignItems="flex-start">
                            <Text fontSize="14px">
                                <strong>Aplicativo Partiu!</strong>
                            </Text>
                            <Text fontSize="12px">
                                Grátis - Na {getStoreName()}
                            </Text>
                        </VStack>
                        <a href={getStoreLink()} target="_blank">
                            <Button colorScheme="blue" variant="link">
                                VER
                            </Button>
                        </a>
                    </HStack>
                </Container>
            ) : (
                <></>
            )}
        </>
    );
};
