import React from "react";
import {
    Link,
    Text,
    VStack,
} from "@chakra-ui/react";
import { Button } from "../../../components/atoms/Button";
import { useMutation } from "react-query";
import { useForm } from "react-hook-form";
import { Input } from "../../../components/atoms/Input";
import { useNavigate } from "react-router-dom";
import { LoginWrapper } from "./LoginWrapper";
import { useLogin } from "../context/login.context";
import { checkPromocode } from "../../../api/register";
import { useTheme } from "../../../context";

export function RegisterWithPromocode() {
    const navigate = useNavigate();
    const { register, handleSubmit, getValues } = useForm();
    const { id } = useTheme();
    const { setValidatedPromocode, setValidatedAgreement } = useLogin();
    const verifyMutation = useMutation(
        ({ promocode }) => checkPromocode(promocode, id), {
            onSuccess: (response) => {
                setValidatedPromocode(getValues("promocode"))
                setValidatedAgreement(response.data.agreement)
                navigate("/login/validate-success")
            }
        }
    );

    return (
        <LoginWrapper>
            <VStack width={{ base: "100%", lg: "80%" }}>
                <form
                    style={{ width: "100%" }}
                    onSubmit={handleSubmit(verifyMutation.mutate)}
                >
                    <VStack spacing={7} width="100%">
                        <Input
                            hookForm={register("promocode")}
                            placeholder="Código Promocional"
                            backgroundColor="#FAFAFA"
                            textTransform="uppercase !important"
                        />
                        {verifyMutation.isError && (
                            <Text color="red.500" textAlign={"left"} width={"100%"}>
                                {verifyMutation.error.response.data.error}       
                            </Text>
                        )}
                        <Button
                            isLoading={verifyMutation.isLoading}
                            width="100%"
                            type="submit"
                        >
                            Verificar
                        </Button>
                        <Link
                            href="#"
                            fontSize="14px"
                            textDecoration="underline !important"
                            onClick={() => navigate('/login')}
                        >
                            Selecionar outro método
                        </Link>
                    </VStack>
                </form>
            </VStack>
        </LoginWrapper>
    );
}
