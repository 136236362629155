import { Flex, VStack } from "@chakra-ui/react";
import React from "react";
import { MainTitle } from "./Headers";

export function DocumentationSection({ id, title, children, codeChildren }) {
    return (
        <VStack
            id={id}
            className="documentation-section"
            height="100%"
            width="100%"
        >
            <Flex width="100%">
                <VStack
                    spacing={4}
                    textAlign="left"
                    align="flex-start"
                    padding="20px"
                    paddingTop="50px"
                    flex="1"
                    fontSize="15px"
                >
                    <MainTitle>{title}</MainTitle>
                    {children}
                </VStack>
                <VStack
                    textAlign="left !important"
                    color="white"
                    paddingTop="50px"
                    flex="1"
                    align="center"
                    justify="flex-start"
                    backgroundColor="#292d33"
                >
                    {codeChildren}
                </VStack>
            </Flex>
        </VStack>
    );
}
