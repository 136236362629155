import React from "react";
import { VStack, Text, HStack } from "@chakra-ui/layout";
import { Title } from "../../../components/atoms/Title";

export const About = (props) => {
    return (
        <VStack
            paddingTop="10px"
            textAlign="left"
            alignItems="flex-start"
            w="100%"
        >
            <Text dangerouslySetInnerHTML={{ __html: props.description }}/>
            {props.site && props.site.length && (
                <HStack wordBreak="break-all">
                    <i className="fas fa-at" />
                    {props.site.includes('https://') ? <a href={props.site} target="_blank" rel="noreferrer">{props.site}</a> : <Text>{props.site}</Text>}
                </HStack>
            )}
        </VStack>
    );
};
