import { Box, Button, Image, VStack } from "@chakra-ui/react";
import { faClose } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import path from "path";
import React, { useEffect, useRef, useState } from "react";
import { ReactReader } from "react-reader";
import { useLocation, useNavigate } from "react-router-dom";
import { useSettings, useTheme } from "../../context";
import {
    TransformWrapper,
    TransformComponent,
} from "react-zoom-pan-pinch";
import * as PDFJS from "pdfjs-dist";
PDFJS.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${PDFJS.version}/pdf.worker.min.js`;

export const DrmEbook = () => {
    const { state } = useLocation();
    const [largeText, setLargeText] = useState(false);
    const rendition = useRef(undefined);

    const navigate = useNavigate();
    const { primaryColor, titleColor, logo } = useTheme();
    const { isApp, isIOS } = useSettings();
    const [location, setLocation] = useState(0);

    const containerRef = useRef(null);

    useEffect(() => {
        rendition.current?.themes.fontSize(largeText ? "140%" : "100%");
    }, [largeText]);

    const [pages, setPages] = useState([]);

    useEffect(() => {
        const loadPdf = async () => {
            const loadingTask = PDFJS.getDocument(
                state?.url.split(".pdf")[0] +
                    ".pdf#toolbar=0&navpanes=0&scrollbar=0"
            );
            const pdf = await loadingTask.promise;

            const pagePromises = [];
            for (let pageNum = 1; pageNum <= pdf.numPages; pageNum++) {
                pagePromises.push(pdf.getPage(pageNum));
            }

            const loadedPages = await Promise.all(pagePromises);
            setPages(loadedPages);
        };

        if (path.extname(state?.url).includes(".pdf")) {
            loadPdf();
        }
    }, [state]);

    const PageCanvas = ({ page }) => {
        const canvasRef = useRef(null);

        useEffect(() => {
            const renderPage = async () => {
                const containerWidth = containerRef.current.clientWidth;

                // Use a scale based on the container width and device pixel ratio for better quality
                const scale =
                    (containerWidth / page.view[2]) * window.devicePixelRatio;
                const viewport = page.getViewport({ scale });

                const canvas = canvasRef.current;
                const context = canvas.getContext("2d");
                canvas.height = viewport.height;
                canvas.width = viewport.width;

                const renderContext = {
                    canvasContext: context,
                    viewport: viewport
                };
                await page.render(renderContext).promise;

                // Scale the canvas back down for better resolution
                canvas.style.width = `${containerWidth}px`;
                canvas.style.height = `${
                    viewport.height / window.devicePixelRatio
                }px`;
            };

            renderPage();
        }, [page]);

        return (
            <TransformWrapper
                panning={{ disabled: true }}
                wheel={{
                    disabled: true
                }}
            >
                <TransformComponent>
                    <canvas ref={canvasRef} style={{ marginBottom: "20px" }} />;
                </TransformComponent>
            </TransformWrapper>
        );
    };

    return (
        <VStack
            style={{
                height: "100vh",
                overflow: "hidden"
            }}
        >
            <Box
                style={{
                    position: "fixed",
                    zIndex: 99999,
                    left: 0,
                    top: 0,
                    width: "100vw",
                    minHeight: "4vh",
                    display: "flex",
                    justifyContent: "end",
                    alignItems: "center",
                    backgroundColor: primaryColor,
                    padding: 4
                }}
            >
                <Box flex={1} fontWeight={"bold"} textAlign={"center"}>
                    {state.title.length > 25
                        ? state.title.substring(0, 25) + "..."
                        : state.title}
                </Box>
                <Button
                    variant="outline"
                    onClick={() => navigate(-1)}
                    style={{
                        textAlign: "center",
                        fontFamily: "Open Sans",
                        fontSize: "13pt",
                        fontWeight: "600",
                        paddingRight: 16,
                        color: titleColor,
                        letterSpacing: "0.03rem",
                        userSelect: "none",
                        cursor: "pointer"
                    }}
                >
                    <FontAwesomeIcon
                        color="rgba(0,0,0,0.75)"
                        size="lg"
                        icon={faClose}
                    />
                </Button>
            </Box>
            <Box
                style={{
                    position: "relative",
                    width: "100vw",
                    height:
                        path.extname(state?.url).includes("pdf") ||
                        (isApp && isIOS)
                            ? "96vh"
                            : "89vh",
                    marginTop: "4vh",
                    overflowX: "hidden",
                    display: "flex",
                    flexDirection: "column",
                    "&::WebkitScrollbar": {
                        width: "0px",
                        background: "transparent"
                    },
                    "&::WebkitScrollbarThumb": {
                        background: "#ddd",
                        borderRadius: "10px"
                    },
                    "&::WebkitScrollbarThumb:hover": {
                        background: "#ccc"
                    },
                    "&::WebkitScrollbarTrack": {
                        background: "transparent"
                    }
                }}
            >
                {path.extname(state?.url).includes(".pdf") ? (
                    <div
                        ref={containerRef}
                        style={{
                            width: "100%",
                            textAlign: "center",
                            height: "100%"
                        }}
                    >
                        {pages.map((page, index) => (
                            <PageCanvas key={index} page={page} />
                        ))}
                    </div>
                ) : (
                    <div style={{ width: "100%", height: "100%" }}>
                        <Image
                            src={logo}
                            width="40px"
                            height="40px"
                            objectFit="contain"
                            position="absolute"
                            right="12px"
                            top={"12px"}
                            backgroundColor={"#FFF"}
                        ></Image>
                        <ReactReader
                            url={state?.url}
                            location={location}
                            locationChanged={(epubcfi) => setLocation(epubcfi)}
                            swipeable={true}
                            getRendition={(rendition) => {
                                rendition.current = rendition;
                                rendition.hooks.content.register((contents) => {
                                    const body =
                                        contents.window.document.querySelector(
                                            "body"
                                        );
                                    if (body) {
                                        body.oncontextmenu = () => {
                                            return false;
                                        };
                                    }
                                });
                                rendition.current.themes.fontSize(
                                    largeText ? "140%" : "100%"
                                );
                            }}
                        />
                    </div>
                )}
            </Box>
        </VStack>
    );
};
