import { Box, Grid, Skeleton } from "@chakra-ui/react";
import React from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { useInfiniteQuery } from "react-query";
import { Discount } from "../atoms/Discount";
import { PercentageTag, TextTag } from "../atoms/DiscountTag";
import { sanitizeObject, useCheckMobile } from "../../utils";
import { NoDiscountFound } from "../atoms/NoDiscountFound";
import { useTheme } from "../../context/index";
import { getPromotions } from "../../api/discounts";

export function DiscountGrid({ children, ...props }) {
    const isMobile = useCheckMobile();

    return (
        <Box
            width={{ sm: "100%", xl: "82%" }}
            margin="auto"
            direction="column"
            flex="1"
            {...props}
        >
            <Grid
                templateColumns={`repeat(${isMobile ? "2" : "3"}, 1fr)`}
                gap={5}
            >
                {children}
            </Grid>
        </Box>
    );
}

const getTag = (data) => {
    if (data.type === "buy_and_gain")
        return (
            <TextTag>
                COMPRE
                <br /> & GANHE
            </TextTag>
        );
    else if (data.type === "treat") {
        return <TextTag>MIMO</TextTag>;
    }

    return <PercentageTag percentage={data.percentage} />;
};

export function NewDiscountList({ name, params }) {
    const { id } = useTheme();
    const {
        fetchNextPage,
        isSuccess,
        hasNextPage,
        isLoading, 
        data,
    } = useInfiniteQuery({
        queryKey: ['promotions', name, sanitizeObject(params)],
        queryFn: ({ pageParam = 1 }) => getPromotions({ agreement: id, page: pageParam, ...params }),
        getNextPageParam: (lastPage, allPages) => lastPage.length > 0 ? allPages.length + 1 : undefined,
        refetchOnWindowFocus: false,
        enabled: !!id
    });

    return (
        <InfiniteScroll
            dataLength={Number(data && data.pages ? data.pages.flat().length : 0)} //This is important field to render the next data
            style={{
                overflow: "unset",
                height: "100%",
                paddingBottom: "160px"
            }}
            next={fetchNextPage}
            hasMore={hasNextPage}
            loader={
                <DiscountGrid paddingTop="20px">
                    {new Array(6).fill(0).map((_, i) => (
                        <Skeleton key={i} width="100%" height="180px" />
                    ))}
                </DiscountGrid>
            }
            endMessage={<></>}
        >
            <DiscountGrid>
                {isSuccess && data.pages && data.pages.flat().map((d) => (
                    <Box key={d.id} height={{ base: "180px", lg: "210px" }}>
                        <Discount
                            key={d.id}
                            tag={getTag(d)}
                            title={d.show_name}
                            category={d.category}
                            subTitle={d.subtitle}
                            neighborhood={d.neighborhood}
                            favorited={d.favorite}
                            exclusive={d.exclusive}
                            forceMode={
                                (!d.discounts && !d.image) ||
                                    d.mode === "online"
                                    ? "contain"
                                    : "cover"
                            }
                            mode={d.mode === "online" ? "ecommerce" : "físico"}
                            image={d.image}
                            href={`/detalhes/${d.mode}/${d.id.split("_")[1]}/`}
                            distance={d.distance}
                        />
                    </Box>
                ))}
            </DiscountGrid>
            {isSuccess && data.pages && !data.pages.flat().length? <NoDiscountFound /> : <></>}
            {isLoading && !data && (
                <DiscountGrid paddingTop="20px">
                    {new Array(6).fill(0).map((_, i) => (
                        <Skeleton key={i} width="100%" height="180px" />
                    ))}
                </DiscountGrid>
            )}
        </InfiniteScroll>
    );
}
