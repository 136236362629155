import { Heading, HStack, Stack, Text, VStack } from "@chakra-ui/layout";
import { Divider, Image, Link as ChakraLink, Select } from "@chakra-ui/react";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useSettings, useTheme } from "../../context/index";
import { Title } from "../atoms/Title";
import { useAvailableLinks } from "../../context/links";

function FooterSection({ title, children }) {
    return (
        <VStack maxW="500px" flex="1" alignItems="flex-start">
            <Title
                fontFamily="Montserrat"
                textAlign="left"
                color="black"
                fontSize="16px"
                fontWeight="bold"
                marginBottom="10px"
            >
                {title.toUpperCase()}
            </Title>
            {children}
        </VStack>
    );
}

function FooterLink({ link, children }) {
    return (
        <Link
            style={{
                fontSize: "15px",
                fontWeight: "bold",

                textAlign: "left",
                color: "#555"
            }}
            to="#"
            onClick={(e) => {
                window.location.href = link;
                e.preventDefault();
            }}
        >
            {children}
        </Link>
    );
}

function FooterSocialLink({ icon, link }) {
    return (
        <ChakraLink isExternal _hover={{ color: "#666" }} href={link}>
            <i style={{ fontSize: 42 }} className={icon}></i>
        </ChakraLink>
    );
}

export function Footer() {
    const { name, telephone, email, bookstoreModule } = useTheme();
    const { iosMode, isApp } = useSettings();
    const [state, setState] = useState("Rio de Janeiro");
    const links = useAvailableLinks();

    const formatPhone = (phone) => {
        let t = phone.replace(/^(\d{2})(\d{4})(\d{4}).*/, "($1) $2-$3");

        return t;
    };

    const states = {
        "Rio de Janeiro":
            "Av. Pasteur, 110, 7º andar - Botafogo, Rio de Janeiro",
        "São Paulo - Capital":
            "Alameda Santos, 200 – Térreo - Bela Vista, São Paulo",
        "São Paulo - Santos": "Rua Dr. Acácio Nogueira, 06 - Boqueirão, Santos",
        Paraíba: "Av. Goiás, 705 - Estados, João Pessoa",
        Brasília:
            "QI 3 Bloco A Lotes N 04/36 Loja 6, Guará I – Distrito Federal",
        Piauí: "Av. Nossa Senhora de Fátima, 1613, Sala 2 – Teresina",
        Belém: "Rua Municipalidade, 985 - Umarizal",
        "Foz do Iguaçu": "Av. Juscelino Kubitschek, 1865 - Vila Paraguaia",
        Recife: "Av. República do Líbano, 251, Torre 3, Sala 1209 - Pina"
    };

    if (isApp) return <></>;

    return (
        <VStack
            width="100%"
            padding="50px 10%"
            alignItems="flex-start"
            spacing={10}
            borderTop="1px solid #eee"
            backgroundColor="white"
        >
            <Stack
                width="100%"
                alignItems="flex-start"
                direction={{ base: "column", lg: "row" }}
                spacing={10}
            >
                <FooterSection title={`${bookstoreModule ? '' : 'Clube'  } ${name}`}>
                    {links.map((l) => <FooterLink key={l.name} link={l.link}>{l.name}</FooterLink>)}
                </FooterSection>
                <FooterSection title="Contato">
                    {name === "app" ? (
                        <>
                            {" "}
                            <Select
                                size="md"
                                variant="flushed"
                                onChange={(v) => setState(v.target.value)}
                            >
                                {Object.keys(states).map((s) => (
                                    <option key={s} value={s}>{s}</option>
                                ))}
                            </Select>
                            <FooterLink>{states[state]}</FooterLink>
                        </>
                    ) : (
                        <></>
                    )}
                    <FooterLink link={`mailto:${email || "suporte@partiu.com.br"}`}>
                        Envie-nos um email
                    </FooterLink>
                    <FooterLink link={`tel:${telephone ? telephone : "21974204416"}`}>
                        {telephone ? formatPhone(telephone) : "(21) 97420-4416"}
                    </FooterLink>
                </FooterSection>
                {name === "app" ? (
                    <>
                        {" "}
                        <FooterSection title="Social">
                            <HStack spacing={5}>
                                <FooterSocialLink
                                    icon="fab fa-facebook"
                                    link="https://pt-br.facebook.com/partiuvantagens/"
                                />
                                <FooterSocialLink
                                    icon="fab fa-instagram"
                                    link="https://www.instagram.com/soupartiu/?hl=pt-br"
                                />
                                <FooterSocialLink
                                    icon="fab fa-linkedin"
                                    link="https://www.linkedin.com/company/partiu-vantagens/"
                                />
                            </HStack>
                        </FooterSection>
                        <FooterSection title="Aplicativos">
                            <HStack spacing={5}>
                                <FooterSocialLink
                                    icon="fab fa-apple"
                                    link="https://itunes.apple.com/br/app/partiu-vantagens/id996531860?mt=8/"
                                />
                                {iosMode ? (
                                    <></>
                                ) : (
                                    <FooterSocialLink
                                        icon="fab fa-android"
                                        link="https://play.google.com/store/apps/details?id=br.com.partiuvantagens"
                                    />
                                )}
                            </HStack>
                        </FooterSection>
                    </>
                ) : (
                    <></>
                )}
            </Stack>
            <Divider />
            <Stack
                spacing={10}
                width="100%"
                direction={{ base: "column", lg: "row" }}
                justifyContent="space-between"
            >
                <HStack spacing={5}>
                    <Image
                        height="25px"
                        filter="contrast(0%)"
                        src="https://partiu-static.s3.amazonaws.com/partiu_logo_black_white.png"
                    />
                    <Text
                        textAlign="left"
                        color="#555"
                        fontSize="12px"
                        fontWeight="600"
                    >
                        © Copyright 2024 - Partiu - Todos os direitos reservados
                    </Text>
                </HStack>
                <HStack marginLeft="auto">
                    <FooterLink link="/legal/termos-de-uso">
                        Termos de Uso
                    </FooterLink>
                    <Heading fontSize="14px">|</Heading>
                    <FooterLink link="/legal/politica-de-privacidade">
                        Política de Privacidade
                    </FooterLink>
                </HStack>
            </Stack>
        </VStack>
    );
}
