import React from "react";
import { FilterBar } from "../atoms/FilterBar";

export const DiscountFilterBar = ({
    search,
    onSearchChange = () => {},
    onLocationChange = () => {}
}) => {
    return (
        <FilterBar search={search} onSearchChange={onSearchChange}></FilterBar>
    );
};
