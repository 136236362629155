import React from "react";
import { GoogleMap, useJsApiLoader, Marker } from "@react-google-maps/api";
import {
    HStack,
    Link,
    MenuButton,
    MenuItem,
    Menu,
    MenuList,
    VStack,
    Text,
    Flex
} from "@chakra-ui/react";
import { Button } from "../../../components/atoms/Button";
import { Title } from "../../../components/atoms/Title";
import { useTheme } from "../../../context/index";

const containerStyle = {
    width: "100%",
    height: "300px"
};

function StoreMap({ data, center }) {
    const { isLoaded } = useJsApiLoader({
        id: "google-map-script",
        googleMapsApiKey: "AIzaSyB5tXrkiapXmzmvkkW0-7MQvfx1LL9VScQ"
    });
    const { primaryColor, primaryFontColor } = useTheme();

    return (
        <VStack
            paddingBottom="10px"
            alignItems="flex-start"
            spacing={0}
            w="100%"
            bgColor="white"
            p="20px 20px"
            borderTop="2px"
            borderRadius="5px"
            borderColor="gray.800"
            boxShadow="0 0 0.75rem rgba(0,0,0,0.2)"
            textAlign="left"
            position="relative"
            width={{ base: "100%", lg: "100%" }}
        >
            <HStack
                alignItems="baseline"
                justifyContent="space-between"
                width="100%"
                paddingTop="0px"
                paddingBottom="20px"
            >
                <Title>Mapa</Title>
                <Menu>
                    <MenuButton
                        as={Button}
                        backgroundColor={primaryColor}
                        color={primaryFontColor}
                        leftIcon={
                            <i
                                style={{ fontSize: 12 }}
                                className="fas fa-location-arrow"
                            />
                        }
                        padding="0px 10px"
                        fontSize="14px"
                        height="35px"
                    >
                        IR AGORA
                    </MenuButton>
                    <MenuList>
                        <a href={`https://www.google.com/maps/dir/?api=1&destination=${data.latitude},${data.longitude}&travelmode=walking`} target="_blank">
                            <Flex
                                dir="row"
                                padding={5}
                            >
                                <i
                                    style={{
                                        width: "30px",
                                        fontSize: 20
                                    }}
                                    className="fas fa-walking"
                                />
                                <Text>À pé</Text>
                            </Flex>
                        </a>
                        <a href={`https://www.google.com/maps/dir/?api=1&destination=${data.latitude},${data.longitude}&travelmode=driving`} target="_blank">
                            <Flex
                                dir="row"
                                padding={5}
                            >
                                <i
                                    style={{
                                        width: "30px",
                                        fontSize: 20
                                    }}
                                    className="fas fa-car"
                                />
                                <Text>De carro</Text>
                            </Flex>
                        </a>
                        <a href={`https://www.google.com/maps/dir/?api=1&destination=${data.latitude},${data.longitude}&travelmode=bicycling`} target="_blank">
                            <Flex
                                dir="row"
                                padding={5}
                            >
                                <i
                                    style={{
                                        width: "30px",
                                        fontSize: 20
                                    }}
                                    className="fas fa-biking"
                                />
                                <Text>De bicicleta</Text>
                            </Flex>
                        </a>
                        <a href={`https://www.google.com/maps/dir/?api=1&destination=${data.latitude},${data.longitude}&travelmode=transit`} target="_blank">
                            <Flex
                                dir="row"
                                padding={5}
                            >
                                <i
                                    style={{
                                        width: "30px",
                                        fontSize: 20
                                    }}
                                    className="fas fa-bus"
                                />
                                <Text>De transporte público</Text>
                            </Flex>
                        </a>
                        {/* 
                      
                        <MenuItem
                            as={Link}
                            isExternal={true}
                            href={`https://www.google.com/maps/dir/?api=1&destination=${data.latitude},${data.longitude}&travelmode=transit`}
                            icon={
                                <i
                                    style={{
                                        width: "30px",
                                        fontSize: 20
                                    }}
                                    className="fas fa-bus"
                                />
                            }
                        >
                            De transporte público
                        </MenuItem> */}
                    </MenuList>
                </Menu>
            </HStack>
            <Text fontSize="16px" color="GrayText" pb={4}>
                Quer saber como é a região do estabelecimento? Basta arrastar o boneco amarelo para o marcador vermelho no mapa para ativar a funcionalidade street view.
            </Text>
            {isLoaded ? (
                <GoogleMap
                    mapContainerStyle={containerStyle}
                    center={center}
                    zoom={17}
                >
                    <Marker position={center} />
                </GoogleMap>
            ) : (
                <></>
            )}
        </VStack>
    );
}

export default React.memo(StoreMap);
