import { useTheme } from "../../../context";
import { Heading } from "@chakra-ui/react";
import React from "react";

export function Plan({
                  active,
                  name,
                  onClick,
              }) {
    const { primaryColor, secondaryColor } = useTheme();
    return (
        <Heading
            cursor="pointer"
            padding="10px"
            textAlign="center"
            onClick={onClick}
            borderRadius="10px"
            fontSize="12pt"
            width="100%"
            {...(active
                ? {
                    background:
                        `linear-gradient(90deg, ${primaryColor}, ${secondaryColor})`,
                    color: "white",
                }
                : {})}
        >
            {name}
        </Heading>
    );
}