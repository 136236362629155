import React from 'react';

// import { Container } from './styles';

function Divider() {
  return (
    <div style={{
        height: 5,
        width: 50,
        backgroundColor: "grey",
        borderRadius: 5
    }} />
  )
}

export default Divider;