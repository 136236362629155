import { HStack, Radio, RadioGroup, Text, VStack } from "@chakra-ui/react";
import React from "react";
import { useMutation } from "react-query";
import { partiuClient } from "../../../axios";
import { useTheme } from "../../../context/theme";
import { useProfile } from "../../../context/user";

export function Agreements() {
    const { agreements = [] } = useProfile();
    const theme = useTheme();
    const profileMutation = useMutation(
        (data) => partiuClient.profile.update(data),
        {
            onSuccess(data) {
                theme.setTheme(data.active_agreement);
            }
        }
    );

    function setNewAgreement(agreement) {
        theme.setTheme({ ...theme, isLoading: true });
        profileMutation.mutate({ active_agreement: agreement });
    }

    return (
        <VStack height="100%" spacing={8} width={{ base: "100%", lg: "92%" }}>
            <Text
                color="#495057"
                fontSize="15px"
                opacity="0.7"
                textAlign="left"
                width="100%"
            >
                Verifique aqui todas as adesões vinculadas ao seu cpf. A adesão
                principal é aquela que define a aparência do App Partiu e também
                as vantagens exclusivas.
            </Text>
            <HStack
                spacing={5}
                width="100%"
                justify="flex-start"
                align="flex-start"
                overflowY="auto"
            >
                <RadioGroup
                    width="100%"
                    onChange={setNewAgreement}
                    value={theme.id}
                >
                    <VStack
                        width="100%"
                        justify="flex-start"
                        align="flex-start"
                        spacing={3}
                    >
                        {agreements.map((a) => (
                            <Radio
                                size="lg"
                                margin={0}
                                width="100%"
                                padding={0}
                                value={a.id}
                            >
                                {a.name}
                            </Radio>
                        ))}
                    </VStack>
                </RadioGroup>
            </HStack>
        </VStack>
    );
}
