import { Link } from "@chakra-ui/react";
import React from "react";
import { useSettings } from "../../context";
import { APP_EVENTS, spawnAppEvent } from "../../utils/app";

export function ExternalLink({
    to,
    target = "_blank",
    textDecoration = "underline !important",
    ...props
}) {
    const { isApp, isMobile } = useSettings();

    function onClick() {
        if (isApp) spawnAppEvent(APP_EVENTS.OPEN_LINK, to);
        else window.open(to, target);
    }

    if (isApp && !isMobile) {
        return (
            <Link minHeight={"48px"} textDecor={textDecoration} onClick={onClick} {...props}></Link>
        )
    } else {
        return (
            <a
                href={to}
                target="_blank"
                style={{
                  minHeight: '48px'
                }} rel="noreferrer"
            >
                <Link textDecor={textDecoration} {...props}></Link>
            </a>
        )
    }
}
