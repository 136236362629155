import React from "react";
import {
    Link,
    Tab,
    TabList,
    TabPanel,
    TabPanels,
    Tabs,
    Text,
    VStack
} from "@chakra-ui/react";
import { Button } from "../../../components/atoms/Button";
import { useMutation } from "react-query";
import { RFHMaskedInput } from "../../../components/atoms/RFHMaskedInput";
import { useForm } from "react-hook-form";
import { useTheme } from "../../../context/theme";
import { checkCPF } from "../../../api/register";
import { useNavigate } from "react-router-dom";
import { LoginWrapper } from "./LoginWrapper";
import { useLogin } from "../context/login.context";

export function RegistrationWithDocument() {
    const navigate = useNavigate();
    const { register, handleSubmit, getValues } = useForm();
    const { id } = useTheme();
    const { setValidatedAgreement, setValidatedDocument } = useLogin();
    const verifyMutation = useMutation(({ document }) => checkCPF(document, id), {
        onSuccess: (response) => {
            setValidatedDocument(getValues("document"))
            setValidatedAgreement(response.data.agreement)
            navigate("/login/validate-success")
        }
    });

    return (
        <LoginWrapper>
            <VStack width={{ base: "100%", lg: "80%" }}>
                <form
                    style={{ width: "100%" }}
                    onSubmit={handleSubmit(verifyMutation.mutate)}
                >
                    <VStack spacing={7} width="100%">
                        <Tabs
                            isLazy={true}
                            colorScheme="primaryScheme"
                            isFitted
                            width="100%"
                        >
                            <TabList>
                                <Tab height="48px">CPF</Tab>
                                <Tab height="48px">CNPJ</Tab>
                            </TabList>
                            <TabPanels paddingTop="30px">
                                <TabPanel padding="0px">
                                    <RFHMaskedInput
                                        hookForm={register("document")}
                                        placeholder="CPF"
                                        mask="999.999.999-99"
                                    />
                                    {verifyMutation.isError && (
                                        <Text color="red.500" textAlign={"left"} width={"100%"}>
                                            {verifyMutation.error.response.data.error}       
                                        </Text>
                                    )}
                                </TabPanel>
                                <TabPanel padding="0px">
                                    <RFHMaskedInput
                                        hookForm={register("document")}
                                        placeholder="CNPJ"
                                        mask="99.999.999/9999-99"
                                    />
                                    {verifyMutation.isError && (
                                        <Text color="red.500" textAlign={"left"} width={"100%"}>
                                            {verifyMutation.error.response.data.error}                
                                        </Text>
                                    )}
                                </TabPanel>
                            </TabPanels>
                        </Tabs>
                        <Button
                            isLoading={verifyMutation.isLoading}
                            width="100%"
                            type="submit"
                        >
                            Verificar
                        </Button>
                        <Link
                            href="#"
                            fontSize="14px"
                            textDecoration="underline !important"
                            onClick={() => navigate('/login')}
                        >
                            Selecionar outro método
                        </Link>
                    </VStack>
                </form>
            </VStack>
        </LoginWrapper> 
    );
}
