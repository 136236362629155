import { Input, InputGroup, InputLeftElement } from "@chakra-ui/react";
import React from "react";
import { useSettings } from "../../context/index";

export function SearchBar({ value, onChange }) {
    const { isIOS, isApp } = useSettings();
    const appSearchProps = {
        height: "50px",
        backgroundColor: "white",
        borderTop: "1px solid rgba(0,0,0,0.1)"
    };

    if(!isApp) {
        appSearchProps.position = "fixed";
        appSearchProps.zIndex = 999;
        appSearchProps.botton = isIOS ? "74px" : "64px";
    }

    const desktopSearchProps = {
        height: "50px",
        paddingLeft: { base: "0px", lg: "10%" }
    };

    return (
        <InputGroup
            {...(isApp ? appSearchProps : desktopSearchProps)}
            flex={!isApp && "10"}
        >
            <InputLeftElement
                height="100%"
                pointerEvents="none"
                paddingLeft={{ base: "0px", lg: "11%" }}
                children={
                    <i
                        className="fas fa-search"
                        style={{ color: "rgba(0,0,0,0.3)" }}
                    />
                }
            />
            <Input
                onChange={(event) => onChange(event.target.value)}
                height="100%"
                borderTop="0px"
                value={value}
                borderLeft="0px"
                fontSize="10pt"
                borderRadius="0px"
                borderBottom={{ base: "1px solid inherit", lg: "0px" }}
                placeholder="Digite o que deseja aqui..."
            />
        </InputGroup>
    );
}
