import React from "react";
import { Button, Center, Circle, Spinner } from "@chakra-ui/react";
import { useContext } from "react";
import { useTheme } from "../../context/index";

export const RoundedIconButton = React.forwardRef(
    (
        {
            icon,
            onClick = () => {},
            size = "48px",
            iconClassName,
            color = null,
            isLoading = false,
            ...style
        },
        ref
    ) => {
        const { primaryColor } = useTheme();

        return (
          <Button onClick={onClick} padding={0} backgroundColor={"transparent"} _hover={{backgroundColor: 'transparent'}}>
            <Circle
                width={size}
                height={size}
                backgroundColor="white"
                boxShadow="0px 0px 10px rgba(0,0,0,0.2)"
                cursor="pointer"
                transition="0.2s"
                _hover={{
                    marginTop: "-5px",
                    boxShadow: "0px 5px 10px rgba(0,0,0,0.2)"
                }}
                ref={ref}
                {...style}
            >
                {isLoading ? (
                    <Spinner size="sm" color="primaryColor" />
                ) : (
                    <i
                        className={icon + " " + iconClassName}
                        style={{ fontSize: 17, color: color || primaryColor }}
                    ></i>
                )}
            </Circle>
          </Button>
        );
    }
);
