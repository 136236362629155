import React from "react";
import { Heading } from "@chakra-ui/layout";

export const Title = ({ children, ...props }) => (
    <Heading
        fontFamily="Open Sans"
        fontSize="13pt"
        fontWeight="600"
        color="#495057"
        letterSpacing="0.03rem"
        {...props}
    >
        {children}
    </Heading>
);
