import { Box, Code, Text, VStack } from "@chakra-ui/react";
import React from "react";

export function RegistrationCode() {
    return (
        <VStack
            paddingTop="250px"
            textAlign="left"
            alignSelf="center"
            width="90%"
        >
            <Code padding="10px 25px" backgroundColor="#212429" color="white">
                curl -X POST
                https://api.partiu.com.br/api/v1/agreement_integration/vinculate/
                \<br /> -H 'Authorization: Token sua chave aqui' \<br /> -H
                'Content-Type: application/json' \<br /> -d{" "}
                {
                    '{"email": "teste@gmail.com", "full_name": "John Doe", "image_url": "https://upload.wikimedia.org/wikipedia/en/e/ed/Nyan_cat_250px_frame.PNG", "telephone": "(69) 3774-9735", "value_of_plan": 32.30, "cpf": "565.138.937-71", "groups": ["Condomínio Nova Barra", "Contrato Especial"]}'
                }
            </Code>
            <Text paddingTop="450px" alignSelf="flex-start"></Text>
            <Code padding="10px 25px" backgroundColor="#212429" color="white">
                curl -X POST
                https://api.partiu.com.br/api/v1/agreement_integration/remove/ \
                <br /> -H 'Authorization: Token sua chave aqui' \<br /> -H
                'Content-Type: application/json' \<br /> -d{" "}
                {'{"cpf": "565.138.937-71"}'}
            </Code>
            <Text paddingTop="160px" alignSelf="flex-start"></Text>
            <Code padding="10px 25px" backgroundColor="#212429" color="white">
                curl
                https://api.partiu.com.br/api/v1/agreement_integration/groups/status/
                \
                <br /> -H 'Authorization: Token sua chave aqui' \<br /> -H
                'Content-Type: application/json' \<br /> -d{" "}
                {'{"status": true, "groups": ["Condomínio Nova Barra"]}'}
            </Code>
        </VStack>
    );
}
