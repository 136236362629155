import { Link, Text, VStack, useDisclosure } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Title } from "../../../components/atoms/Title";
import { PlanDrawer } from "../../../components/organisms/PlanDrawer";
import { useTheme } from "../../../context";
import { useProfile } from "../../../context/user";
import { ActivationModal } from "./ActivationModal";
import { CPFModal } from "./CPFModal";
import { CinemaModal } from "./CinemaModal";
import { QuantityChooser } from "./QuantityChooser";
import { VirtualCard } from "./VirtualCards";

export const UseDiscount = ({
    mode,
    discount,
    link,
    company,
    category,
    promotions,
    coupon = null,
    initialValue = 0,
    userHasPermission,
}) => {
    const navigate = useNavigate();

    const _repeatShake = useState(0);
    const [quantity, setQuantity] = useState(1);
    const disclosure = useDisclosure();
    const planDisclosure = useDisclosure();
    const cinemaDisclosure = useDisclosure();
    const cpfModalDisclosure = useDisclosure();
    const { logged, cpf, setShowNoSubscriptionModal } = useProfile();

    const { integratedLogin, secondaryColor, secondaryFontColor } = useTheme();

    useEffect(() => {
        const interval = setInterval(() => {
            _repeatShake[1](_repeatShake[0] + 1);
        }, 4000);

        return () => clearInterval(interval);
    }, [_repeatShake]);

    function howToUse() {
        if (mode === "local") {
            return "Mostre o cartao virtual usando o botão abaixo e apresente-o ao estabelecimento.";
        }

        if (coupon) {
            return "Acesse o site usando o link abaixo e use o cupom no fim da compra.";
        }

        return "Acesse o site usando o link premiado abaixo, seu desconto será aplicado automaticamente.";
    }

    async function onClick(e) {
        if (!userHasPermission) {
            e.preventDefault();
            setShowNoSubscriptionModal(true);
            return;
        }

        if (mode === 'local') {
            e.preventDefault();
        }

        if (!logged)
        return navigate(
            { pathname: integratedLogin ? "/login/parceiro" : "/login" },
            { state: { showToast: true } }
        );

        if (!cpf){
            cpfModalDisclosure.onOpen();
            return
        }

        if (category === "Cinema") {
            cinemaDisclosure.onOpen();
            // navigate("compra")
            return
        }

        return disclosure.onOpen();
    }

    const renderButton = () => {
        const buttonText = (category === "Cinema") ? "Comprar" : "Fazer Check-in";
        
        return (
            <Link
                style={{ 
                    width: "100%",
                    textDecoration: "none",
                    textTransform: "uppercase",
                    backgroundColor: secondaryColor,
                    color: secondaryFontColor,
                    textAlign: "center",
                    fontWeight: 'bold',
                    display: 'block',
                    borderRadius: '6px',
                    padding: '10px 0',
                }}
                href={link}
                target="_blank"
                width="100%"
                onClick={onClick}
            >
                {buttonText}
            </Link>
        );
        
    };

    return (
        <>
            <PlanDrawer {...planDisclosure} />
            <ActivationModal
                discount={discount}
                company={company.id}
                link={link}
                initialValue={initialValue}
                {...disclosure}
                isOpen={mode === "online" && disclosure.isOpen}
            />
            <CPFModal 
                {...cpfModalDisclosure}
            />
            <CinemaModal
                discount={discount}
                company={company}
                promotions={promotions}
                link={link}
                initialValue={initialValue}
                quantity={quantity}
                {...cinemaDisclosure}
                isOpen={cinemaDisclosure.isOpen}
            />
            {logged ? (
                <VirtualCard
                    discount={discount}
                    company={company.id}
                    initialValue={initialValue}
                    {...disclosure}
                    isOpen={mode === "local" && disclosure.isOpen}
                />
            ) : (
                <></>
            )}
            <VStack
                display="block"
                bgColor="white"
                p="20px 20px"
                borderTop="2px"
                borderRadius="5px"
                borderColor="gray.800"
                filter="drop-shadow(0 0 0.75rem rgba(0,0,0,0.2))"
                alignItems="flex-start"
                textAlign="left"
                width={{ base: "100%", lg: "100%" }}
                zIndex="10"
            >
                <Title pb="10px">Usar o desconto</Title>
                <Text paddingBottom="10px">{howToUse()}</Text>
                {coupon && logged && discount.is_subscribed && userHasPermission && (
                    <Text className="Coupon" fontWeight="bold">CUPOM: {coupon.toUpperCase()}</Text>
                )}
                {discount.is_product && <QuantityChooser discount={discount} quantity={quantity} onChangeQuantity={setQuantity} />}
                {renderButton()}
            </VStack>
        </>
    );
};
