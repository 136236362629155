import { Box, Button, HStack } from "@chakra-ui/react";
import React from "react";
import { Link } from "react-router-dom";
import server from "../../server";
import { inIframe } from "../../utils";

export function IFrameBar() {
    if (!inIframe()) return <></>;

    return (
        <HStack>
            <Link to="/cadastrar-loja">
                <Button fontSize="14px">SEJA PARCEIRO</Button>
            </Link>
        </HStack>
    );
}
