import { Input as ChakraInput } from "@chakra-ui/input";
import { Flex, Heading } from "@chakra-ui/layout";
import React, { useContext, useEffect, useState } from "react";
import ReactInputMask from "react-input-mask";
import { useTheme } from "../../context/index";

export const Input = React.forwardRef(
    (
        {
            value,
            onChange = () => {},
            mask = null,
            disabled = false,
            type = "text",
            placeholder = "",
            required = false,
            hookForm = {},
            ...props
        },
        ref
    ) => {
        const { secondaryColor } = useTheme();
        if (mask)
            return (
                <ReactInputMask
                    type={type}
                    disabled={disabled}
                    onChange={(e) => onChange(e.target.value)}
                    mask={mask}
                    value={value}
                    required={required}
                    placeholder={placeholder}
                    style={{
                        fontSize: "1rem",
                        paddingRight: "1rem",
                        height: "48px",
                        borderRadius: "0",
                        border: "0px solid",
                        borderBottom:"1px solid",
                        borderColor: "rgb(226, 232, 240)",
                        width: "100%",
                        backgroundColor:"transparent",
                        paddingLeft:"0px"
                    }}
                    {...props}
                />
            );

        return (
            <ChakraInput
                ref={ref}
                width="100%"
                disabled={disabled}
                placeholder={placeholder}
                type={type}
                required={required}
                backgroundColor="transparent"
                variant="flushed"
                focusBorderColor={secondaryColor}
                value={value}
                letterSpacing="0.03rem"
                onChange={(e) => onChange(e.target.value)}
                {...hookForm}
                {...props}
            />
        );
    }
);
