import { Heading } from "@chakra-ui/layout";
import React, { useContext } from "react";
import { useTheme } from "../../context/index";
import { Title } from "./Title";

export const SectionHeader = ({ title, ...style }) => {
    return (
        <Title margin="0px" marginTop="15px" {...style}>
            {title}
        </Title>
    );
};
