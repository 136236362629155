import {
  Box,
  Button,
  Divider,
  Flex,
  Image,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Spacer,
  Spinner,
  Text,
  useToast,
  VStack
} from "@chakra-ui/react";
import { faBook, faBookReader, faDownload, faEnvelope, faFileAudio } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useMemo, useState } from "react";
import { useMutation, useQuery } from "react-query";
import { Link, useNavigate } from "react-router-dom";
import { addBookToLibrary, sendBookToUserEmail, userHasPermissionToBook } from "../../../api/books";
import { Title } from "../../../components/atoms/Title";
import { useTheme } from "../../../context";
import { useProfile } from "../../../context/user";
import { EmailModal } from "./EmailModal";

export function BookModal({ id, title, author, agreement, file, audiobooks, origin, pdf, cover, description, isOpen, onClose }) {
    const toast = useToast();
    const { uraModuleCellphone} = useTheme();
    const { logged, setShowNoSubscriptionModal } = useProfile();
    const navigate = useNavigate();
    const { integratedLogin, id:agreementID } = useTheme();
    const [ emailModalIsOpen, setEmailModalIsOpen] = useState(false)

    const hasPermissionUseQuery = useQuery('hasPermission', () => userHasPermissionToBook(id, agreementID), {
        enabled: !!id && !!agreementID
    })

    const onEmailModalClose = () => {
        setEmailModalIsOpen(false);
    }

    const downloadBookMutation = useMutation(() => addBookToLibrary(id, agreementID), {
        async onSuccess(data) {
            if (data.status === 400) {
                toast({
                    title: "Oops",
                    description: "Esse livro não está disponível em seu combo. Para ter acesso à esse livro melhore seu combo."
                });
                return navigate({ pathname: "/combos" })
            }
            if (data.status === 403) return navigate({ pathname: "/combos" }, { state: { showToast: true } });

            const link = document.createElement('a');
            link.href = pdf ? pdf : file;
            link.setAttribute('download', true);
            // link.setAttribute('target', "_blank");
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        },
        onError(error) {
            navigate({ pathname: "/combos" });
        }
    });

    const addToLibraryMutation = useMutation(() => addBookToLibrary(id, agreementID), {
        onSuccess(data) {
            if (data.status === 400) {
                toast({
                    title: "Oops",
                    description: "Esse livro não está disponível em seu combo. Para ter acesso à esse livro melhore seu combo."
                });
                return;
            }
            if (data.status === 403) {
                setShowNoSubscriptionModal(true);
                return;
            }
            navigate(
                { pathname: "/drm/ebook" },
                {
                    state: {
                        url: data.file,
                        author,
                        title: title,
                        pdf: pdf
                    }
                }
            )
        },
        onError(error) {
            if (error.response.status === 403) setShowNoSubscriptionModal(true);
        }
    });

    const addAudioBookToLibraryMutation = useMutation(() => addBookToLibrary(id, agreementID), {
        onSuccess(data) {
            if (data.status === 400) {
                toast({
                    title: "Oops",
                    description: "Esse audiobook não está disponível em seu combo. Para ter acesso à esse livro melhore seu combo."
                });
                return;
            }
            if (data.status === 403) {
                setShowNoSubscriptionModal(true);
                return;
            }
            navigate(
                { pathname: "/drm/audiobook" },
                {
                    state: {
                        url: data.file,
                        author,
                        title: title,
                        audiobooks: audiobooks,
                        cover: cover,
                    }
                }
            )
        },
        onError(error) {
            if (error.response.status === 403) setShowNoSubscriptionModal(true);
        }
    });

    const sendBookToMailMutation = useMutation(() => sendBookToUserEmail(id, agreementID), {
        onSuccess(data) {
            if (data.status === 400) {
                toast({
                    title: "Oops",
                    description: "Esse livro não está disponível em seu combo. Para ter acesso à esse livro melhore seu combo."
                });
                return;
            }
            if (data.status === 403) {
                setShowNoSubscriptionModal(true);
                return;
            }
            toast({
                title: 'Email enviado com sucesso!',
                description: "Você receberá o livro em seu email em alguns minutos.",
                status: 'success',
                duration: 9000,
                isClosable: true,
            })
        },
        onError(error) {
            if (error.response.status === 403) setShowNoSubscriptionModal(true);
        }
    });

    function openBook(file) {
        window.open(file, "_blank");
    }

    function onDownloadBook() {
        if (!logged)
            return navigate(
                { pathname: integratedLogin ? "/login/parceiro" : "/login" },
                { state: { showToast: true } }
            );

        downloadBookMutation.mutate();
    }

    function onAddToLibrary() {
        if (!logged)
            return navigate(
                { pathname: integratedLogin ? "/login/parceiro" : "/login" },
                { state: { showToast: true } }
            );

        /*if (agreement) return openBook(file)*/

        addToLibraryMutation.mutate();
    }

    function onAudioBookAddToLibrary() {
        if (!logged)
            return navigate(
                { pathname: integratedLogin ? "/login/parceiro" : "/login" },
                { state: { showToast: true } }
            );

        /*if (agreement) return openBook(file)*/

        addAudioBookToLibraryMutation.mutate();
    }

    function onSendBookToEmail() {
        if (!logged)
            return navigate(
                { pathname: integratedLogin ? "/login/parceiro" : "/login" },
                { state: { showToast: true } }
            );

        sendBookToMailMutation.mutate();
    }

    const userHasPermissionToAccessThisBook = useMemo(() => !hasPermissionUseQuery.isLoading && hasPermissionUseQuery.isSuccess, [hasPermissionUseQuery])

    if (!title) return <></>;

    return (
        <Modal size={{ base: "full", lg: "sm" }} isCentered isOpen={isOpen} onClose={onClose}>
            <ModalOverlay />
            <ModalContent maxWidth={"600px"} maxHeight={"100vh"} overflowY={"auto"}>
              <ModalCloseButton height={"48px"} width={"48px"} backgroundColor={"white"} border="2px solid black" color="black"/>
                <ModalBody padding="0px">
                    <Flex flexDir={{ base: "column", lg: "row" }} height="100%" align="stretch">
                        <Flex
                          flex="1"
                          backgroundColor="#ddd !important"
                          borderTopLeftRadius="6px"
                          borderBottomLeftRadius={{ base: "0px", lg: "6px" }}
                          borderTopRightRadius={{ base: "6px", lg: "0px" }}
                        >
                          <Image
                            objectFit="contain"
                            objectPosition="center"
                            height={"100%"}
                            width={"100%"}
                            flex="1"
                            borderTopLeftRadius="6px"
                            borderBottomLeftRadius={{ base: "0px", lg: "6px" }}
                            borderTopRightRadius={{ base: "6px", lg: "0px" }}
                            borderRight="1px solid #ddd"
                            src={cover} 
                          />
                        </Flex>


                        <VStack flex="1" gap={0} padding="20px 10px" paddingRight="15px" align="flex-start"
                            justify="flex-start">
                            <Title maxWidth={{ base: "100%", lg: "270px" }} fontWeight="bold"
                                fontSize="14pt">{title.toUpperCase()}</Title>
                            
                            <Title fontSize="9pt" textTransform="capitalize"
                                opacity="0.7">{author?.toLowerCase()}</Title>
                            <Divider width="100%" paddingTop="10px" />
                            {
                              description && description.length && (
                               <>
                                <Title paddingTop="10px" fontWeight="bold" fontSize="12pt">Sobre</Title>
                                <Box
                                  style={{ fontSize: "10pt", maxHeight: "200px", overflowY: "auto", overflowX: "hidden" }}
                                  dangerouslySetInnerHTML={{ __html: description }} />
                                <Spacer />
                                <Divider width="100%" paddingTop="10px" />
                                </>
                              )
                            }
                            <Box height="5px" />
                            {hasPermissionUseQuery.isLoading && (
                                <VStack width={"100%"} justifyContent={"center"}>
                                    <Spinner size="sm" />
                                </VStack>
                            )}
                            {userHasPermissionToAccessThisBook && <Button onClick={onAddToLibrary} 
                                colorScheme="green" width="100%"
                                leftIcon={<FontAwesomeIcon icon={faBookReader} />}>Ler Agora</Button>}
                            {(userHasPermissionToAccessThisBook) && origin === 'partiu' && <Button onClick={onDownloadBook}
                                                      colorScheme="cyan" width="100%"
                                                      leftIcon={<FontAwesomeIcon icon={faDownload} />}>Download</Button>}
                            {((userHasPermissionToAccessThisBook) && audiobooks.length > 0) && <Button onClick={onAudioBookAddToLibrary}
                                        colorScheme="blue" width="100%"
                                        leftIcon={<FontAwesomeIcon icon={faFileAudio} />}>Ouvir Agora</Button>
                            }
                            {(userHasPermissionToAccessThisBook) && origin === 'partiu' && <Button onClick={() => setEmailModalIsOpen(true)}
                                        colorScheme="orange" width="100%"
                                        leftIcon={<FontAwesomeIcon icon={faEnvelope} />}>Receber por email</Button>
                            }
                            {(userHasPermissionToAccessThisBook && uraModuleCellphone !== "" && audiobooks.length > 0) &&
                              <Link to={`tel:${uraModuleCellphone.replace(/\s/g, "")}`} style={{ width: '100%' }}>
                                <Button colorScheme="green" width="100%"
                                          leftIcon={<FontAwesomeIcon icon={faBook} />}>
                                  URA
                                </Button>
                              </Link>
                            }
                            {
                              hasPermissionUseQuery.isError && hasPermissionUseQuery.error?.response?.status === 403 && (
                                <VStack alignItems={"center"} width={"100%"}>
                                  <Text textAlign={"center"}>Esse livro não está incluído no seu combo. Deseja fazer upgrade do combo?</Text>
                                  <Button onClick={() => navigate("/combos")} size={"sm"}>
                                    Fazer upgrade do combo
                                  </Button>
                                </VStack>
                              )
                            }
                            <EmailModal isOpen={emailModalIsOpen}
                                        onClose={onEmailModalClose}
                                        sendEmail={() => onSendBookToEmail(id, agreementID)}
                            />
                        </VStack>
                    </Flex>
                </ModalBody>
            </ModalContent>
        </Modal>
    );
}
