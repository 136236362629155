import {
    Button,
    Heading,
    Input,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    Text,
    useToast
} from "@chakra-ui/react";
import React, { useState } from "react";
import { sendPartnerEnquiry } from "../../../api/register";
import { FormInput } from "../../../components/atoms/FormInput";
import { useTheme } from "../../../context";
import { useNavigate } from "react-router-dom";

export const SetPasswordModal = ({
    isOpen,
    onClose,
    company,
    store,
    discount,
    user,
    exclusive
}) => {
    const toast = useToast();

    const [password, setPassword] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [worked, setWorked] = useState(false);

    const { id, _ } = useTheme();

    const [link, setLink] = useState("");

    const navigate = useNavigate();

    async function onSubmit() {
        if (worked) {
            onClose();
            return;
        }

        setIsLoading(true);
        try {
            if (
                !/^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/.test(
                    password
                )
            ) {
                setIsLoading(false);

                return toast({
                    title: "Oops!",
                    status: "warning",
                    description:
                        "Sua senha deve ter pelo menos 8 caracteres, precisando ter no minimo um numero e um caractere especial."
                });
            }

            let sendCompany = {
                ...company,
                approved_data: true,
                creator_origin: "landing",
                creator_agreement: id
            };

            if (exclusive) {
                sendCompany = {
                    ...sendCompany,
                    agreement: id
                };
            }

            delete sendCompany.cover_photo;
            delete sendCompany.logo;

            const data = new FormData();

            const whiteSpace =
                "\u0009\u000a\u000b\u000c\u000d\u0020\u00a0" +
                "\u1680\u180e\u2000\u2001\u2002\u2003\u2004" +
                "\u2005\u2006\u2007\u2008\u2009\u200a\u200b" +
                "\u2028\u2029\u202f\u205f\u3000";

            const re = new RegExp(`[${whiteSpace}]`, "gmu");

            data.append(
                "company",
                `${JSON.stringify(JSON.stringify(sendCompany, null, "\t"))
                    .replace(re, "\u0020")
                    .replace(/ +/, "\u0020")}`
            );
            data.append(
                "store",
                `${JSON.stringify(JSON.stringify(store, null, "\t"))
                    .replace(re, "\u0020")
                    .replace(/ +/, "\u0020")}`
            );
            data.append(
                "discount",
                `${JSON.stringify(JSON.stringify(discount, null, "\t"))
                    .replace(re, "\u0020")
                    .replace(/ +/, "\u0020")}`
            );
            data.append(
                "user",
                `${JSON.stringify(
                    JSON.stringify(
                        {
                            ...user,
                            password
                        },
                        null,
                        "\t"
                    )
                )
                    .replace(re, "\u0020")
                    .replace(/ +/, "\u0020")}`
            );

            data.append("cover_photo", company.cover_photo);
            data.append("logo", company.logo);
            data.append("discount_image", discount.image);

            delete discount.image;

            const response = await sendPartnerEnquiry(data);

            if (response.status === 201) {
                if (response?.data?.link) {
                    setWorked(true);
                    setLink(response.data.link);
                }
                onClose();
                navigate("/sucesso");
            } else {
                toast({
                    title: "Oops!",
                    description: response.data,
                    status: "error",
                    isClosable: true
                });
            }
            setIsLoading(false);
            onClose();
        } catch (err) {
            let errorMessage =
                "Revise as informações do formulário e tente novamente.";

            if (err?.response?.data) {
                const errorData = err?.response?.data;
                errorMessage = Object.keys(errorData)
                    .map((key) => `${errorData[key].join(" ")}`)
                    .join(", ");
            }

            toast({
                title: "Oops!",
                description: errorMessage,
                status: "error"
            });
            setIsLoading(false);
        }
    }

    return (
        <Modal
            closeOnOverlayClick={false}
            closeOnEsc={false}
            isOpen={isOpen}
            onClose={onClose}
        >
            <ModalOverlay />
            <ModalContent>
                <ModalCloseButton />
                <ModalHeader>Crie sua Senha</ModalHeader>
                {worked && (
                    <ModalBody>
                        <Text paddingBottom="10px">
                            Parabéns, cadastro criado com sucesso. Seu login e
                            senha foram enviados por e-mail. Em breve um
                            consultor entrará em contato para realizar um
                            treinamento com você.
                        </Text>
                        <a
                            href={link}
                            rel="noreferrer"
                            target="_blank"
                            paddingBottom="10px"
                            style={{
                                color: "blue"
                            }}
                        >
                            Para acessar entre no site:
                            https://pdv.partiu.com.br
                        </a>
                    </ModalBody>
                )}
                {!worked && (
                    <ModalBody>
                        <Text paddingBottom="10px">
                            Crie sua senha para acesso do sistema PDV
                        </Text>
                        <FormInput
                            value={company.email}
                            label={"Email"}
                            placeholder="Email"
                            detail="Email que será utilizado no login!"
                            type="email"
                            disabled
                        />
                        <Heading
                            textAlign="left"
                            marginBottom="10px"
                            marginTop={"10px"}
                            fontSize="14px"
                        >
                            SENHA
                        </Heading>
                        <Input
                            width="100%"
                            placeholder="Digite sua senha"
                            type={"password"}
                            required={true}
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                        />
                        <Text
                            paddingBottom="10px"
                            fontSize={"12px"}
                            marginTop={"4px"}
                        >
                            A senha precisa de pelo menos 8 caracteres,
                            precisando ter no minimo um numero e um caractere
                            especial não pode ser parecido com seu email, não
                            pode usar sequências como: 123, abc, etc..."
                        </Text>
                    </ModalBody>
                )}
                <ModalFooter>
                    <Button
                        onClick={onSubmit}
                        isLoading={isLoading}
                        colorScheme="green"
                    >
                        {worked ? "Fechar" : "Continuar"}
                    </Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
};
