import { Heading } from "@chakra-ui/layout";
import React, { useContext, useState } from "react";
import { HStack, Link, VStack } from "@chakra-ui/react";
import { useTheme } from "../../../context/index";
import { Title } from "../../../components/atoms/Title";

function Question({ title, children }) {
    const [open, setOpen] = useState(false);

    return (
        <VStack
            width="100%"
            onClick={() => setOpen(!open)}
            cursor="pointer"
            spacing={0}
            minHeight={{
                base: open ? "auto" : "60px",
                lg: open ? "auto" : "40px"
            }}
        >
            <HStack
                width="100%"
                backgroundColor="white"
                boxShadow="0 2px 5px rgba(0,0,0,.2)"
                borderRadius="2px"
                justifyContent="space-between"
                padding="15px 20px"
            >
                <Heading fontSize="16px">{title}</Heading>
                <i className="fas fa-chevron-down" />
            </HStack>
            <VStack
                width="100%"
                padding={open ? "20px 50px 20px 30px" : "0px"}
                backgroundColor="white"
                boxShadow="0 2px 5px rgba(0,0,0,.2)"
                borderRadius="2px"
                textAlign="left"
                height={open ? "auto" : "0px"}
                zIndex={open ? "0" : "-1"}
                transition="0.2s"
                key={open}
                className={`animate__animated ${
                    open ? "animate__zoomIn" : "animate__zoomOut"
                }`}
                alignItems="flex-start"
            >
                {children}
            </VStack>
        </VStack>
    );
}

export const FAQ = (props) => {
    const { name, id, canLoginCPF } = useTheme();
    return (
        <VStack
            backgroundColor="#f6f5fa"
            spacing={0}
            padding="30px 0px"
            paddingBottom="100px"
            width="100%"
            borderTop="1px solid #eee"
        >
            <Title fontSize="16pt" fontWeight="700" paddingBottom="30px">
                FAQ
            </Title>
            <VStack width="80%" spacing={7}>
                <Question
                    title={`Como faço para usar as promoções do ${name}
                            Vantagens.`}
                >
                    {id !== 251 ? (
                        <div className="content">
                            Simples, se sua unidade for participante do clube,
                            basta seguir os passos:
                            <ol>
                                <li>
                                    Do seu celular, acesse a App Store ou Google
                                    Store.{" "}
                                </li>
                                <li>
                                    Busque o aplicativo PARTIU VANTAGENS e faça
                                    o download.
                                </li>
                                {canLoginCPF === "null" || canLoginCPF === 0 ? (
                                    <div>
                                        <li>
                                            Na tela inicial, clique em TENHO UM
                                            CÓDIGO PROMOCIONAL.{" "}
                                        </li>
                                        <li>
                                            Ao solicitar o código, digite uma
                                            das combinações que você recebeu da
                                            sua empresa.{" "}
                                        </li>
                                        <li>
                                            Ao aparecer a tela de boas-vindas da
                                            Partiu {name}, clique em ACEITAR{" "}
                                        </li>
                                    </div>
                                ) : (
                                    <div>
                                        <li>
                                            Na tela inicial, clique em QUERO ME
                                            REGISTRAR.{" "}
                                        </li>
                                        <li>Cadastre-se usando seu CPF.</li>
                                    </div>
                                )}
                                <li>
                                    Escolha as promoções disponíveis no
                                    aplicativo{" "}
                                </li>
                                <li>
                                    No menu principal, selecione a opção “USAR
                                    AGORA”.{" "}
                                </li>
                                <li>
                                    Quando for utilizar, apresente o cartão
                                    gerado ao estabelecimento, observando as
                                    Regras Gerais de Uso.
                                </li>
                            </ol>
                        </div>
                    ) : (
                        <div className="content">
                            <ol>
                                <li>
                                    Acesse:{" "}
                                    <a href="https://universitariomais.com.br/assinante">
                                        https://universitariomais.com.br/assinante
                                    </a>
                                </li>
                                <li>Insira o seu CPF e a senha;</li>
                                <li>Clique em "Confira os Descontos";</li>
                                <li>
                                    Pronto! Agora é só aproveitar os benefícios!
                                </li>
                            </ol>
                        </div>
                    )}
                </Question>
                {id !== 251 ? (
                    <Question
                        title={`Um cartão dá desconto para quantas pessoas?`}
                    >
                        <p>
                            Isso varia de acordo com estabelecimento, pois as
                            promoções podem ser individuais ou coletivas. Sempre
                            consulte a descrição e regulamento de cada promoção.
                        </p>
                    </Question>
                ) : null}

                {id !== 251 ? (
                    <Question
                        title={`Estou com problemas para gerar meu cartão
                    virtual. Como procedo?`}
                    >
                        <p>
                            No próprio aplicativo, escolha a opção “Fale com a
                            Partiu!” e descreva seu problema.
                        </p>
                    </Question>
                ) : null}

                {id === 251 ? (
                    <Question
                        title={`Estou com dúvidas para acessar a plataforma.
                    Como procedo?`}
                    >
                        <p>
                            Entre em contato através do e-mail{" "}
                            <a href="mailto:contato@partiu.com.br">
                                contato@partiu.com.br
                            </a>{" "}
                            e envie a sua dúvida.
                        </p>
                    </Question>
                ) : null}

                {id !== 251 ? (
                    <Question
                        title={`Não tenho um smartphone. Como faço para usar o
                    clube?`}
                    >
                        <p style={{ textAlign: "left", width: "100%" }}>
                            Também é possível gerar o código virtual na página
                            'rede' deste site.
                        </p>
                    </Question>
                ) : null}
                <Question
                    title={`Tenho uma empresa ou quero indicar
                        ${id !== 251 ? "um estabelecimento" : "uma empresa"} 
                        para fazer parte dos parceiros do Clube Partiu 
                        ${name}. Como faço?`}
                >
                    <p>
                        Você tem um estabelecimento e gostaria que fizesse parte
                        do Clube? É fácil! Clique{" "}
                        <Link href="/cadastrar-loja#form">aqui</Link> e faça o
                        cadastro da sua loja!
                    </p>
                </Question>
            </VStack>
        </VStack>
    );
};
