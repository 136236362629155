import React, { useEffect, useState } from "react";
import { Box, TabPanel, TabPanels, Tabs, VStack } from "@chakra-ui/react";
import { useTheme } from "../../context";
import { useQuery } from "react-query";
import { getBooks, getFeaturedBooks, getMyBooks } from "../../api/books";
import { BookHStack } from "./components/BookHStack";
import { FilterBar } from "../../components/atoms/FilterBar";
import { CustomTabList } from "../../components/organisms/CustomTabList";
import { CustomTab } from "../../components/molecules/CustomTabs";
import { BookInfiniteList } from "./components/BookInfiniteList";
import { BookModal } from "./components/BookModal";
import { TopMenu } from "../../components/organisms/TopMenu";
import { useProfile } from "../../context/user";
import { useLocation } from "react-router-dom";
import { BookCategoryTags } from "./components/BookCategoryTags";

export function BooksPage() {
    const [search, setSearch] = useState("");
    const [selectedBook, setSelectedBook] = useState(null);
    const [categories, setCategories] = useState(new Set());
    const { state } = useLocation();

    const { bookstoreType, id } = useTheme();
    const { logged } = useProfile();
    const { data: newBooks, isNewBooksLoading } = useQuery("books", () => getFeaturedBooks(id));

    useEffect(() => {
        if (state?.book) return setSelectedBook(state.book);
        if (state) return setCategories(new Set(state));
    }, [state]);

    return (
        <Box height="100%" overflowY="auto">
            <TopMenu />
            <BookModal isOpen={selectedBook !== null} onClose={() => setSelectedBook(null)} {...selectedBook} agreement={id} />
            <Box paddingTop={{ base: "88px" }}></Box>
            <BookHStack title={`${bookstoreType === "book" ? "Livros" : "Revistas"} do Mês`}
                        subtitle={`${bookstoreType === "book" ? `Abaixo estão os livros do mês. Os livros abaixo que pertencem ao seu combo são seus para sempre! Todo mês, até o dia 05, você receberá novos livros. Todos esses títulos estarão disponíveis na aba "Livros Recebidos".`: `Abaixo estão as revistas do mês. As revistas abaixo que pertencem ao seu combo são suas para sempre! Todo mês, até o dia 31, você receberá novas revistas. Todos esses títulos estarão disponíveis na aba "Revistas Recebidas`}`}
                        onSelectBook={setSelectedBook} isLoading={isNewBooksLoading}
                        books={newBooks} />
            <Tabs isLazy width="100%">
                <VStack width="100%" spacing={0} paddingTop="10px" id="search-bar">
                    <CustomTabList>
                        <CustomTab icon="fas fa-book" fontWeight="bold">
                            {bookstoreType ? 'Biblioteca' : 'Banca'}
                        </CustomTab>
                        {logged ? <CustomTab icon="fas fa-book-open" fontWeight="bold">
                            {`${bookstoreType === "book" ? "Meus Livros" : "Minhas Revistas"}`}
                        </CustomTab> : <></>}
                    </CustomTabList>
                    <FilterBar onSearchChange={setSearch} search={search} />
                    <BookCategoryTags onSelectTag={(cId) => {
                        const newSet = new Set(categories)
                        if (newSet.has(cId)) newSet.delete(cId)
                        else newSet.add(cId)

                        setCategories(newSet)
                    }} activeTags={categories} />
                </VStack>
                <TabPanels padding={{ base: "0% 0%", lg: "0px 10%" }}>
                    <TabPanel>
                        <BookInfiniteList getFunc={getBooks} onSelectBook={setSelectedBook}
                                          params={{ search, category: [...categories] }} />
                    </TabPanel>
                    <TabPanel>
                        <BookInfiniteList getFunc={getMyBooks} onSelectBook={setSelectedBook}
                                          params={{ search, category: [...categories] }} />
                    </TabPanel>
                </TabPanels>
            </Tabs>
        </Box>
    );
}
