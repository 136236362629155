import { VStack } from "@chakra-ui/react";
import React from "react";
import { Title } from "../components/atoms/Title";
import { Banner } from "../components/molecules/Banner";
import { Footer } from "../components/organisms/Footer";
import { useSettings, useTheme } from "../context";

export function BannerPageLayout({ title, className, children, ...props }) {
    const { defaultBackgroundColor } = useTheme();
    const { isApp } = useSettings();
    return (
        <VStack {...props} className={className} spacing={0} backgroundColor="#FAFAFA">
            {!isApp ? (
                <Banner title={title} />
            ) : (
                <Title paddingLeft={{ base: "5%", lg: "0px" }} alignSelf={{ base: "flex-start", lg: "center" }} fontSize="20pt" paddingTop="20px">
                    {title}
                </Title>
            )}
            <VStack
                borderLeft="1px solid #eee"
                borderRight="1px solid #eee"
                width={isApp ? "100%" : "70%"}
                padding={isApp ? "50px 5%" : "40px 10%"}
                paddingTop={{ base: "20px", lg: "50px" }}
                backgroundColor={isApp ? defaultBackgroundColor : "white"}
                spacing={7}
            >
                {children}
            </VStack>
        </VStack>
    );
}
