import {
    faBook,
    faIdCard,
    faMobileAlt,
    faQuestionCircle,
    faStore,
    faUser
} from "@fortawesome/free-solid-svg-icons";
import { useTheme } from "./theme";
import { useProfile } from "./user";

export function useAvailableLinks() {
    const links = [];
    const { logged, subscribed = false } = useProfile();
    const {
        bookstoreModule,
        planSaleModule,
        ecommerceModule,
        localModule,
        bePartnerModule,
        bookstoreType
    } = useTheme();

    if (ecommerceModule || localModule)
        links.push({
            name: "Vantagens",
            link: "/busca",
            icon: "fas fa-store",
            iconClass: faStore
        });

    //if (bookstoreModule) links.push({ name: bookstoreType === "book" ? "Livros" : "Revistas", link: "/livros", icon: "fas fa-book", iconClass: faBook });

    if (planSaleModule && bookstoreModule && subscribed)
        links.push({
            name: "Combos",
            link: "/combos",
            iconClass: faIdCard,
            icon: "fas fa-id-card"
        });

    if (bePartnerModule)
        links.push({
            name: "Seja Parceiro",
            link: "/cadastrar-loja",
            icon: "fas fa-users",
            iconClass: faUser
        });

    links.push({
        name: "Fale Conosco",
        link: "/contato",
        icon: "fas fa-mobile-alt",
        iconClass: faMobileAlt
    });

    if (ecommerceModule || localModule)
        links.push({
            name: "Sobre o Clube",
            link: "/sobre-o-clube",
            icon: "fas fa-question-circle",
            iconClass: faQuestionCircle
        });

    return links;
}

export function useTopMenuLinks() {
    const links = [];
    const { logged, subscribed = false } = useProfile();
    const {
        bookstoreModule,
        planSaleModule,
        ecommerceModule,
        localModule,
        bePartnerModule,
        bookstoreType
    } = useTheme();

    if (ecommerceModule || localModule)
        links.push({
            name: "Vantagens",
            link: "/busca",
            icon: "fas fa-store",
            iconClass: faStore
        });

    if (bookstoreModule && logged)
        links.push({
            name: bookstoreType === "book" ? "Biblioteca" : "Banca",
            link: bookstoreType === "book" ? "/biblioteca" : "/banca",
            icon: "fas fa-book",
            iconClass: faBook
        });

    if (bePartnerModule)
        links.push({
            name: "Seja Parceiro",
            link: "/cadastrar-loja",
            icon: "fas fa-users",
            iconClass: faUser
        });

    return links;
}
