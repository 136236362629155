import { IconButton, Flex, Image, useToast, VStack } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import { useSettings } from "../../context/index";
import { saveTheme, useTheme } from "../../context/theme";
import { useProfile } from "../../context/user";
import { Login } from "../Login/components/Login";

export const LoginSteps = {
  CHOOSE_METHOD: 0,
  VERIFY_METHOD: 1,
  CONFIRM_AGREEMENT: 2,
  PERSONAL_INFORMATION: 3,
  LOGIN: 4,
  SUBSCRIPTION_DETAIL: 5
};

export function AuthPage() {
  const navigate = useNavigate();
  const [mode, setMode] = useState();
  const [agreement, setAgreement] = useState();
  const [promocode, setPromocode] = useState();
  const [cpf, setCPF] = useState();

  const { state } = useLocation();
  const { isApp, isIOS } = useSettings();
  const { logged } = useProfile();
  const { logo } = useTheme();
  const toast = useToast();

  async function login(token, _agreement) {
    localStorage.setItem("token", token);

    if (isApp && _agreement) {
      saveTheme({ ..._agreement, isLoading: true });
    }

    // if (mode !== "cpf" && mode !== "promocode" && mode !== "login")
    //     navigate({ pathname: "/combos" });

    // window.location.reload();
  }

  useEffect(() => {
    if (!state?.showToast) return;

    toast({
      title: "Faça Login.",
      description:
        "Para acessar o clube é necessário que você esteja logado em uma conta. Escolha uma das opções a seguir:",
      isClosable: true,
      position: "bottom"
    });
  }, [state]);

  if (logged) return <Navigate to={{ pathname: "/" }} />;

  return (
    <VStack
      forcePrimaryColor={agreement?.primaryColor}
      forceSecondaryColor={agreement?.secondaryColor}
      marginTop={isIOS ? "-35px" : "0px"}

      justify="center"
      align="center"
      direction="column"
      overflowY="auto"
      margin="auto"
      paddingBottom={"50px"}
      width={{ base: "100%", lg: "50%" }}
    >
      <Flex justify="center" align="center" width="100%" height="100%">
        <Flex
          display={{ base: "none", md: "flex" }}
          flex="1"
          height="100%"
          backgroundColor="#FAFAFA"
          justify="center"
          align="center"
        >
          <Image
            w="300px"
            objectFit="contain"
            src={agreement?.logo || logo}
          />
        </Flex>
        <Flex
          direction="column"
          spacing={10}
          justify="center"
          width="100%"
          align="center"
          minHeight="100%"
        >
          <Flex justify="center" align="center" minH="250px" flex="1">
            <Image
              maxW="300px"
              // w="30%"
              objectFit="contain"
              src={agreement?.logo || logo}
            />
          </Flex>
          <VStack flex="1" width="80%">
            <Login
              {...{
                setMode,
                mode,
                setStep: () => null,
                login,
                agreement,
                setAgreement,
                promocode,
                setPromocode,
                cpf,
                setCPF,
                showSignup: false
              }}
              forceSubscriptions={[]}
            />
          </VStack>
        </Flex>
      </Flex>
    </VStack>
  );
}
