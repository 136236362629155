import { Button } from "@chakra-ui/button";
import { InputLeftAddon, InputGroup, Input } from "@chakra-ui/input";
import { Heading } from "@chakra-ui/layout";
import { Flex } from "@chakra-ui/layout";
import { HStack } from "@chakra-ui/layout";
import { Text } from "@chakra-ui/layout";
import { VStack, Stack } from "@chakra-ui/layout";
import {
    ModalContent,
    ModalOverlay,
    ModalBody,
    ModalFooter
} from "@chakra-ui/modal";
import { Modal } from "@chakra-ui/modal";
import React, { useEffect, useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import { createSale } from "../../../api/sales";

const Thumb = ({ onClick, direction = "up", color = "green", active }) => (
    <Flex
        cursor="pointer"
        onClick={onClick}
        border="1px solid rgba(0,0,0,0.2)"
        borderRadius="10px"
        padding="20px"
        backgroundColor="#eee"
        {...(direction === "up"
            ? { borderTopRightRadius: 0, borderBottomRightRadius: 0 }
            : { borderBottomLeftRadius: 0, borderTopLeftRadius: 0 })}
    >
        <i
            style={{
                color: active ? color : "rgba(0,0,0,0.2)",
                fontSize: 25,
                transition: "0.2s"
            }}
            className={`fas fa-thumbs-${direction}`}
        ></i>
    </Flex>
);

export const RateExperience = ({
    isOpen,
    onClose,
    company,
    discount,
    initialValue = 0,
    virtualCardCode
}) => {
    
    const [rating, setRating] = useState(true);
    const queryClient = useQueryClient();
    const [value, setValue] = useState(
        parseFloat(discount.original_price) * discount.discount
    );
    const createMutation = useMutation(() => 
        createSale({
            feedback: rating,
            user_given_discount: value,
            discount: discount.id,
            virtual_card: virtualCardCode
        })
    );

    useEffect(() => {
        setRating(true);
        setValue(initialValue);
    }, [isOpen]);

    return (
        <Modal
            isCentered={true}
            size={{ base: "md", lg: "md" }}
            isOpen={isOpen}
            onClose={onClose}
            closeOnOverlayClick={false}
        >
            <ModalOverlay
                backgroundColor="rgba(0,0,0,0.8)"
                style={{
                    backdropFilter: "blur(5px)",
                    "-webkit-backdrop-filter": "blur(5px)"
                }}
            />
            <ModalContent maxWidth="500px" width="90%">
                <ModalBody padding="20px">
                    <VStack>
                        <Heading fontSize="18px">
                            Como foi seu desconto {company.name}?
                        </Heading>
                        <Text fontSize="16px">
                            Seu feedback nos ajuda a manter a boa qualidade
                            Partiu.
                        </Text>
                        <HStack paddingTop="20px" spacing={0}>
                            <Thumb
                                onClick={() => setRating(true)}
                                active={rating}
                                color="green"
                                direction="up"
                            />
                            <Thumb
                                onClick={() => setRating(false)}
                                active={!rating}
                                color="red"
                                direction="down"
                            />
                        </HStack>
                    </VStack>
                </ModalBody>
                <ModalFooter
                    borderBottomRightRadius="10px"
                    borderBottomLeftRadius="10px"
                    backgroundColor="#FAFAFA"
                >
                    <Stack
                        direction={{ base: "column", lg: "row" }}
                        width="100%"
                        justifyContent="space-between"
                    >
                        <VStack
                            textAlign="left"
                            alignItems="flex-start"
                            justifyContent="flex-start"
                        >
                            <Heading fontSize="14px">Valor Economizado</Heading>
                            <InputGroup variant="flushed" size="sm">
                                <InputLeftAddon
                                    padding="0px 10px"
                                    children="R$"
                                />
                                <Input
                                    value={value}
                                    onChange={(e) => {
                                        let value = e.target.value.replace(
                                            ",",
                                            "."
                                        );
                                        if (isNaN(value)) return;

                                        setValue(value);
                                    }}
                                />
                            </InputGroup>
                        </VStack>
                        <HStack paddingTop={{ base: 5, lg: 0 }}>
                            <Button
                                flex={{ base: "1", lg: null }}
                                isLoading={createMutation.isLoading}
                                onClick={async () => {
                                    onClose();
                                }}
                                variant="ghost"
                            >
                                Cancelar
                            </Button>
                            <Button
                                flex={{ base: "1", lg: null }}
                                isLoading={createMutation.isLoading}
                                onClick={async () => {
                                    await createMutation.mutateAsync();
                                    queryClient.invalidateQueries("usage");
                                    onClose();
                                }}
                                colorScheme="green"
                            >
                                Enviar
                            </Button>
                        </HStack>
                    </Stack>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
};
