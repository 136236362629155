import { Button } from "@chakra-ui/button";
import { HStack } from "@chakra-ui/layout";
import { Modal, ModalCloseButton, ModalHeader, ModalOverlay, ModalBody, ModalFooter, ModalContent } from "@chakra-ui/modal";
import React, { useRef } from "react";
import { useNavigate } from "react-router-dom"
import { useProfile } from "../../context/user";
import { useSettings, useTheme } from "../../context";

export const NoSubscriptionModal = () => {
  const btnRef = useRef();
  const navigate = useNavigate();
  const {showNoSubscriptionModal, setShowNoSubscriptionModal, cpf} = useProfile();
  const {isApp, isIOS} = useSettings();
  const { primaryColor, primaryFontColor, id, hasIntegratedPurchaseOnMobileStore} = useTheme();

  const onSubmit = () => {
    if (isApp && isIOS && hasIntegratedPurchaseOnMobileStore){
      setShowNoSubscriptionModal(false);
      navigate(`/purchase-subscription-mobile/${btoa(cpf+':'+id)}`);
    }else {
      setShowNoSubscriptionModal(false);
      navigate(`/combos`);
    }
  }

  return (
    <Modal
      isCentered
      isOpen={showNoSubscriptionModal}
      onClose={() => setShowNoSubscriptionModal(false)}
      ref={btnRef}
      id="cpf-modal"
    >
      <ModalOverlay
        backgroundColor="rgba(0,0,0,0.8)"
        style={{
          backdropFilter: "blur(5px)",
          "-webkit-backdrop-filter": "blur(5px)"
        }}
      />
      <ModalContent width="90%">
      
        <ModalHeader>Contrate um combo</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          Olá, notamos você ainda não possui um combo ativo. Contrate um combo para continuar.
        </ModalBody>

        <ModalFooter>
          <HStack>
            <Button onClick={onSubmit} width="100%" backgroundColor={primaryColor} color={primaryFontColor}>
              Escolher um combo
            </Button>
          </HStack>
        </ModalFooter>
      </ModalContent> 
    </Modal>
  );
};
