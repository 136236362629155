import { Box, Code, Text, VStack } from "@chakra-ui/react";
import React from "react";

export function AuthenticationCode() {
    return (
        <VStack
            paddingTop="50px"
            textAlign="left"
            alignSelf="center"
            width="90%"
        >
            <Code padding="10px 25px" backgroundColor="#212429" color="white">
                curl
                https://api.partiu.com.br/api/v1/agreement_integration/vinculate/
                \<br /> -H 'Authorization: Token sua chave aqui'
            </Code>
        </VStack>
    );
}
