import React, { useState } from "react";
import { HStack, Text, VStack } from "@chakra-ui/layout";
import { Stack, Flex, TabPanel, TabPanels, Tab, TabList, Tabs, Spacer, Box, Image as ImageDrawer } from "@chakra-ui/react";
import { useSettings, useTheme } from "../../context/index";
import { Title } from "../../components/atoms/Title";
import { Tag } from "../../components/atoms/Tag";
import { Parallax } from "react-parallax";

import { ApproveTermsModal } from "./components/ApproveTermsModal";
import { SetPasswordModal } from "./components/SetPasswordModal";
import { BePartnerForm } from "./components/BePartnerForm";

export const PartnerForm = () => {
    const { primaryColor, secondaryColor } = useTheme();
    const { isApp } = useSettings();

    const [tabIndex, setTabIndex] = useState(0);

    const [isOpenTerms, setIsOpenTerms] = useState(false);
    const [isOpenPassword, setIsOpenPassword] = useState(false);

    const [company, setCompany] = useState({});
    const [store, setStore] = useState({});
    const [discount, setDiscount] = useState({});
    const [user, setUser] = useState({});

    const [exclusive, setExclusive] = useState(false)
    const [coverPhoto, setCoverPhoto] = useState("");
    const [discountImage, setDiscountImage] = useState("");

    const convertStringToHTML = htmlString => {
        const parser = new DOMParser();
        const html = parser.parseFromString(htmlString, 'text/html');

        return html.body;
    }

    const handleTabsChange = (index) => {
        setTabIndex(index)
    };

    return (
        <VStack width="100%" spacing={0}>
            <Stack
                alignItems="flex-start"
                justifyContent="flexstart"
                spacing={{ base: 10, lg: 40 }}
                width="100%"
                padding="60px 5%"
                paddingTop={isApp ? "80px" : "60px"}
                transform={isApp ? "translateY(-50px)" : ""}
                background={
                    "linear-gradient(135deg, " +
                    secondaryColor +
                    " 0%," +
                    primaryColor +
                    " 100%)"
                }
                direction={{ base: "column", lg: "row" }}
            >
                <VStack
                    alignItems="flex-start"
                    spacing={5}
                    textAlign={{ base: "left", lg: "left" }}
                >
                    <VStack
                        marginTop={{
                            base: "-40vh",
                            lg: "-7.8vh",
                        }}
                        w="100%"
                        minWidth={{ base: undefined, lg: "35vw" }}
                        zIndex="1"
                    >
                        {coverPhoto ? (
                            <Parallax
                                bgImage={coverPhoto}
                                style={{
                                    height: "50vh",
                                    backgroundImage: `url(${coverPhoto})`,
                                    backgroundSize: "cover",
                                    backgroundRepeat: "no-repeat",
                                    position: "fixed",
                                    width: "100vw",
                                    left: 0,
                                }}
                                bgImageSize="cover"
                                bgImageStyle={{
                                    height: "51vh",
                                    position: "relative",
                                    width: "100vw",
                                    "object-fit": "contain"
                                }}
                            />
                        ) : (
                            <Box
                                height="50vh"
                                width="100vw"
                                style={{
                                    position: "fixed",
                                    left: 0,
                                    zIndex: 0
                                }}
                                backgroundImage={`linear-gradient(to bottom, rgba(0,0,0, 0), rgba(0,0,0, 0.5)), url(${coverPhoto}), linear-gradient(135deg, ${primaryColor} 0%, ${secondaryColor} 100%)`}
                            />
                        )}
                        <VStack
                            top={32}
                            left={8}
                            w="40vw"
                            border="0px"
                            borderRadius="10px"
                            bgColor="white"
                            boxShadow="0 -5px 0.75rem rgba(0,0,0,0.2)"
                            alignItems="flex-start"
                            paddingBottom="20px"
                            spacing={5}
                            position={`fixed`}
                        >
                            <VStack padding="20px" pb="10px" w="100%">
                                <Flex w="100%" alignItems="flex-start">
                                    <VStack spacing={2} fontSize="15px" alignItems="flex-start">
                                        <Title
                                            color={secondaryColor}
                                            textAlign="left"
                                            fontFamily="Montserrat"
                                            letterSpacing="0.02rem"
                                            fontSize="15pt"
                                            fontWeight="bold"
                                        >
                                            {company.name?.toUpperCase()}
                                        </Title>
                                        <Flex alignItems="flex-start" w="100%" pb="10px">
                                            <Tag tagName={company.category} />
                                            <Tag
                                                tagName={
                                                    discount.mode === "local"
                                                        ? "Parceiro Local"
                                                        : "Parceiro E-Commerce"
                                                }
                                            />
                                        </Flex>

                                        <Text fontSize="14px" textAlign="left">
                                            {discount.subtitle}
                                        </Text>
                                    </VStack>
                                    <Spacer />
                                </Flex>
                            </VStack>
                            <Tabs index={tabIndex} onChange={handleTabsChange} w="100%">
                                <TabList>
                                    <Tab
                                        fontSize="16px"
                                        fontWeight="500"
                                        fontFamily="Open Sans"
                                        letterSpacing="0.06rem"
                                        _selected={{
                                            fontWeight: "600",
                                            color: primaryColor,
                                            borderBottom: `2px solid ${primaryColor}`,
                                        }}
                                    >
                                        Vantagens
                                    </Tab>
                                    <Tab
                                        fontSize="16px"
                                        fontWeight="400"
                                        fontFamily="Open Sans"
                                        letterSpacing="0.06rem"
                                        _selected={{
                                            fontWeight: "600",
                                            color: primaryColor,
                                            borderBottom: `2px solid ${primaryColor}`,
                                        }}
                                    >
                                        Sobre
                                    </Tab>
                                    <Tab
                                        fontSize="16px"
                                        fontWeight="400"
                                        fontFamily="Open Sans"
                                        letterSpacing="0.06rem"
                                        _selected={{
                                            fontWeight: "600",
                                            color: primaryColor,
                                            borderBottom: `2px solid ${primaryColor}`,
                                        }}
                                        isSelected
                                    >
                                        Regulamento
                                    </Tab>
                                </TabList>

                                <TabPanels>
                                    <TabPanel>
                                        <VStack paddingBottom="10px" alignItems="flex-start" spacing={0}>
                                            <Text textAlign="left" color="#777" fontSize="12px">
                                                Aperte no desconto para mais informações
                                            </Text>
                                        </VStack>
                                        <Stack alignItems="flex-start" w="100%" direction={{ base: "column", lg: "column" }} spacing={5}>
                                            <VStack
                                                borderRadius="md"
                                                p="10px"
                                                border="1.5px dashed #E5E5E5"
                                                w="100%"
                                            >
                                                <HStack width="100%">
                                                    {discountImage !== "" ? (
                                                        <ImageDrawer
                                                            marginRight="20px"
                                                            width={{ base: "150px", lg: "200px" }}
                                                            height={{ base: "100px", lg: "150px" }}
                                                            borderRadius="10px"
                                                            objectFit="cover"
                                                            src={discountImage}
                                                        />
                                                    ) : (
                                                        <Box
                                                            marginRight="20px"
                                                            backgroundColor={"#AAA"}
                                                            width={{ base: "150px", lg: "200px" }}
                                                            height={{ base: "100px", lg: "150px" }}
                                                            borderRadius="10px"
                                                            objectFit="cover" />
                                                    )}
                                                    <VStack
                                                        bgColor="white"
                                                        transition="0.2s"
                                                        cursor="pointer"
                                                        alignItems="flex-start"
                                                        width="100%"
                                                        spacing={0}
                                                    >
                                                        <HStack alignItems="center" spacing={3}>
                                                            <i
                                                                className="fas fa-tag"
                                                                style={{ color: primaryColor }}
                                                            />
                                                            <Title
                                                                fontSize="8pt"
                                                                textAlign="left"
                                                                color={primaryColor}
                                                            >
                                                                {discount.discount || "10"}% de desconto
                                                            </Title>
                                                        </HStack>
                                                        <Text textAlign="left" pt="5px" fontSize="12px">
                                                            <b>{discount.name ? discount.name : " "}</b>
                                                            <Text>Descrição da promoção</Text>
                                                        </Text>
                                                    </VStack>
                                                </HStack>
                                            </VStack>
                                        </Stack>
                                    </TabPanel>
                                    <TabPanel>
                                        <VStack
                                            paddingTop="10px"
                                            textAlign="left"
                                            alignItems="flex-start"
                                            w="100%"
                                        >
                                            <Text style={{ lineBreak: 'anywhere' }}>{company.description}</Text>
                                            {company.site !== "" ? (
                                                <HStack wordBreak="break-all">
                                                    <i className="fas fa-at" />
                                                    <Text>{company.site}</Text>
                                                </HStack>
                                            ) : (
                                                <></>
                                            )}
                                        </VStack>
                                    </TabPanel>
                                    <TabPanel>
                                        <VStack overflowY="scroll" textAlign="left" padding="5px" alignItems="flex-start" spacing={2}>
                                            <Title paddingTop="20px" fontSize="18px">
                                                {company.corporate_name}
                                            </Title>
                                            {discount.discount}% DE DESCONTO
                                            <Title paddingTop="20px" fontSize="14px">
                                                REGULAMENTO
                                            </Title>
                                            {discount.rules && <div dangerouslySetInnerHTML={{ __html: discount.rules }} />}
                                        </VStack>
                                    </TabPanel>
                                </TabPanels>
                            </Tabs>
                        </VStack>
                    </VStack>
                </VStack>
                <BePartnerForm
                    setTabIndex={setTabIndex}
                    company={company}
                    store={store}
                    discount={discount}
                    user={user}
                    setCompany={setCompany}
                    setStore={setStore}
                    setDiscount={setDiscount}
                    setUser={setUser}
                    setIsOpen={setIsOpenTerms}
                    setCoverPhoto={setCoverPhoto}
                    setDiscountImage={setDiscountImage}
                    setExclusive={setExclusive}
                />
                <ApproveTermsModal isOpen={isOpenTerms} onClose={() => { setIsOpenTerms(false) }}
                    onAccept={() => { setIsOpenTerms(false); setIsOpenPassword(true) }}
                    company={company}
                    store={store}
                    discount={discount}
                />
                <SetPasswordModal
                    isOpen={isOpenPassword}
                    onClose={setIsOpenPassword}
                    company={company}
                    store={store}
                    discount={discount}
                    user={user}
                    exclusive={exclusive}
                />
            </Stack>
        </VStack>
    );
};
