import { Flex, HStack, VStack } from "@chakra-ui/react";
import React from "react";
import { Link } from "react-router-dom";
import { useProfile } from "../../context/user";
import { Title } from "../atoms/Title";
import { UserImage } from "../atoms/UserImage";
import { EconometerBar } from "../molecules/EconometerBar";

export function UserEconometer({
                                   hideImage = false,
                                   changeImage = false,
                                   forceShow = false,
                                   showFootNote = true,
                                   titleSize = "14px",
                                   barHeight = "27px",
                                   spacing = 2,
                                   ...props
                               }) {
    const { logged } = useProfile();

    if (!logged) return <></>;
    return (
        <HStack className="EconometerBar" paddingTop="0px" width="100%" {...props}>
            <VStack spacing={spacing} align="flex-start" width="100%">
                <Link
                    style={{
                        width: "100%",
                        display: "flex",
                        flexDirection: "column"
                    }}
                    to={{ pathname: "/econometro" }}
                >
                    <VStack spacing={spacing} align="flex-start" width="100%">
                        <Title fontSize={titleSize}>Econômetro Mensal</Title>
                        <Flex width="80%">
                            <EconometerBar
                                forceShow={forceShow}
                                showFootNote={showFootNote}
                                barHeight={barHeight}
                            />
                        </Flex>
                    </VStack>
                </Link>
            </VStack>
            {hideImage ? <></> : <UserImage changeImage={changeImage} />}
        </HStack>
    );
}
