import { Button, Flex, Heading, Text, VStack } from "@chakra-ui/react";
import React from "react";
import { Link } from "react-router-dom";

export const NoDiscountFound = ({
    title = "Puxa vida!",
    subtext = "Parece que não encontramos nenhum desconto com esses filtros!",
    text = "Quer indicar algum estabelecimento? Acesse nossa página Seja Parceiro, usando o botão abaixo.",
    showButton = true
}) => (
    <VStack flex="1" direction="column" align="center" justify="center">
        <i flex="1" style={{ fontSize: 60 }} className="fas fa-frown-open" />
        <Heading flex="1">{title}</Heading>
        <Text textAlign="justify" flex="1">
            {subtext} {text}
        </Text>

        {showButton ? (
            <Link to="/cadastrar-loja" >
                <Button
                    // onClick={() => window.location.reload()}
                    padding="20px"
                    marginTop="40px"
                    flex="1"
                    colorScheme="green"
                >
                    Seja Parceiro!
                </Button>
            </Link>
        ) : (
            <></>
        )}
    </VStack>
);
