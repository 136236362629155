import { Input } from "@chakra-ui/input";
import { Flex, Heading } from "@chakra-ui/layout";
import React, { useEffect, useState } from "react";
import ReactInputMask from "react-input-mask";

export const FormInput = ({
    label,
    value,
    onChange = () => {},
    onClick = () => {},
    mask = null,
    disabled = false,
    type = "text",
    containerProps = {},
    hookForm = {},
    placeholder = "",
    required = false,
    children,
    maxLength = 250
}) => {
    const [_, forceUpdate] = useState(0);

    useEffect(() => {
        forceUpdate(_ + 1);
    }, [value]);

    return (
        <Flex {...containerProps} flexDirection="column" onClick={onClick}>
            <Heading textAlign="left" marginBottom="10px" fontSize="14px">
                {label.toUpperCase()}
            </Heading>
            {mask ? (
                <ReactInputMask
                    type={type}
                    disabled={disabled}
                    onChange={(e) => onChange(e.target.value)}
                    mask={mask}
                    value={value}
                    required={required}
                    placeholder={placeholder}
                    style={{
                        fontSize: "1rem",
                        paddingLeft: "1rem",
                        paddingRight: "1rem",
                        height: "2.5rem",
                        borderRadius: "0.375rem",
                        border: "1px solid",
                        borderColor: "inherit",
                        width: "100%"
                    }}
                    {...hookForm}
                />
            ) : (
                <Input
                    width="100%"
                    disabled={disabled}
                    placeholder={placeholder}
                    type={type}
                    required={required}
                    value={value}
                    maxLength={maxLength}
                    onChange={(e) => onChange(e.target.value)}
                    {...hookForm}
                />
            )}
            {children}
        </Flex>
    );
};
