import { Card } from "../../../components/atoms/Card";
import { Heading } from "@chakra-ui/layout";
import { Divider, Flex, Text } from "@chakra-ui/react";
import { useTheme } from "../../../context";
import { Button } from "../../../components/atoms/Button";
import { Link } from "react-router-dom";

export function LoginNeededCard() {
    const { name, bookstoreType } = useTheme();

    return (
        <Card>
            <Flex width={"100%"} height={"100%"} direction={"column"}>
                <Heading paddingBottom={"20px"} fontSize="20px">Procurando um incentivo para ler mais?</Heading>
                <Text>
                    {`Compre recorrente e tenha acesso a ${bookstoreType === "book" ? "diversos Livros" : "diversas Revistas"} em categorias como: ficção, romance, drama, ação,
                    aventura e muito mais.
                    E o melhor: todo mês ${bookstoreType === "book" ? "novos Livros" : "novas Revistas"} para você.
                    Escolha o seu combo e comece agora.`}
                </Text>
                <Divider paddingTop={"15px"} marginTop={"auto"} />
                <Flex height={"20px"} />
                <Link style={{ textAlign: "end" }} to={"/login"}>
                    <Button>
                        Cadastre-se agora
                    </Button>
                </Link>
            </Flex>
        </Card>
    );
}