import { Button, HStack, VStack } from "@chakra-ui/react";
import {
  faBars,
  faBook,
  faBookOpen,
  faChartPie,
  faHome,
  faRightToBracket,
  faSearch,
  faTicketAlt
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useSettings, useTheme } from "../../context/index";
import { useProfile } from "../../context/user";
import { Title } from "../atoms/Title";

function MenuIcon({ icon, text, to = "", onClick = () => {} }) {
    const location = useLocation();
    const { primaryColor } = useTheme();
    const isActive = location.pathname === to;
    const navigate = useNavigate();

    const handleClick = () => {
      if (to.length) navigate(to)
      onClick();
    }

    return (
        <Button 
          {...(!isActive ? {_hover: {}} : {boxShadow: 'none'})}
          onClick={handleClick}
          minHeight="56px"
          minWidth="48px"
          display={"block"}
          backgroundColor={"transparent"}
          textTransform={"capitalize"}
          flex={1} 
          padding={"1px"} 
        >
            <VStack justify="baseline" align="center" maxWidth={"100%"}>
                <FontAwesomeIcon
                    size="sm"
                    style={{ transition: "0.1s", height: "15px !important" }}
                    color={isActive ? primaryColor : "#495057"}
                    icon={icon}
                />
                <Title
                    letterSpacing="0rem"
                    fontWeight={isActive ? "800" : "600"}
                    transition="0.1s"
                    color={isActive ? primaryColor : "#495057"}
                    fontSize="8pt"
                    breakWord="break-word"
                    maxWidth="100%"
                >
                    {text}
                </Title>
            </VStack>
        </Button>
    );
}

export function BottomMenu() {
    const { isApp, isIOS } = useSettings();
    const { logged } = useProfile();
    const {
        integratedLogin,
        bookstoreModule,
        localModule,
        ecommerceModule,
        econometerModule,
        bookstoreType,
        digitalMagazinesModule
    } = useTheme();

    if (!isApp || (bookstoreModule && !logged)) return <></>;

    return (
        <HStack
            backgroundColor="#FAFAFA"
            borderTop="1px solid #D3D3D3"
            padding="8px"
            justify="space-between"
            alignItems="center"
            align="baseline"
            position="fixed"
            bottom="0px"
            zIndex="999"
            width="100%"
            minHeight={isIOS ? "85px" : "65px"}
        >
            <MenuIcon icon={faHome} text="Home" to="/" />
            {logged && (localModule || ecommerceModule) && (
                <MenuIcon
                    icon={faTicketAlt}
                    text="Compras"
                    to="/minhas-compras"
                />
            )}
            {localModule || ecommerceModule ? (
                <MenuIcon icon={faSearch} text="Vantagens" to="/busca" />
            ) : (
                <></>
            )}
            {bookstoreModule ? (
                <MenuIcon
                    icon={faBook}
                    text={
                        bookstoreType === "book" ? "Biblioteca" : "Banca"
                    }
                    to={
                        bookstoreType === "book" ? "/biblioteca" : "/banca"
                    }
                />
            ) : (
                <></>
            )}

            {bookstoreModule && bookstoreType === "book" && (
                <MenuIcon
                    icon={faBookOpen}
                    text={<>Livros <br/>Recebidos</>}
                    to="/livros-recebidos"
                />
            )}
            {bookstoreModule && bookstoreType === "magazine" && (
                <MenuIcon
                    icon={faBookOpen}
                    text={<>Revistas <br/> Recebidas</>}
                    to="/revistas-recebidas"
                />
            )}

          {bookstoreModule && bookstoreType === "magazine" && digitalMagazinesModule
 && (
            <MenuIcon
              icon={faBook}
              text={<>Revistas <br/> digitais</>}
              to="/revistas-digitais"
            />
          )}

          {econometerModule ? (
                <MenuIcon
                    icon={faChartPie}
                    text="Econômetro"
                    to="/econometro"
                />
            ) : (
                <></>
            )}
            {logged ? (
                <MenuIcon icon={faBars} text="Menu" to="/perfil" />
            ) : (
                <MenuIcon
                    icon={faRightToBracket}
                    text="Login"
                    to={integratedLogin ? "/login/parceiro" : "/login"}
                />
            )}
        </HStack>
    );
}
