import React from "react";
import { useTheme } from "../../../context/index";
import { VStack, Text } from "@chakra-ui/layout";
import { Flex } from "@chakra-ui/react";
import { Tag } from "../../../components/atoms/Tag";
import { Title } from "../../../components/atoms/Title";

export const PromoInfo = ({ show_name = "", subtitle, mode, category }) => {
    const { secondaryColor, agreementID, name: agreementName } = useTheme();
    return (
        <VStack spacing={2} fontSize="15px" alignItems="flex-start">
            <Title
                color={secondaryColor}
                textAlign="left"
                fontFamily="Montserrat"
                letterSpacing="0.02rem"
                fontSize="15pt"
                fontWeight="bold"
            >
                {show_name.toUpperCase()}
            </Title>
            <Flex alignItems="flex-start" w="100%" pb="10px">
                {category && <Tag tagName={category} />}
                <Tag
                    tagName={
                        mode === "local"
                            ? "Parceiro Local"
                            : "Parceiro E-Commerce"
                    }
                />
            </Flex>

            <Text fontSize="14px" textAlign="left">
                {subtitle}
            </Text>
        </VStack>
    );
};
