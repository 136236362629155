import { Box, Button, Flex, Heading, Link, ScaleFade, Spinner, Text, VStack } from "@chakra-ui/react";
import { faCheckCircle, faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useMemo } from "react";
import { useQuery } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import { getSaleStatus } from "../../api/sales";
import { useSettings, useTheme } from "../../context";
import { TopMenu } from "../../components/organisms/TopMenu";

export const BuyStatus = () => {   

    const { primaryColor, primaryFontColor, logo } = useTheme();
    const { isMobile } = useSettings();
    const navigate = useNavigate();

    const { id  } = useParams();

    const { isLoading, isError, data, refetch } = useQuery('buyStatus', () => getSaleStatus(id), {
        enabled: !!id,
        refetchInterval: 60000,
        retry: 1
    });


    const isSuccess = useMemo(() => {
        if (!data) return false
        return data.payment_status === "paid";
    },[data]);

    if (isError) return <div>Error fetching buy status</div>;

    return (
            <VStack
                alignItems="center"
                flexDirection="column"
                justifyContent="center"
                padding="16px"
                boxSizing="border-box"
                paddingBottom={"64px"}
                width="100%"
                height={"100vh"}
            >
                <TopMenu />
                <Box marginBottom={"20px"}>
                    <img width={isMobile ? 150 : 300} height={isMobile ? 150 : 300} src={logo} alt="Logo"></img>
                </Box>
                <Flex
                    width="100%"
                    alignItems="center"
                    justifyContent="center"
                    direction="column"
                    padding={"16px"}
                    flex={1}
                >
                    {
                        isLoading || (data && data.payment_status === "created") ? (
                            <>
                                <Heading
                                    size='lg'
                                    marginBottom="24px"
                                    maxWidth="500px !important"
                                    color="black"
                                    textAlign="center"
                                    mt={5}
                                >
                                    Aguardando confirmação do pagamento!
                                </Heading>
                                <Text textAlign="center">
                                    Se você já tiver realizado o pagamento, aguarde um instante, pois estamos validando a transação.
                                </Text>
                                {
                                    data && (
                                        <Link marginY={"16px"} href={data.vindi_bill_url} target="_blank" backgroundColor={"gray.100"} borderRadius={"10px"} padding={"9px"}>
                                            Acessar link da compra
                                        </Link>
                                    )
                                }
                                <Spinner size="xl" />
                            </>
                        ) : (
                            <>
                                <Box marginTop={"24px"}>
                                    <ScaleFade initialScale={0.5} in={true}>
                                        {isSuccess
                                            ?   <FontAwesomeIcon
                                                    color="#009688"
                                                    opacity="1"
                                                    fontSize="48px"
                                                    icon={faCheckCircle}
                                                />
                                            :   <FontAwesomeIcon
                                                    color="#F44336"
                                                    opacity="1"
                                                    fontSize="48px"
                                                    icon={faTimesCircle}
                                                />
                                        }
                                    </ScaleFade>
                                </Box>
                                <Heading
                                    marginBottom="24px"
                                    maxWidth="500px !important"
                                    fontWeight="500"
                                    fontFamily="Open Sans !important"
                                    fontSize="32px !important"
                                    lineHeight="1.5em"
                                    color={isSuccess ? 'teal.500' : 'red.500'}
                                    textAlign="center"
                                    mt={5}
                                >
                                    {isSuccess ? 'Compra realizada com sucesso!' : 'Erro na compra'}
                                </Heading>
                                <Text color={isSuccess ? 'teal.500' : 'red.500'} textAlign={"center"}>
                                    {isSuccess
                                        ? `Sua compra #${data ? data.id : ''} foi realizada com sucesso.`
                                        : 'Houve um erro ao processar sua compra. Por favor, tente novamente mais tarde.'}
                                </Text>
                                

                                <Button color={primaryFontColor} backgroundColor={primaryColor} marginTop={"36px"} onClick={() => navigate("/")}>
                                    Voltar
                                </Button>
                            </>
                        )
                    }
                </Flex>
            </VStack>
    );
};
