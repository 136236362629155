import React, { useEffect, useState } from "react";

import { Box, Button, Divider, Flex, Heading, HStack, Image, Spinner, Stack, Text, useToast, VStack } from "@chakra-ui/react";
import { useQuery } from "react-query";
import { getPlans } from "../../api/plans";
import { Card } from "../../components/atoms/Card";
import { useSettings, useTheme } from "../../context";
import { Hero } from "../AboutTheClub/components/Hero";
import { TopMenu } from "../../components/organisms/TopMenu";
import { PlanSelector } from "./components/PlanSelector";
import { PlanDetail } from "./components/PlanDetail";
import { PlanForm } from "./components/PlanForm";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import { useProfile } from "../../context/user";
import { LoginNeededCard } from "./components/LoginNeededCard";
import { AddAgreementButton } from "../Profile/components/AddAgreement";

export default function PlansPage() {
    const toast = useToast();
    const { logged, cpf } = useProfile();
    const { state } = useLocation();
    const { id, name, bookstoreModule, bookstoreType, integratedLogin, hasIntegratedPurchaseOnMobileStore, primaryColor, logo} = useTheme();
    const { isLoading, data } = useQuery("plans", () => getPlans(id), {
        enabled: !!id
    });
    const [selectedPlan, setSelectedPlan] = useState(0);
    const navigate = useNavigate();

    const { isApp, isIOS } = useSettings();

    const getHeroMessage = () => {
        if (data && data[selectedPlan].type === "company") return `Tenha seu próprio Clube de vantagens com descontos exclusivos para membros ${name}`
        
        if (bookstoreModule) return `Procurando um incentivo para ler mais? Compre recorrente agora.`
        
        return 'Assine agora o clube de vantagens'
    }

    useEffect(() => {
        if (state?.showToast) toast({
            status: "error",
            title: "Compre recorrente Já!",
            description: `Observamos que você ainda não é um assinante. Para realizar o download ${bookstoreType === "book" ? "dos Livros" : "das Revistas"} você precisa ser um assinante ativo.`
        });
    }, [state]);

    useEffect(() => {
        if (isApp && isIOS && hasIntegratedPurchaseOnMobileStore){
            navigate(`/purchase-subscription-mobile/${btoa(cpf+':'+id)}`);
          }
    },[cpf, hasIntegratedPurchaseOnMobileStore, id, isApp, isIOS, navigate])

 

    if (isLoading) return (
        <VStack paddingY="100px" paddingX={"16px"}>
            <TopMenu />
            <Text marginBottom={"24px"}>
                Aguarde um instante estamos carregando os combos
            </Text>
            <Spinner size="xl" />
        </VStack>

    );
    if (!isLoading && (!data || data.length == 0)) return (
        <VStack paddingY="100px">
            <TopMenu />
            <Text marginBottom={"24px"}>
                Não há combos cadastrados para esse convenio
            </Text>
            <Text marginBottom={"24px"}>
                Entre em contato com o nosso suporte
            </Text>
        </VStack>
    );

    if (!logged) return <Navigate to={{ pathname: integratedLogin ? "/login/parceiro" : "/login",  search: 'redirectUrl=/combos'}} />;

    return (
        <VStack paddingBottom="70px">
            <TopMenu />
            <Box paddingTop={{ base: "80px", lg: "0px" }}></Box>
            {/* <Hero hideDiscounts={true} height={"100px"} hideButton={true}
                forceText={isLoading ? null : (getHeroMessage())} /> */}
            <Box 
              // backgroundColor={primaryColor} 
              padding={"24px 16px"} 
              display={"flex"} 
              flexDirection={"column"} 
              justifyContent={"center"}
              alignItems={"center"}  
              width={"100%"}
            >
              <Image boxSize="100px" objectFit="contain" src={logo} />
              <Text fontSize={"2xl"} textAlign={"center"}>
                  {getHeroMessage()}
              </Text>
            </Box>
            <Stack
                spacing={10}
                width="80%"
                margin="auto"
                direction={{ base: "column", lg: "row" }}
                marginBottom="50px"
            >
                {/* <Card>
                    <Flex width={"100%"} height={"100%"} direction={"column"}>
                        <Heading paddingBottom={"20px"} fontSize="20px">Possui codigo promocional ou já é cliente?</Heading>
                        <Text>
                            Insira seu CPF ou código promocional abaixo e ganhe uma assinatura gratis.
                        </Text>
                        <Divider paddingTop={"15px"} marginTop={"auto"} />
                        <Flex height={"20px"} />
                        <AddAgreementButton>
                            <Button>
                                Verificar agora
                            </Button>
                        </AddAgreementButton>
                    </Flex>
                </Card> */}
                <Card
                    padding="30px 20px"
                    maxWidth={{ base: "100%", lg: "320px" }}
                >
                    <PlanSelector
                        plans={data}
                        selectedPlan={selectedPlan}
                        setSelectedPlan={setSelectedPlan}
                    />
                    <HStack width="100%" justify="center" textAlign="center">
                        <Heading paddingTop="10px" alignSelf="flex-start" fontSize="14pt">
                            R$
                        </Heading>
                        <Heading fontSize="40pt">
                            {!isLoading ? data[selectedPlan].value.split(".")[0] : null}
                        </Heading>
                        <Heading paddingTop={"10px"} fontSize="20pt" alignSelf={"flex-start"}>
                            ,{!isLoading ? data[selectedPlan].value.split(".")[1] : null}
                        </Heading>
                        <Heading alignSelf="flex-end" fontSize="8pt">
                            Mensais
                        </Heading>
                    </HStack>
                    <Divider paddingTop="30px" />
                    <PlanDetail paddingTop={"30px"} plan={data[selectedPlan] || null} />
                </Card>
                
                {logged ? <PlanForm plan={data[selectedPlan] || null} /> : <LoginNeededCard />}
            </Stack>
        </VStack>
    );
}
