import { Code, Text, VStack } from "@chakra-ui/react";
import React from "react";

export function IFrameCode() {
    return (
        <VStack
            paddingTop="50px"
            textAlign="left"
            alignSelf="center"
            width="90%"
        >
            <Text alignSelf="flex-start">Exemplo de IFrame</Text>
            <Code padding="10px 25px" backgroundColor="#212429" color="white">
                {` <iframe
            frameborder="0"
            allow="geolocation *;"
            allowtransparency
            allowfullscreen
            width="500px"
            height="500px"
            src="link gerado pela API"
        ></iframe>`}
            </Code>
        </VStack>
    );
}
