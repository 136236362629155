import { useTheme } from "../../context";
import { useProfile } from "../../context/user";
import { Heading } from "@chakra-ui/layout";
import { Button, Text, useToast, VStack } from "@chakra-ui/react";
import React, { useEffect, useMemo, useState } from "react";
import { Input } from "../atoms/Input";
import { useMutation } from "react-query";
import { updateSubscription } from "../../api/subscription";

export function AccountDependants({isRegistering=false, onClose=()=>{}, forceSubscriptions=null}){
    const toast = useToast();
    const {id, primaryColor, primaryFontColor} = useTheme();
    const {subscriptions, cpf} = useProfile();
    const [extraCPFs, setExtraCPFs] = useState([])
    const subscription = useMemo(() => (forceSubscriptions || subscriptions || []).filter((s) => s.plan.agreement.id === id)[0], [subscriptions])
    const updateSubscriptionMutation = useMutation(() => updateSubscription(subscription.id, {"extra_cpfs":extraCPFs.filter(cpf => cpf)}), {
        onSuccess: function(){
            toast({title:"Sucesso", description:"Os CPFs foram vinculados com sucesso. Caso não tenham conta, cadastre-se usando a opção CPF Vinculado. Usuários já cadastrados poderão fazer login na plataforma usando a conta com o CPF cadastrado.", status:"success"})
            onClose();
        },
        onError: function(){
            toast({title:"Oops!", description:"Um dos CPFs digitados não é válido!", status:"error"})
        }
    })

    useEffect(() => {
        if(!subscription) return;

        const newArr = new Array(subscription.plan.access_amount - 1).fill("");
        subscription.extra_cpfs.forEach((cpf, index) => newArr[index] = cpf)
        setExtraCPFs(newArr)
    }, [subscription]);

    function updateCPFState(index, value){
        const newArr = [...extraCPFs];
        newArr[index] = value
        setExtraCPFs(newArr)
    }

    function getDependantText(){
        if(subscription?.plan?.access_amount === 1) return `Seu combo não permite dependentes.`
        return `Olha que notícia boa! Identificamos que seu combo ${subscription.plan.name} permite que você cadastre mais ${subscription?.plan?.access_amount - 1} CPF(s) para ter acesso gratuito a plataforma. Não perca tempo, cadastre agora.`
    }

    return(
        <VStack spacing={5} align={"flex-start"}>
            <Heading color="#495057" fontSize="18px">Seus Dependentes</Heading>
            <Text color="#495057"
                  fontSize="15px"
                  opacity="0.7"
                  width="100%" textAlign="justify">{getDependantText()}</Text>
            <VStack width={"100%"} spacing={2}>
                {extraCPFs.length > 0 && new Array(extraCPFs.length).fill(0).map((_,i) => <Input width={"100%"} mask="999.999.999-99" key={i} value={extraCPFs[i]} onChange={(e) => updateCPFState(i, e)} placeholder={`Dependente ${i + 1}`}/>)}
            </VStack>
            <Button
                backgroundColor={primaryColor}
                color={primaryFontColor}
                width="100%"
                disabled={extraCPFs.length === 0 || extraCPFs.filter(cpf => cpf).length === 0}
                isLoading={updateSubscriptionMutation.isLoading}
                onClick={updateSubscriptionMutation.mutate}
            >
                Atualizar
            </Button>
            {isRegistering && <Button
                backgroundColor={primaryColor}
                color={primaryFontColor}
                width="100%"
                onClick={onClose}
            >
                Pular
            </Button>}
        </VStack>
        )
}