import { Divider, HStack, Heading, Select, Text, VStack } from '@chakra-ui/react'
import React from 'react'
import { numberToBRL } from '../../../utils/money'

export function QuantityChooser({ discount, onChangeQuantity, quantity }) {
    return (
        <VStack paddingBottom="10px" spacing={3} align="flex-start" width="100%">
            <Divider />
            <Text fontSize={16}>Valor Unitário: {numberToBRL(discount.buy_price)}</Text>
            <HStack>
                <Text fontSize={16}>Quantidade</Text>
                <Select defaultValue={1} size="sm" fontSize={16} onChange={(e) => onChangeQuantity(e.target.value)}>
                    {new Array(discount.monthly_max_usage_per_user || 4).fill(0).map((_, index) => <option value={index + 1}>{index + 1} ({numberToBRL((index + 1) * discount.buy_price)})</option>)}
                </Select>
            </HStack>
            <Heading fontSize={18}>Valor Total: {numberToBRL(discount.buy_price * quantity)}</Heading>
            <Divider />
        </VStack>
    )
}