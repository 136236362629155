import {
    Button,
    Menu,
    MenuButton,
    MenuItem,
    MenuList,
    Tag
} from "@chakra-ui/react";
import React, { useContext } from "react";
import { useTheme } from "../../context/index";

export function FilterTag({
    children,
    onClick = () => {},
    icon = "fas fa-times",
    isActive = false
}) {
    const { secondaryColor, secondaryFontColor } = useTheme();

    return (
        <Tag
            onClick={onClick}
            border={isActive ? 0 : `1px solid rgba(0,0,0,0.1)`}
            backgroundColor={isActive ? secondaryColor : "white"}
            borderRadius="3rem"
            color={isActive ? secondaryFontColor : "#495057 !important"}
            fontWeight="600"
            fontSize="8pt"
            minWidth="fit-content"
            cursor="pointer"
            whiteSpace="nowrap"
            padding="7px 11px"
            display="flex"
        >
            <i
                style={{
                    marginRight: 10,
                    color: isActive
                        ? `${secondaryFontColor} !important`
                        : "#495057 !important"
                }}
                className={isActive ? "fas fa-times" : icon}
            ></i>
            {children}
        </Tag>
    );
}
