import { Code, VStack } from "@chakra-ui/react";
import React from "react";

export function UserWebhook() {
    return (
        <VStack
            paddingTop="50px"
            textAlign="left"
            alignSelf="center"
            width="90%"
        >
            <Code padding="10px 25px" backgroundColor="#212429" color="white">
                curl
                https://api.partiu.com.br/api/v2/agreement_integration/webhooks/users/
                \<br /> -H 'Authorization: Token sua chave aqui'
                \<br /> -d {"{'url': 'https://example.com/user', 'bearer_token': 'ANY_TOKEN', 'expected_response_code': 200}"}
            </Code>
        </VStack>
    );
}
