import {
    ModalBody,
    ModalContent,
    ModalHeader,
    ModalOverlay,
    Text,
    Modal,
    VStack,
    Checkbox,
    HStack,
    ModalFooter,
    Button,
  } from "@chakra-ui/react";
  import React, { useState } from "react";
  import Terms from "../../../utils/Terms";
  
  export function ApproveTermsModal({ isOpen, onClose, onAccept, company, store, discount }) {
    const [approved, setApproved] = useState(false);

    return (
      <Modal
        closeOnOverlayClick={false}
        closeOnEsc={false}
        isOpen={isOpen}
        size="3xl"
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Contrato Partiu - {company.name}</ModalHeader>
          <ModalBody>
            <VStack height="50vh" overflow="scroll" width="100%" margin="auto">
              <Terms company={company}
                    store={store}
                    discount={discount}/>
            </VStack>
            <HStack
              onClick={() => setApproved(!approved)}
              cursor="pointer"
              marginTop="40px"
            >
              <Checkbox
                isChecked={approved}
                onChange={(val) => setApproved(val)}
              />
              <Text>
                Estou de acordo com o contrato e desejo disponibilizar minha loja
                no Clube Partiu.
              </Text>
            </HStack>
          </ModalBody>
          <ModalFooter justifyContent="space-between">
            <Button
              onClick={onClose}
              variant="ghost"
              colorScheme="red"
            >
              Mudar Dados
            </Button>
            <Button
              onClick={onAccept}
              colorScheme="green"
              disabled={!approved}
            >
              Assinar Digitalmente
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    );
  }