import React from "react";
import Steps from "../../../components/molecules/Steps";
import Features from "../../../components/molecules/Features";
import { VStack } from "@chakra-ui/react";
import {
    faCartPlus,
    faMobile,
    faUsers
} from "@fortawesome/free-solid-svg-icons";

const Introduction = (props) => {
    const steps = [
        {
            title: "Cadastre seu estabelecimento!",
            description: "Cadastre seu estabelecimento na Partiu!",
            icon: faMobile
        },

        {
            title: "Crie suas ofertas exclusivas",
            description: "Ofertas com a cara do seu estabelecimento.",
            icon: faCartPlus
        },

        {
            title: "Aumente sua base de clientes",
            description:
                "Se preocupe apenas com o seu estabelecimento, porque, clube de vantagens e marketing são os nossos negócios.",
            icon: faUsers
        }
    ];

    const features = [
        "Personalização de ofertas e dados do estabelecimento.",
        "Sistema de acompanhamento de pedidos e descontos integrado ao app.",
        "Conheça melhor quem são seus clientes e use as ferramentas de marketing Partiu! para aumentar ainda mais a sua base"
    ];

    return (
        <VStack paddingBottom="50px" width="100%">
            <Steps title="Como Funciona?" steps={steps} />

            <VStack width="100%" backgroundColor="#f6f5fa">
                <Features title="Recursos Para Sua Marca" features={features} />
            </VStack>
        </VStack>
    );
};

export default Introduction;
