import {
  Box,
  Button,
  Divider,
  Flex,
  Image,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Spacer,
  Spinner,
  VStack
} from "@chakra-ui/react";
import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBookReader } from "@fortawesome/free-solid-svg-icons";
import { useMutation, useQuery } from "react-query";
import { userHasPermissionToBook } from "../../../api/books";
import { useProfile } from "../../../context/user";
import { useNavigate } from "react-router-dom";
import { useTheme } from "../../../context";

export function MagazineModal({
                                id,
                                article_title,
                                file_url,
                                cover,
                                article_content,
                                isOpen,
                                onClose
                              }) {
  const { logged } = useProfile();
  const navigate = useNavigate();
  const { integratedLogin } = useTheme();
  const onReadMagazineMutation = useMutation(() => {
  }, {
    async onSuccess() {
      const link = document.createElement("a");
      link.href = file_url;
      link.setAttribute("download", true);
      link.setAttribute("target", "_blank");
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
    onError() {
      navigate({ pathname: "/combos" });
    }
  });

  function onReadMagazine() {
    if (!logged)
      return navigate(
        { pathname: integratedLogin ? "/login/parceiro" : "/login" },
        { state: { showToast: true } }
      );

    onReadMagazineMutation.mutate();
  }

  if (!article_title) return <></>;

  return (
    <Modal size={{ base: "md", lg: "sm" }} isCentered isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent width={{ base: "90%", lg: "600px" }}>
        <ModalBody padding="0px">
          <Flex flexDir={{ base: "column", lg: "row" }} height="100%" align="stretch">
            <Flex
              flex="1"
              backgroundColor="#ddd !important"
              borderTopLeftRadius="6px"
              borderBottomLeftRadius={{ base: "0px", lg: "6px" }}
              borderTopRightRadius={{ base: "6px", lg: "0px" }}>
              <Image
                objectFit="cover"
                objectPosition="top"
                height={{ base: "250px", lg: "100%" }}
                flex="1"
                borderTopLeftRadius="6px"
                borderBottomLeftRadius={{ base: "0px", lg: "6px" }}
                borderTopRightRadius={{ base: "6px", lg: "0px" }}
                borderRight="1px solid #ddd"
                src={cover} />
            </Flex>

            <VStack flex="1" gap={0} padding="20px 10px" paddingRight="15px" align="flex-start"
                    justify="flex-start">
              <article_title maxWidth={{ base: "100%", lg: "270px" }} fontWeight="bold"
                     fontSize="14pt">{article_title.toUpperCase()}</article_title>
              <ModalCloseButton height={"48px"} width={"48px"} backgroundColor={"HighlightText"} />
              <Divider width="100%" paddingTop="10px" />
              <article_title paddingTop="10px" fontWeight="bold" fontSize="12pt">Sobre</article_title>
              <Box
                style={{ fontSize: "10pt", maxHeight: "200px", overflowY: "auto", overflowX: "hidden" }}
                dangerouslySetInnerHTML={{ __html: article_content }} />
              <Spacer />
              <Divider width="100%" paddingTop="10px" />
              <Box height="5px" />
              {file_url &&
                <Button onClick={onReadMagazine} isLoading={onReadMagazineMutation.isLoading}
                        colorScheme="green" width="100%"
                        leftIcon={<FontAwesomeIcon icon={faBookReader} />}>Ler Agora</Button>}
            </VStack>
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
