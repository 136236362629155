import {
    Box,
    Button,
    Flex,
    HStack,
    Tab,
    Text,
    useMultiStyleConfig,
    useTab,
    VStack
} from "@chakra-ui/react";
import React from "react";
import { useContext } from "react";
import { useTheme } from "../../context/index";

export const CustomTab = React.forwardRef(
    ({ children, icon = null, tag, subtitle, ...props }, ref) => {
        // 1. Reuse the `useTab` hook
        const tabProps = useTab({ ...props, ref });
        const isSelected = !!tabProps["aria-selected"];

        // 2. Hook into the Tabs `size`, `variant`, props
        const styles = useMultiStyleConfig("Tabs", tabProps);
        const { primaryColor, primaryFontColor } = useTheme();
        return (
            <Button
                __css={styles.tab}
                {...tabProps}
                fontSize="11pt"
                fontWeight="bold"
                minWidth="fit-content"
                maxWidth="fit-content"
                letterSpacing="0.03rem"
                color="#808080"
                height="49px"
                transform="translateY(-2px)"
                {...(isSelected
                    ? {
                          color: `${primaryColor} !important`,
                          borderColor: `${primaryColor} !important`
                      }
                    : {
                          color: "#808080 !important",
                          borderColor: `#808080 !important`
                      })}
            >
                <HStack transform="translateY(-2px)">
                    {icon ? (
                        <i
                            style={{
                                marginRight: 10,
                                color: "inherit !important"
                            }}
                            className={icon}
                        ></i>
                    ) : (
                        <></>
                    )}
                    <VStack justify="flex-start" align="flex-start" spacing={0}>
                        <Box>{children}</Box>

                        <Text
                            transform="translateY(-3.5px) translateX(1px)"
                            fontSize="7pt"
                            fontWeight="400"
                            margin="0px"
                            color="inherit"
                            height="5px"
                        >
                            {subtitle || "   "}
                        </Text>
                    </VStack>
                </HStack>
            </Button>
        );
    }
);
