import React, { useEffect, useState } from "react";
import { axiosInstance, partiuClient } from "../axios";
import { getDataFromParameters } from "../utils";
import { APP_EVENTS, spawnAppEvent } from "../utils/app";
import { storage } from "../utils/storage";
import { useQuery } from "react-query";

export function parseProfileData(profile) {
    spawnAppEvent(APP_EVENTS.SET_USER_ID, profile.id);

    return {
        logged: true,
        name: profile.full_name,
        photo: profile.image,
        createdAt: profile.created_at,
        email: profile.email,
        telephone: profile.telephone,
        cpf: profile.cpf,
        activeAgreement: profile.active_agreement,
        agreements: profile.agreements,
        allowedPushNotifications: profile.allowed_push_notifications,
        allowedPromotionalEmails: profile.allowed_promotional_emails,
        allowedWhatsapp: profile.allowed_whatsapp,
        econometer: profile.econometer || { target: 0, progress: 0 },
        mustUpdateInfo: false,
        updateProfile: () => {
        },
        refreshProfile: () => {
        },
        subscribed: profile.subscribed,
        plans: profile.plans,
        subscriptions: profile.subscriptions || [],
        isLoading: true,
    };
}

export const noUserData = {
    logged: false,
    name: null,
    photo: null,
    email: null,
    cpf: null,
    telephone: null,
    subscribed: false,
    createdAt: new Date(),
    econometer: { target: 0, progress: 0 },
    subscriptions: [],
    updateProfile: () => {
    },
    refreshProfile: () => {
    }
};


export const UserContext = React.createContext(noUserData);

export function UserProvider({ children }) {

    const urlParams = getDataFromParameters();
    const token = urlParams.token || storage.getToken();
    const tokenExpirationDate = urlParams.tokenExpirationDate || storage.getTokenExpirationDate();

    if (token && tokenExpirationDate && new Date(tokenExpirationDate) < new Date()) {
      storage.removeToken();
      partiuClient.auth.logout();
    }

    const [data, setData] = useState(null);
    const [showNoSubscriptionModal, setShowNoSubscriptionModal] = useState(null);

    const {data: profileData, refetch, isLoading} = useQuery('profile', () => axiosInstance.get('profile/'), {
      enabled: !!token,
      onSuccess: (response) => {
        const profile = parseProfileData(response.data)

        if (
            (profile.cpf && profile.cpf.includes("i_")) ||
            (!profile.cpf && !profile.email)
        ) {
            profile.mustUpdateInfo = true;
        }

        setData(profile)
      },

      onError: (error) => {
        if (error.response.status === 401){
          if (storage.getToken()) {
              storage.removeToken();
              partiuClient.auth.logout();
          }
          if (storage.getAutoLogin()) storage.setAutoLogin(null);
        }
      }
    });

    useEffect(() => {
      if (token && (!storage.getToken() || storage.getToken() !== token)) {
          storage.setToken(token);
          partiuClient.auth.login(token);
      }
      if (tokenExpirationDate && (!storage.getTokenExpirationDate() || storage.getTokenExpirationDate() !== tokenExpirationDate)) {
          storage.setTokenExpirationDate(tokenExpirationDate);
      }
    },[token,tokenExpirationDate])

    useEffect(() => {
      if (window.location.pathname === "/legal/termos-de-uso" || !storage.getToken()){
        setData(noUserData);
        return;
      }
    },[])

    const updateProfile = (user) => {
        setData(parseProfileData(user))
    }

    return (
        <UserContext.Provider value={{...data, updateProfile, refreshProfile: refetch, resetProfile: () => setData(noUserData), showNoSubscriptionModal, setShowNoSubscriptionModal, isLoading}}>
            {children}
        </UserContext.Provider>
    );
}

export function useProfile() {
    return React.useContext(UserContext);
}
