import { Box, TabPanel, TabPanels, Tabs, Text, VStack } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { Navigate, useSearchParams } from "react-router-dom";
import { Title } from "../../components/atoms/Title";
import { CurrentLocation } from "../../components/molecules/CurrentLocation";
import { CustomTab } from "../../components/molecules/CustomTabs";
import { AccountDependants } from "../../components/organisms/AccountDependants";
import { CustomTabList } from "../../components/organisms/CustomTabList";
import { useSettings, useTheme } from "../../context/index";
import { useProfile } from "../../context/user";
import { Agreements } from "./components/Agreements";
import { Menu } from "./components/Menu";
import { Preferences } from "./components/Preferences";
import { ProfileData } from "./components/ProfileData";

export function ProfilePage() {
    const { name: agreementName, defaultBackgroundColor } = useTheme();
    const { name, subscriptions, logged } = useProfile();
    const { agreementApp } = useSettings();
    const { integratedLogin } = useTheme();
    const [searchParams] = useSearchParams();
    const [tabIndex, setTabIndex] = useState(0);

    const handleTabsChange = (index) => {
        setTabIndex(index);
    };

    console.log(subscriptions);
    useEffect(() => {
        if (searchParams.get("selectedTab") === "profile") {
            setTabIndex(1);
        }
    }, [searchParams]);

    if (!logged)
        return (
            <Navigate
                to={{
                    pathname: integratedLogin ? "/login/parceiro" : "/login"
                }}
            />
        );

    return (
        <VStack
            backgroundColor={defaultBackgroundColor}
            spacing={8}
            width="100%"
            overflow="hidden"
        >
            <VStack width="90%" margin="auto" spacing={1} align="flex-start">
                {/* {econometerModule ? <UserEconometer changeImage={true} hideImage={!isApp} /> : <></>} */}
                <Title paddingTop="20px" fontSize="20px">
                    {name}
                </Title>
                <Text
                    paddingBottom="10px"
                    color="#495057"
                    opacity="0.5"
                    fontSize="13px"
                >
                    {agreementName}
                </Text>
                <CurrentLocation />
            </VStack>
            <Tabs
                isLazy
                width="100%"
                index={tabIndex}
                onChange={handleTabsChange}
            >
                <CustomTabList paddingLeft={{ sm: "0%", lg: "5%" }}>
                    <CustomTab>Menu</CustomTab>

                    <CustomTab>Dados Pessoais</CustomTab>
                    {subscriptions?.length > 0 && (
                        <CustomTab>Dependentes</CustomTab>
                    )}
                    <CustomTab>Preferências</CustomTab>
                    {/* {agreementApp == null ? (
                        <CustomTab>Adesões</CustomTab>
                    ) : (
                        <></>
                    )} */}
                </CustomTabList>
                <TabPanels paddingBottom="50px" overflowY="auto" align="center">
                    <TabPanel>
                        <Menu />
                    </TabPanel>

                    <TabPanel>
                        <ProfileData />
                    </TabPanel>
                    {subscriptions?.length > 0 && (
                        <TabPanel>
                            <Box width={"90%"} margin={"auto"}>
                                <AccountDependants />
                            </Box>
                        </TabPanel>
                    )}
                    <TabPanel>
                        <Preferences />
                    </TabPanel>
                    {agreementApp == null ? (
                        <TabPanel>
                            <Agreements />
                        </TabPanel>
                    ) : (
                        <></>
                    )}
                </TabPanels>
            </Tabs>
        </VStack>
    );
}
