import { Box, Button, Divider, HStack, Image, Link, Modal, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay, Text, VStack, useDisclosure, Spinner } from "@chakra-ui/react";
import { faExternalLink, faQuestionCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { ModalBody, ModalTitle } from "react-bootstrap";
import { useQuery } from "react-query";
import { listMyVouchers } from "../../api/vouchers";
import { Title } from "../../components/atoms/Title";
import { TopMenu } from "../../components/organisms/TopMenu";
import { useTheme } from "../../context";
import { useProfile } from "../../context/user";
import { toBRLDate } from "../../utils/dates";

function Purchase({ purchase }) {
    const howToUseModalDisclosure = useDisclosure();
    const { primaryColor, primaryFontColor, secondaryColor } = useTheme();
    const link = React.useMemo(() => {
      if (purchase.sale.discount.mode === "local") return "/detalhes/local/" + purchase.sale.discount.stores[0];

      return "/detalhes/online/" + purchase.sale.discount.company.id
    }, [purchase])
    return (
        <VStack align="flex-start" className="layered-box" borderRadius="5px" border="1px solid #eee">
            <Modal {...howToUseModalDisclosure}>
                <ModalOverlay />
                <ModalContent>
                    <ModalCloseButton />
                    <ModalHeader>
                        <ModalTitle>
                            Como Usar
                        </ModalTitle>
                    </ModalHeader>
                    <ModalBody >
                        <Text dangerouslySetInnerHTML={{ __html: purchase.sale.discount?.how_to_use?.replaceAll("\n", "<br/>") }} />
                    </ModalBody>
                </ModalContent>
            </Modal>
            <HStack align="flex-start" padding="20px" paddingBottom="10px">
                <Box>
                    <Image borderRadius="5px" objectFit="cover" boxSize="100px" src={purchase.sale.discount.image} />
                </Box>
                <VStack align={"flex-start"}>
                    <Title fontSize="16pt">{purchase.sale.discount.company.name}</Title>
                    <Title fontSize="8pt">Comprado em: {toBRLDate(new Date(purchase.sale.created_at))}</Title>
                    <Title fontSize="8pt">Valido até: {toBRLDate(new Date(purchase.valid_until))}</Title>
                </VStack>
            </HStack>
            <Link href={link} paddingLeft="20px">Ver regulamento<FontAwesomeIcon style={{ marginLeft: 5 }} icon={faExternalLink} /></Link>
            {purchase.sale.discount.how_to_use && <Button transform="translateX(18px)" _selected={{ backgroundColor: secondaryColor }} onClick={howToUseModalDisclosure.onOpen} color={primaryFontColor} backgroundColor={primaryColor}>Como Usar <FontAwesomeIcon style={{ marginLeft: 5 }} icon={faQuestionCircle} /></Button>}
            <Divider />
            <HStack align="flex-end" justify="flex-end" alignSelf="flex-end" padding="0px 20px" paddingBottom="10px">
                <Title fontSize="11pt" margin="0px" padding="0px">Voucher: {purchase.code.replace(",", "")}</Title>
            </HStack>
        </VStack>
    )
}

export function MyPucharsesPage() {
    const { logged } = useProfile();
    const { data: purchases, isLoading, isFetching, isSuccess } = useQuery("my-vouchers", listMyVouchers, {
      enabled: logged,
      refetchOnMount: true,

      keepPreviousData: false
    });

    const getMainContent = (purchases, isLoading, isFetching, isSuccess) => {
      if(isLoading || isFetching) return (
        <VStack width={"100vw"} height={"100vh"} justifyContent={"center"}>
          <Text marginBottom={"16px"}>Aguarde! Estamos carregando suas compras</Text>
          <Spinner size="xl" />
        </VStack>
      );

      if (isSuccess && purchases?.length > 0)
        return purchases.map(purchase => <Purchase key={'purchase_' + purchase.id} purchase={purchase} />)

      if (isSuccess && purchases?.length === 0)
        return (
          <VStack width={"100%"} height={"80vh"} justifyContent={"center"} paddingX={"16px"}>
            <Text>{`Você ainda não possui compras! Navegue pela nossa plataforma`}</Text>
          </VStack> 
        )
    };

    return (
      <VStack
        alignItems="center"
        flexDirection="column"
        justifyContent="center"
        background={"#f4f4f4"}
        boxSizing="border-box"
        paddingBottom={"120px"}
        width="100%"
        height="100%"
      >
        <TopMenu />
        <VStack paddingTop={"64px"} spacing={8} width="100%" paddingX={"16px"} height="100%">
            {getMainContent(purchases, isLoading, isFetching, isSuccess)}
        </VStack>
      </VStack>
    );
};
