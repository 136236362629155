import { Heading } from "@chakra-ui/react";
import React from "react";

export function MainTitle({ children, ...style }) {
    return (
        <Heading
            fontSize="30px"
            fontWeight={500}
            fontFamily="Raleway"
            {...style}
        >
            {children}
        </Heading>
    );
}

export function SubTitle({ children, ...style }) {
    return (
        <MainTitle fontSize="20px" {...style}>
            {children}
        </MainTitle>
    );
}

export function SmallTitle({ children = "", ...style }) {
    return (
        <MainTitle fontSize="12px" fontWeight="800" {...style}>
            {children.toUpperCase()}
        </MainTitle>
    );
}
