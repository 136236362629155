import { axiosInstance, axiosInstanceV2, partiuClient } from "../axios";

export function withParams(baseUrl, obj) {
    let url = "";

    Object.keys(obj).forEach((k, i) => {
        if (obj[k] !== null && obj[k] !== "" && obj[k] !== undefined)
            url += (i === 0 ? "" : "&") + `${k}=${obj[k]}`;
    });

    if (url === "") return baseUrl;

    return baseUrl + "?" + url;
}

export const getOfferInfo = (id, mode, agreementID = null) => {
    let url = mode === "local" ? `/stores/${id}/` : `/discounts/${id}/`;

    if (agreementID) {
        url += "?agreement=" + agreementID;
    }

    return axiosInstance.get(url).then(({ data }) => data);
};

export const getFeaturedDiscounts = () => {
    return axiosInstance
        .get("/discounts/?featured=true&limit=12")
        .then(({ data }) => data.results);
};

export const getStoresNearby = (params) => {
    let agreement = params.agreementId;

    if (agreement && agreement !== "null") {
        params["agreement"] = agreement;
    }

    params["limit"] = 12;

    if (params.type && params.type === "exclusive") {
        delete params["type"];

        params["limit"] = 1000000;
        params["exclusive"] = true;
    } else {
        if (!params["lat"] || !params["long"]) {
            console.error("Cant load nearby stores withou lat and long");
            return Promise.reject();
        }
    }

    return axiosInstance
        .get(withParams("/stores/", params))
        .then(({ data }) => data);
};

export const getPromotions = (params) => {
        return axiosInstanceV2.get(withParams('promotion/promotions/', params)).then(({ data }) => data);
};

export const retrievePromotion = (id) => {
    return axiosInstanceV2.get(`promotion/promotions/${id}/`).then(({ data }) => data);
};

export const getOnlineDiscounts = (params) => {
    params["limit"] = 12;

    if (params.type && params.type === "exclusive") {
        delete params["type"];
        params["exclusive"] = true;
        params["limit"] = 1000000;
    }

    let agreement = params.agreementId;

    if (agreement && agreement !== "null") {
        params["agreement"] = agreement;
    }

    return axiosInstance
        .get(withParams("/online_discounts/", params))
        .then(({ data }) => data)
        .catch(() => []);
};

export const getUsage = (year) => {
    return axiosInstance.get(`/usage/?year=${year}`).then(({ data }) => data);
};

export const createUsage = ({
    discount,
    feedback,
    user_given_discount,
    virtual_card_code = null
}) => {
    return axiosInstance
        .post(`/usage/`, {
            discount,
            feedback,
            user_given_discount,
            virtual_card_code
        })
        .then(({ data }) => data);
};
