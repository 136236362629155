import { Flex, Text, VStack } from "@chakra-ui/react";
import { faFrown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { useTheme } from "../../context/index";
import { useProfile } from "../../context/user";
import { numberToBRL } from "../../utils/money";
import { Gradient } from "../atoms/Gradient";
import { Title } from "../atoms/Title";

export function EconometerBar({
    barHeight = "27px",
    barFontSize = "10px",
    subtitleFontSize = "9pt",
    forceShow = false,
    showFootNote = true
}) {
    const { econometer } = useProfile();

    if (econometer.progress === 0 && !forceShow)
        return (
            <VStack
                color="#495057"
                textAlign="left"
                opacity="0.5"
                fontSize={subtitleFontSize}
                width="100%"
                spacing={5}
            >
                <FontAwesomeIcon size="3x" icon={faFrown} />
                <Text>Ainda não registramos nenhuma utilização este mês.</Text>
            </VStack>
        );

    return (
        <VStack align="flex-start" width="100%">
            <Flex
                borderRadius="1rem"
                height={barHeight}
                width="100%"
                transform="translateX(-1%)"
                backgroundColor="rgba(0,0,0,0.02)"
                border="1px solid rgba(0,0,0,0.1)"
                align="center"
                position="relative"
            >
                <Gradient
                    borderRadius="1rem"
                    Component={Flex}
                    height="100%"
                    position="relative"
                    width={
                        Math.min(
                            Math.floor(
                                (econometer.progress * 100) / econometer.target
                            ),
                            100
                        ) + "%"
                    }
                    align="center"
                >
                    {econometer.progress > 0 ? < Title
                        color="white"
                        position="absolute"
                        right="11px"
                        fontSize={barFontSize}
                        letterSpacing="0px"
                    >
                        {numberToBRL(econometer.progress)}
                    </Title> : <></>}
                </Gradient>
                {econometer.target > econometer.progress ? (
                    <Title
                        position="absolute"
                        right="10px"
                        justifySelf="flex-end"
                        fontSize={barFontSize}
                        color="rgba(0,0,0,0.25)"
                        letterSpacing="0px"
                    >
                        {numberToBRL(econometer.target)}
                    </Title>
                ) : (
                    <></>
                )}
            </Flex>
            {
                showFootNote ? (
                    <Text
                        color="#495057"
                        textAlign="left"
                        opacity="0.5"
                        fontSize={subtitleFontSize}
                        width="100%"
                    >
                        Se refere ao valor da sua mensalidade aproximada.
                    </Text>
                ) : (
                    <></>
                )
            }
        </VStack >
    );
}
