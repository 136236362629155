export function getGeolocation(enableHighAccuracy = false) {
    return new Promise((resolve, reject) => {
        if (!navigator.geolocation) return reject();

        navigator.geolocation.getCurrentPosition(
            (geo) => {
                resolve({
                    latitude: geo.coords.latitude,
                    longitude: geo.coords.longitude
                });
            },
            (error) => {
                console.error("Location:", error.message, error);

                if (enableHighAccuracy) return getGeolocation(enableHighAccuracy = false);
                reject(error.message);
            },
            { timeout: 10000, maximumAge: 0, enableHighAccuracy }
        );
    });
}
