import {
    Button,
    Image,
    useToast,
    VStack,
    Flex,
    Spacer,
    Text,
    Link
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { useTheme, useSettings } from "../../context";
import { PasswordInput } from "../../components/atoms/PasswordInput";
import { Title } from "../../components/atoms/Title";
import { Input } from "../../components/atoms/Input";
import { loginSercomtel } from "../../api/sercomtel";
import { useNavigate, Link as LinkRouter, useSearchParams } from "react-router-dom";
import { loginLeste } from "../../api/leste";
import { useMutation } from "react-query";
import { login as loginFunc } from "../../api/login";
import { useLogin } from "../Login/context/login.context";

export function DirectLogin() {
  const { id, agreement, name, logo, planSaleModule, primaryColor, primaryFontColor, bookstoreModule } = useTheme();
  const toast = useToast();
  const [isLoading, setIsLoading] = useState(false)
  const { isIOS } = useSettings();
  const [searchParams] = useSearchParams();
  const { login } = useLogin();

  const navigate = useNavigate();

  const [username, setUsername] = useState("")
  const [password, setPassword] = useState("")

  const loginMutation = useMutation(() => loginFunc(id, username, password), {
    onSuccess({ data, status }) {
      if (status === 400)
        return toast({
          title: "Oops!",
          description: data.error,
          status: "error"
        });
      login(data.token, data.user);
      if (searchParams.get("redirectUrl")) {
        navigate(searchParams.get("redirectUrl"));
      } else {
        navigate("/");
      }
    },
    onError() {
      toast({
        title: "Oops!",
        description: "Esta combinação de email/cpf e senha não existe! Verifique seus dados e tente novamente",
        status: "error"
      });
    }
  });

  function redirect(token) {
    window.location.href = `/?token=${token}`;
  }

  useEffect(() => {
    let savedData = localStorage.getItem("auto-login");

    if (!savedData) return;

    try {
      savedData = JSON.parse(savedData);
      if (savedData.slug && savedData.token)
        redirect(savedData.token);
    } catch {
      localStorage.removeItem("auto-login");
    }
  }, []);

  return (
    <VStack
      forcePrimaryColor={agreement?.primaryColor}
      forceSecondaryColor={agreement?.secondaryColor}
      marginTop={isIOS ? "-35px" : "0px"}
      justify="center"
      align="center"
      direction="column"
      margin="auto"
      paddingBottom="100px"
      width={{ base: "100%", lg: "50%" }}
    >
      <Flex justify="center" align="center" width="100%" height="100%">
        <Flex
          display={{ base: "none", md: "flex" }}
          flex="1"
          height="100%"
          backgroundColor="#FAFAFA"
          justify="center"
          align="center"
        >
          <Image
            w="300px"
            objectFit="contain"
            src={agreement?.logo || logo}
          />
        </Flex>
        <Flex
          direction="column"
          spacing={10}
          justify="center"
          width="100%"
          align="center"
          minHeight="100%"
        >
          <Flex justify="center" align="center" minH="250px" flex="1">
            <Image
              maxW="300px"
              // w="30%"
              objectFit="cover"
              src={agreement?.logo || logo}
            />
          </Flex>
          <VStack flex="1" width="80%">
            <VStack
              align="flex-start"
              spacing={3}
              width={{ base: "100%", lg: "60%" }}
            >
              <Title paddingBottom="10px">Entre utilizando seu nome de usuário e senha</Title>
              <Input
                value={username}
                onChange={setUsername}
                placeholder="Nome de usuário"
                type="text"
              />
              <VStack width="100%" spacing={2}>
                <PasswordInput
                  pr='4.5rem'
                  value={password}
                  onChange={setPassword}
                  placeholder="Senha"
                />
                <Link
                  fontSize="10px"
                  alignSelf="end"
                  textDecoration="underline !important"
                  margin="0px"
                  href={`${process.env.REACT_APP_API_URL}/password_reset/?agreement_id=${id}`}
                  target="_blank"
                  height={"48px"}
                >
                  Esqueci minha senha
                </Link>
              </VStack>

              <Spacer />
              <Button
                width="100%"
                isLoading={loginMutation.isLoading}
                onClick={loginMutation.mutate}
              >
                Entrar
              </Button>
              {planSaleModule && (
                <>
                  <Button backgroundColor={primaryColor} color={primaryFontColor} marginTop={-4} onClick={() => navigate("/signup")} width={"100%"}>
                    Com Assinatura
                  </Button>
                </>
              )}
            </VStack>
          </VStack>
        </Flex>
      </Flex>
    </VStack>
  );
}
