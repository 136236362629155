import { Button } from "@chakra-ui/react";
import React, { useContext } from "react";
import { useSettings, useTheme } from "../../context/index";

export function WhatsappBall() {
    const { isApp } = useSettings();
    const { cellphone } = useTheme();
    if (isApp) return <></>;

    return (
        <a
            style={{ zIndex: 100000 }}
            target="_blank"
            rel="noopener noreferrer"
            href={`https://api.whatsapp.com/send?phone=55${cellphone ? cellphone : "21974204416"}&text=Olá, gostaria de falar com a Partiu!`}
        >
            <Button
                zIndex="100"
                colorScheme="green"
                position="fixed"
                right={{ base: "5%", lg: "50px" }}
                bottom={{ base: "85px", lg: "45px" }}
                borderRadius="100px"
                height="50px"
                width="50px"
                justifyContent="center"
                alignContent="center"
            >
                <i style={{ fontSize: 30 }} className="fab fa-whatsapp"></i>
            </Button>
        </a>
    );
}
