import { axiosInstance } from "../axios";

export const getYoucastPartners = async () => {
    return axiosInstance
        .get(`/youcast/partners`)
        .then(({ data }) => data);
};

export const loginYoucast = async ({ username, password, idcompany }) => {
    return axiosInstance
        .post(`/youcast/login/`, {
            login: username,
            password,
            idcompany
        })
        .then(({ data }) => data);
};