const setUser = (user) => localStorage.setItem("user", JSON.stringify(user));
const getUser = () => JSON.parse(localStorage.getItem("user"));

const setToken = (token) => localStorage.setItem("token",token);
const getToken = () => localStorage.getItem("token");
const setTokenExpirationDate = (tokenExpirationDate) => localStorage.setItem("tokenExpirationDate",tokenExpirationDate);
const getTokenExpirationDate = () => localStorage.getItem("tokenExpirationDate"); 
const removeToken = () => localStorage.removeItem("token");


const setAgreementId = (agreementId) => localStorage.setItem("agreementId",agreementId);
const getAgreementId = () => localStorage.getItem("agreementId");
const removeAgreementId = () => localStorage.removeItem("agreementId");

const setAutoLogin = (user) => localStorage.setItem("auto-login", JSON.stringify(user));
const getAutoLogin = () => JSON.parse(localStorage.getItem("auto-login"));

export const storage = {
setUser,
  getUser,
  getToken,
  setToken,
  setTokenExpirationDate,
  getTokenExpirationDate,
  removeToken,
  setAutoLogin,
  getAutoLogin,
  setAgreementId,
  getAgreementId,
  removeAgreementId
}

