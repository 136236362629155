import { Link, Text, VStack } from "@chakra-ui/react";
import React, { useEffect } from "react";
import { Button } from "../components/atoms/Button";
import { Title } from "../components/atoms/Title";
import { Footer } from "../components/organisms/Footer";
import server from "../server";
import { BannerPageLayout } from "../templates/BannerPageLayout";

export function NotInAgreement() {
    useEffect(() => {
        if (server.user) {
            server.user = null;
            server.redirectToError = false;
            localStorage.clear();
            window.location.reload();
        }
    }, []);

    return (
        <BannerPageLayout title="Oops!">
            <i style={{ fontSize: 40 }} className="fas fa-frown"></i>
            <Title>
                Poxa! Parece que você não está mais vinculado à este convênio...
            </Title>
            <Text>
                Mas não tem problema! Você pode continuar usando a Partiu usando
                outro convênio ou pagando pela nossa assinatura!
            </Text>
            <Link href="https://www.partiu.com.br/paraVoce">
                <Button>Saiba Mais</Button>
            </Link>
        </BannerPageLayout>
    );
}
