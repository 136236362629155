import React from "react";
import { VStack, Text, Box, AspectRatio } from "@chakra-ui/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHeart } from "@fortawesome/free-solid-svg-icons";
import { BannerPageLayout } from "../../templates/BannerPageLayout";
import { TopMenu } from "../../components/organisms/TopMenu";
import { Title } from "../../components/atoms/Title";
import { useTheme } from "../../context";

const SuccessPage = () => {
    const { menuColor, secondaryColor } = useTheme();

    return (
        <BannerPageLayout title="Cadastro Criado">
            <TopMenu />
            <VStack spacing={"10"} alignItems="center">
                <Title fontSize="xl" textAlign="center" fontStyle={"bold"}>
                    Parabéns, cadastro criado com sucesso!
                </Title>
                <Text fontSize="lg" textAlign="center">
                    Em breve um consultor entrará em contato para realizar um treinamento com você.
                </Text>
                <Box textAlign="center" spacing={"10"}>
                    <Text fontSize="lg">Estamos felizes em tê-lo conosco! <FontAwesomeIcon icon={faHeart} color={secondaryColor || menuColor || "#FFFFFF"} style={{ marginRight: "0.5rem" }} /> </Text>
                    <AspectRatio ratio={16/9}>
                        <iframe
                            src="https://player.vimeo.com/video/175736399?h=9c3d4d887f&autoplay=1&title=0&byline=0&portrait=0"
                            width="640" height="360" allow="autoplay; fullscreen; picture-in-picture"
                            allowFullScreen>
                        </iframe>
                    </AspectRatio>
                </Box>
            </VStack>
        </BannerPageLayout>
    );
};

export default SuccessPage;
