import {
    Box,
    Button,
    Input,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    useDisclosure,
    useToast
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { useMutation } from "react-query";
import { useLocation } from "react-router-dom";
import { useTheme } from "../../../context/theme";
import { useProfile } from "../../../context/user";
import { checkPromocode, vinculatePromocode } from "../../../api/register";

export function AddAgreementButton({ children, ...props }) {
    const context = useDisclosure();
    const toast = useToast();
    const [code, setCode] = useState();
    const { refreshProfile } = useProfile();
    const { state } = useLocation();
    const { id } = useTheme();
    const vinculateMutation = useMutation(
        () => vinculatePromocode(code),
        {
            onSuccess({ data }) {
                setCode("");
                context.onClose();
                refreshProfile();
                toast({
                    title: "Sucesso!",
                    description: `Você foi vinculado ao convênio ${data.agreement.name}`,
                    status: "success"
                });
            },
            onError() {
                toast({
                    title: "Oops!",
                    description:
                        "Não foi possível se juntar com esse código. Verifique se você já não é cliente desse convênio.",
                    status: "error"
                });
            }
        }
    );
    const validateMutation = useMutation(
        () => checkPromocode(code, id),
        {
            onSuccess(data) {
                vinculateMutation.mutate();
            },
            onError() {
                toast({
                    title: "Oops!",
                    description: "Esse código é inválido ou está expirado.",
                    status: "error"
                });
            }
        }
    );

    useEffect(() => {
        if (!state?.openPromocode) return;

        context.onOpen();
    }, [state]);

    return (
        <>
            <Modal isCentered {...context}>
                <ModalOverlay />
                <ModalContent width="90%">
                    <ModalHeader textAlign="left">
                        Adicionar Promocode
                    </ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <Input
                            value={code}
                            onChange={(e) =>
                                setCode(e.target.value.toUpperCase())
                            }
                        />
                    </ModalBody>
                    <ModalFooter>
                        <Button
                            variant="ghost"
                            mr={3}
                            onClick={context.onClose}
                        >
                            Cancelar
                        </Button>
                        <Button
                            onClick={validateMutation.mutate}
                            colorScheme="green"
                            isLoading={
                                validateMutation.isLoading ||
                                vinculateMutation.isLoading
                            }
                        >
                            Vincular
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
            <Box width="100%" onClick={context.onOpen} {...props}>
                {children}
            </Box>
        </>
    );
}
