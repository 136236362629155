import { supportsLocalStorage } from "./utils";

class Server {
    constructor() {
        // let homolServer = "http://localhost:8080";
        // let develServer = "https://partiu-server-devel.herokuapp.com";
        let prodServer = "https://partiu-server.herokuapp.com";

        this.url = prodServer;
        this.logourl =
            "https://sistema.partiuvantagens.com.br/files/logo/thumb/225x150_";
        this.promocaourl =
            "https://sistema.partiuvantagens.com.br/files/promocao/thumb/750x750_";
        this.maps_key = "AIzaSyAr_9ffeFYsXs6rtjhqpoRLaMvMc6RSpMg";

        if (supportsLocalStorage()) {
            this.token = localStorage.getItem("token");
            //this.user = JSON.parse(localStorage.getItem("user"));
            if (!localStorage.getItem("isAdmin")) this.isAdmin = false;
            else this.isAdmin = JSON.parse(localStorage.getItem("isAdmin"));
        }

        this.isStaff = false;
        this.isMember = false;

        // Filters Storage
        this.filtersCache = [];
        this.starredOffersCache = {};

        this.wallpaper = null;
    }
}

export default new Server();
