import { Heading, HStack, Stack, Text, VStack } from "@chakra-ui/react";
import React from "react";
import { Card } from "../../../components/atoms/Card";
import { Title } from "../../../components/atoms/Title";
import { useTheme } from "../../../context";

function StatisticBox({ number, title, icon }) {
    const { primaryColor } = useTheme();

    return (
        <Card hover>
            <HStack width="100%" justifyContent="space-between">
                <i
                    style={{ fontSize: "30px", color: primaryColor }}
                    className={icon}
                ></i>
                <VStack alignItems="flex-end" marginLeft="auto">
                    <HStack>
                        <Heading color={primaryColor}>+</Heading>
                        <Heading color={primaryColor}>{number}</Heading>
                    </HStack>
                    <Title color={primaryColor}>{title}</Title>
                </VStack>
            </HStack>
        </Card>
    );
}

export const Statistics = () => (
    <Stack
        width={{ sm: "80%", lg: "70%" }}
        direction={{ sm: "column", lg: "row" }}
        margin="auto"
        spacing={10}
        padding="70px 0px"
        justifyContent="space-between"
    >
        <StatisticBox
            number={3000}
            icon="fas fa-store"
            title="Estabelecimentos"
        />
        <StatisticBox number={100} icon="fas fa-tag" title="Parcerias E-commerces" />
        
    </Stack>
);
