import { PartiuClient } from "@partiu-vantagens/partiu-sdk";
import axios from "axios";
import { storage } from "./utils/storage";

let baseUrl = `${process.env.REACT_APP_API_URL}/api/v1`;

export const axiosInstance = axios.create({
    baseURL: baseUrl,
    validateStatus: (status) => {
        if (status === 401) {
            localStorage.removeItem("token");
            localStorage.removeItem("auto-login");
            // window.location.href = "/"
        }
        return status < 400;
    }
});

export const axiosInstanceV2 = axios.create({
    baseURL: baseUrl.replace("v1", "v2"),
    validateStatus: (status) => {
        if (status === 401) {
            localStorage.removeItem("token");
            localStorage.removeItem("auto-login");
            // window.location.href = "/"
        }
        return status < 400;
    }
});

export const partiuClient = new PartiuClient(false);

function configRequest (config) {
    const token = storage.getToken()
    const agreementId = storage.getAgreementId()
    if (token){
        config.headers.common["Authorization"] = `TOKEN ${token}`;
    } else {
        delete config.headers.common["Authorization"];
    }

    if (agreementId) config.headers.common["x-agreement-id"] = agreementId;
    else delete config.headers.common["x-agreement-id"];
    return config;
  }

axiosInstance.interceptors.request.use(configRequest);
partiuClient.axiosInstance.interceptors.request.use(configRequest);
axiosInstanceV2.interceptors.request.use(configRequest);

// // Add a response interceptor
// axiosInstance.interceptors.response.use(null, function (error) {
//     // Any status codes that falls outside the range of 2xx cause this function to trigger
//     // Do something with response error
//     if (error.response.status === 401){

//     }
//     return Promise.reject(error);
//   });


export function isAuthenticated() {
    return axiosInstanceV2.defaults.headers.common["Authorization"];
}
