import { Box, HStack, Skeleton, VStack } from '@chakra-ui/react'
import React from 'react'
import { Book } from './Book'
import { Title } from '../../../components/atoms/Title'
import { Button } from '../../../components/atoms/Button'
import { Link } from 'react-router-dom'
import { BookGrid } from './BookGrid'
import InfiniteScroll from 'react-infinite-scroll-component'

export function BookHStack({ title, books, subtitle = "", isLoading, onSelectBook, seeMoreLink, padding="16px" }) {

    if (isLoading || !books) return (
        <VStack align="flex-start" width="100%">
            <Title paddingLeft="16px" paddingBottom="20px">{title}</Title>
            <HStack paddingLeft="16px" gap={7} align="flex-start">
                {new Array(5).fill(0).map((_, i) => <VStack key={i}>
                    <Skeleton borderRadius="5px" maxWidth={{ base: "165px", lg: "205px", "2xl": "260px" }}
                height={{ base: "270px", lg: "310px", "2xl": "410px" }} />
                </VStack>)}
            </HStack>
        </VStack>
    )

    return (
        <VStack width="100%" spacing={4} alignContent="flex-start" padding={`0px ${padding}`}>
            <Title alignSelf="flex-start">{title}</Title>
            {subtitle !== '' && <Title fontSize={14} alignSelf="flex-start">{subtitle}</Title>}
            <Box width={'100%'} >
                <InfiniteScroll
                    dataLength={books} //This is important field to render the next data
                    style={{
                        overflow: "unset",
                        height: "100%",
                        paddingTop: "8px"
                    }}
                    next={() => {
                        if (isLoading) return;
                    }}
                    hasMore={false}
                    loader={
                        <BookGrid paddingTop="20px">
                            {new Array(4).fill(0).map((_, i) => (
                                <Skeleton key={i} borderRadius="5px" maxWidth={{ base: "165px", lg: "205px", "2xl": "260px" }}
                                height={{ base: "270px", lg: "310px", "2xl": "410px" }}  />
                            ))}
                        </BookGrid>
                    }
                    endMessage={<></>}
                >
                    <BookGrid>
                        {books.map(b => <Book onClick={() => onSelectBook(b)} {...b} />)}
                    </BookGrid>
                </InfiniteScroll>
            </Box>
            {seeMoreLink ? <Box width="100%" paddingLeft="5%"><Button fontSize="8pt" height="30px">
                <Link
                    style={{ textDecoration: "none!important" }}
                    to={{ pathname: seeMoreLink }}
                >
                    Veja todos {">>"}
                </Link>
            </Button></Box> : <></>}
        </VStack>
    )
}