import { Box } from "@chakra-ui/react";
import React, { useContext } from "react";
import { useTheme } from "../../context/index";
import { useSettings } from "../../context/index";

export function IOSSpacer() {
    const { isApp, isIOS } = useSettings();
    const { defaultBackgroundColor } = useTheme();

    if (!isApp || !isIOS) return <></>;

    return <Box height="35px" backgroundColor={defaultBackgroundColor} />;
}
