import { Button } from "@chakra-ui/button";
import { HStack } from "@chakra-ui/layout";
import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay
} from "@chakra-ui/modal";
import { Input, Spinner } from "@chakra-ui/react";
import React, { useEffect, useRef, useState } from "react";
import { updateEmail } from "../../../api/profile";
import { useProfile } from "../../../context/user";

export const EmailModal = ({ isOpen, onClose, sendEmail }) => {
    const btnRef = useRef();
    const profile = useProfile();
    const [email, setEmail] = useState("");
    const [firstEmail, setFirstEmail] = useState("");

    useEffect(() => {
      if (profile.email) {
        setEmail(profile.email);
        setFirstEmail(profile.email);
      }
    },[profile])


    const onSubmit = async () => {
        if (profile.email !== email) {
          profile.email = email;
            await updateEmail(email);
        }
        sendEmail();
        onClose();
    };

    const isFirstEmailMissing = firstEmail === undefined || firstEmail === "";

    return (
        <Modal
            isCentered
            isOpen={isOpen}
            onClose={onClose}
            ref={btnRef}
            id="email-modal"
        >
            <ModalOverlay
                backgroundColor="rgba(0,0,0,0.8)"
                style={{
                    backdropFilter: "blur(5px)",
                    WebkitBackdropFilter: "blur(5px)"
                }}
            />
            <ModalContent
                width="90%"
                padding="10px"
                display="flex"
                flexDirection="column"
                alignItems="center"
            >
                {profile.isLoading ? (
                    <Spinner size="xl" />
                ) : (
                    <>
                        <ModalHeader paddingTop={"48px"}>
                            {isFirstEmailMissing ? "Email não encontrado" : "Deseja enviar para este email?"}
                        </ModalHeader>
                        <ModalCloseButton height={"48px"} width={"48px"}/>
                        {isFirstEmailMissing && (
                            <ModalBody>
                                Olá, notamos que você não possui email cadastrado. Por favor, atualize seu email abaixo:
                            </ModalBody>
                        )}
                        <Input
                            maxWidth={"400px"}
                            placeholder={isFirstEmailMissing ? "Digite seu email aqui..." : firstEmail}
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                        />
                        <ModalFooter>
                            <HStack>
                                <Button onClick={onSubmit} width="100%">
                                    {isFirstEmailMissing ? "Atualizar email" : "Confirmar email"}
                                </Button>
                            </HStack>
                        </ModalFooter>
                    </>
                )}
            </ModalContent>
        </Modal>
    );
};
