import { Box, Grid } from '@chakra-ui/react';
import React from 'react'
import { useCheckMobile } from '../../../utils';

export function MagazineGrid({ children, templateColumns = 5, templateColumnsMobile = 2, ...props }) {
    const isMobile = useCheckMobile();

    return (
        <Box
            width={{ sm: "100%", xl: "100%" }}
            margin="auto"
            direction="column"
            flex="1"
            {...props}
        >
            <Grid
                templateColumns={`repeat(${isMobile ? templateColumnsMobile : templateColumns}, 1fr)`}
                gap={5}
            >
                {children}
            </Grid>
        </Box>
    );
}