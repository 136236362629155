import { Flex, Image, Tag, VStack } from "@chakra-ui/react";
import React from "react";
import { Title } from "../../../components/atoms/Title";
import { toBRLDate } from "../../../utils/dates";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMedal } from "@fortawesome/free-solid-svg-icons";
import { levels } from "../../../common/Level";

export function Book({ id, title, cover, agreement = null, author, onClick, added_at = null, level = null }) {
    let inverseLevel = (level || 1) ? 3 - (level || 1) : -1;

    if(level === 0){
      level = 99
    }
    if(level === 5){
      level = 0
      inverseLevel = (level || 1) ? 4 - (level || 1) : -1;
    }
    const planColors = ["gold", "darkgrey", "#cd7f32", "#DDAB7EFF"];

    return (
        <VStack onClick={onClick} align="flex-start" width="100%" cursor="pointer">
            <Flex
                width="100%"
                boxSizing="border-box"
                overflow="hidden"
                className="layered-box"
                backgroundColor="white"
                borderRadius="5px"
                position="relative"
                maxWidth={{ base: "165px", lg: "205px", "2xl": "260px" }}
                height={{ base: "270px", lg: "310px", "2xl": "410px" }}
            >
                <Image
                    src={cover}
                    objectFit={'cover'}
                    objectPosition={"center"}
                    transition="0.5s"
                    width="100%"
                    height="100%"
                    backgroundColor="white"
                    flexShrink="0"
                    _hover={{
                        transform: "scale(1.05)"
                    }}
                />
                <VStack backdropFilter={"blur(5px)"} borderBottomLeftRadius={"10px"} backgroundColor={"rgba(255,255,255,0.3)"} position={"absolute"}
                    padding="10px" right={"0px"}
                    top={"0px"}>
                    {inverseLevel > -1 ? new Array(inverseLevel + 1).fill(0).map((_, i) =>
                        <FontAwesomeIcon filter={"drop-shadow(1px 2px 3px rgba(0,0,0,0.3))"} fontSize={21} key={i}
                            icon={faMedal}
                            color={planColors[i]} />
                    ) : <></>}
                </VStack>
                {/* {(level===99) && <Tag fontWeight={700} colorScheme={"green"} borderRadius={"0px"} position={"absolute"} right={0} bottom={0}>Gratuito</Tag>} */}
            </Flex>
            <VStack align="flex-start" paddingTop="5px" spacing={1}>
                <Title fontWeight="bold" fontSize={{ base: "9.5pt", lg: "12pt" }}>{title.length > 25 ? `${title?.slice(0, 25).toUpperCase()}...` : title?.toUpperCase()}</Title>
                <Title opacity="0.8" fontSize={{ base: "8pt", lg: "10pt" }}
                    textTransform="capitalize">{author?.toLowerCase()}</Title>
                {/* {added_at ? <Title opacity="0.6" fontSize={{ base: "6pt", lg: "7pt" }}
                    textTransform="capitalize">{toBRLDate(new Date(added_at))}</Title> : <></>} */}
            </VStack>
        </VStack>
    );
}
