import { useQuery } from "react-query";
import { getBookCategories } from "../../../api/books";
import { HStack } from "@chakra-ui/react";
import { FilterTag } from "../../../components/molecules/FilterTag";
import { useTheme } from "../../../context";

export function BookCategoryTags({ activeTags, onSelectTag }) {
    const { id } = useTheme();
    const { data = [], isLoading } = useQuery("book-categories", () => getBookCategories(id));

    return (
        <HStack paddingTop={"20px"} paddingBottom={"20px"}  paddingLeft={{ base: "2%", lg: "11%" }} width={"100%"} overflowX={"scroll"}>
            {data.map((c) => <FilterTag key={c.id} icon={"fas fa-book"} isActive={activeTags.has(c.id)}
                onClick={() => onSelectTag(c.id)}>{c.name}</FilterTag>)}
        </HStack>
    );
}