import React, { useEffect, useState } from "react";

import { Box, useToast, VStack, HStack } from "@chakra-ui/react";
import { useQuery } from "react-query";
import { getPlans } from "../../api/plans";
import { useTheme } from "../../context";
import { TopMenu } from "../../components/organisms/TopMenu";
import { BookInfiniteList } from "../Books/components/BookInfiniteList";
import { getMyBooks } from "../../api/books";
import { useLocation } from "react-router";
import { Title } from "../../components/atoms/Title";

export default function LibraryPage() {
    const toast = useToast();
    const { state } = useLocation();
    const {bookstoreType } = useTheme();

    const [selectedBook, setSelectedBook] = useState(null);

    useEffect(() => {
        if (state?.showToast) toast({
            status: "error",
            title: "Compre recorrente Já!",
            description: `Observamos que você ainda não é um assinante. Para realizar o download ${bookstoreType === "book" ? "dos Livros" : "das Revistas"} você precisa ser um assinante ativo.`
        });
    }, [state]);


    return (
        <VStack paddingBottom={{ base: "40px", lg: "70px", "2xl": "80px" }}>
            <TopMenu />
          
            <Box padding="5%" paddingTop={"48px"} width={"100%"}>
                <HStack width={"100%"} justifyContent="flex-start">
                    <Title whiteSpace="break-spaces">
                        Olá, 
                        <br/>
                        {`${bookstoreType === "book" ? "Os livros" : "As revistas"} abaixo são todos os títulos que você iniciou ou concluiu a leitura.`}
                    </Title>
                </HStack>
                <BookInfiniteList getFunc={getMyBooks} onSelectBook={setSelectedBook}
                    params={{ search: "", category: null }} />
            </Box>
        </VStack>
    );
}
