import {
    Table,
    TableContainer,
    Tbody,
    Td,
    Text,
    Th,
    Thead,
    Tr,
    VStack
} from "@chakra-ui/react";
import React from "react";

export function RequestTable({ fields }) {
    return (
        <TableContainer width="100%">
            <Table size="sm">
                <Thead>
                    <Tr>
                        <Th>Campo</Th>
                        <Th>Descrição</Th>
                    </Tr>
                </Thead>
                <Tbody>
                    {fields.map((f) => (
                        <Tr textAlign="left">
                            <Td>{f.name}</Td>
                            <Td textAlign="left">
                                <VStack align="flex-start">
                                    <b textAlign="left">{f.type}</b>
                                    <Text
                                        textAlign="left"
                                        whiteSpace="break-spaces"
                                    >
                                        {f.description}
                                    </Text>
                                    {f.isRequired ? (
                                        <Text
                                            fontWeight="bold"
                                            fontSize="10px"
                                            color="red"
                                        >
                                            Obrigatório
                                        </Text>
                                    ) : (
                                        <></>
                                    )}
                                </VStack>
                            </Td>
                        </Tr>
                    ))}
                </Tbody>
            </Table>
        </TableContainer>
    );
}
