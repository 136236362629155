import { Data } from "@react-google-maps/api";
import { axiosInstance } from "../axios";
import { withParams } from "./discounts";

export function getProfile(agreement_id = null, navigate) {
    if (axiosInstance.defaults.headers.common["Authorization"] === null) {
        return;
    }

    let url = "/profile/";

    if (agreement_id) url += "?agreement_id=" + agreement_id;

    return axiosInstance.get(url).then(response => {
        if (response.status === 403) {
            localStorage.clear();
            navigate("/sem-vinculo");
        }

        return response;
    }).catch(error => {
        return error.response;
    });
}

export function updateEmail(email) {
    const formData = new FormData();

    formData.append("email", email);

    return axiosInstance.patch(`/user/email`, formData);
}

export function updateProfile(id, data) {
    const formData = new FormData();

    Object.entries(data).forEach(([k, v]) => {
        formData.append(k, v);
    });

    return axiosInstance.patch(`/profile/${id}/`, formData);
}

export function updateMyProfile(data) {
  return axiosInstance.patch(`/profile/my/`, data);
}

export function createProfile(data) {
    return axiosInstance.post(`/profile/`, data);
}


export function checkUserPermission(params) {
    return axiosInstance.post(withParams('/profile/check_permission/', params))
}

export function toggleFavourite(promotionId) {
    return axiosInstance.patch(`/profile/${promotionId}/toggle_favorite/`)
}

export async function getEconometer() {

    const { data } = await axiosInstance.get(`/profile/econometer/`);
    data.progress.reverse();
    return data;
}
