import { Button } from "@chakra-ui/button";
import { Image } from "@chakra-ui/image";
import { Box, Flex, Grid, Heading, Text } from "@chakra-ui/layout";
import { CircularProgress } from "@chakra-ui/progress";
import React from "react";
import { useQuery } from "react-query";
import { Link } from "react-router-dom";
import { getFeaturedDiscounts } from "../../../api/discounts";
import { useTheme } from "../../../context/index";

const HeroMobile = ({ hideDiscounts = false }) => {
    const { logo, menuColor, primaryColor } = useTheme();
    const { data = [], isLoading } = useQuery("featured", getFeaturedDiscounts);

    return (
        <Box flex="1" width="100%" height="100%">
            <Flex
                backgroundImage="url(https://partiu-static.s3.amazonaws.com/images/app-2.png)"
                width={{ base: "300px", lg: "300px" }}
                margin="auto"
                height={{ base: "280px", lg: "400px" }}
                backgroundPosition="center"
                backgroundRepeat="no-repeat"
                backgroundSize="contain"
                padding={{ base: "38px 88px", lg: "53px 60px" }}
            >
                <Flex
                    width="100%"
                    height="100%"
                    backgroundColor={menuColor}
                    flexDirection="column"
                >
                    <Image
                        padding="10px 0px"
                        src={logo}
                        width="80%"
                        margin="auto"
                    />
                    <Box
                        width="90%"
                        margin="auto"
                        height="15px"
                        backgroundColor="#eee"
                        borderRadius="10px"
                    ></Box>

                    {!hideDiscounts ? <Grid
                        templateColumns="repeat(2, 1fr)"
                        marginTop="10px"
                        flex="1"
                        backgroundColor="#eee"
                        gap={2}
                        boxSizing="border-box"
                        padding={{ base: "5px", lg: "20px 5px 0px 5px" }}
                    >
                        {isLoading === true ? (
                            <CircularProgress
                                isIndeterminate
                                trackColor={primaryColor}
                                top={{ base: "40px", lg: "80px" }}
                                left={{ base: "60%", lg: "80px" }}
                            />
                        ) : (
                            <></>
                        )}
                        {data.slice(0, 4).map((d) => (
                            <Box position="relative">
                                <Box
                                    backgroundColor={primaryColor}
                                    borderRadius="100px"
                                    position="absolute"
                                    top="2px"
                                    right="2px"
                                    fontSize={{ base: "5pt", lg: "10pt" }}
                                    padding="3px 5px"
                                >
                                    {d.discount}
                                    <Text
                                        fontSize={{ base: "3pt", lg: "4pt" }}
                                        position="absolute"
                                        bottom="4px"
                                        right="3px"
                                        fontWeight="bold"
                                        backgroundColor={primaryColor}
                                    >
                                        %
                                    </Text>
                                </Box>
                                <Image
                                    backgroundColor="white"
                                    height="55px"
                                    width="100%"
                                    src={d.company.logo}
                                    borderRadius="10px"
                                    objectFit="contain"
                                />
                            </Box>
                        ))}
                    </Grid> : <></>}
                </Flex>
            </Flex>
        </Box>
    );
};

export const Hero = ({ height, forceText, hideButton, hideDiscounts = false }) => {
    const {
        id,
        name,
        primaryColor,
        primaryFontColor,
        secondaryColor,
        wallpaper,
        welcomeText,
        welcomeTextColor
    } = useTheme();
    let style = {
        background:
            "linear-gradient(135deg, " +
            primaryColor +
            " 0%," +
            secondaryColor +
            " 100%)"
    };

    if (wallpaper)
        style = {
            background: `rgba(0, 0, 0, 0.3) url(${wallpaper})`,
            backgroundPosition: `center`,
            backgroundSize: "cover",
            backgroundBlendMode: "darken"
        };

    return (
        <Flex
            color="white"
            alignItems="center"
            flexDirection={{ base: "column", lg: "row-reverse" }}
            style={style}
            paddingTop="30px"
            boxSizing="border-box"
            paddingBottom={{ base: "0%", lg: "3%" }}
            width="100%"
        >
            <HeroMobile hideDiscounts={hideDiscounts} />
            <Flex
                justifyItems="flex-end"
                flex="1"
                alignSelf="center"
                justifyContent="center"
                minHeight={{ base: height || "37vh", lg: "auto" }}
                paddingTop={{ base: "30px", lg: "0px" }}
                width="100%"
                alignItems="center"
                marginBottom={{ base: "70px", lg: "0px" }}
            >
                <Flex
                    flexDirection="column"
                    alignItems={{ base: "center", lg: "baseline" }}
                >
                    <Heading
                        marginBottom="50px"
                        maxWidth="500px !important"
                        fontWeight="500"
                        fontFamily="Open Sans !important"
                        fontSize="32px !important"
                        padding={"0px 32px"}
                        color={
                            welcomeTextColor === null
                                ? "white"
                                : welcomeTextColor
                        }
                        lineHeight="1.5em"
                        textAlign={{ base: "center", lg: "left" }}
                    >
                        {forceText || welcomeText || (
                            <>
                                Clube de vantagens com descontos exclusivos para{" "}
                                {id === 15 ? "corretores" : "membros"} {name}
                            </>
                        )}
                    </Heading>
                    {!hideButton ? <Link to="/busca">
                        <Button size="md" fontSize="14px" color={primaryColor}>
                            VEJA OS DESCONTOS
                        </Button>
                    </Link> : <></>}
                </Flex>
            </Flex>
        </Flex>
    );
};
