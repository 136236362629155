import { Image } from "@chakra-ui/image";
import { Box, Flex, HStack } from "@chakra-ui/layout";
import React from "react";

export const Logos = (props) => {
    const images = [
        {
            src: "https://partiu-static.s3.amazonaws.com/Logos/marisa.png",
            size: "20px"
        },
        {
            src: "https://partiu-static.s3.amazonaws.com/Logos/netshoes.png",
            size: "20px"
        },
        {
            src: "https://partiu-static.s3.amazonaws.com/Logos/ponto_frio.png",
            size: "20px"
        },
        {
            src: "https://partiu-static.s3.amazonaws.com/Logos/reserva-logo.png",
            size: "20px"
        },
        {
            src: "https://partiu-static.s3.amazonaws.com/Logos/centauro-logo.png",
            size: "20px"
        }
    ];

    return (
        <Flex
            width="100%"
            flex="1"
            overflowX="auto"
            overflowY="hidden"
            borderBottom="1px solid #eee"
            minHeight="7vh"
            justify="center"
            align="center"
        >
            {images.map(({ src, size }) => (
                <Flex
                    margin="0px 10px"
                    alignItems="center"
                    justifyContent="center"
                    flex="1"
                >
                    <Image objectFit="contain" maxHeight={size} src={src} />
                </Flex>
            ))}
        </Flex>
    );
};
