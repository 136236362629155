import { Center, CircularProgress, HStack, Text, VStack } from "@chakra-ui/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import React, { useEffect, useState } from "react";
import { useTheme } from "../../../context";

function PlanProperty({ children }) {
    return (
        <HStack spacing={5}>
            <FontAwesomeIcon icon={faCheck} size="20pt" color="green" />
            <Text width="220px" fontSize="11pt" lineHeight="1.35rem">
                {children}
            </Text>
        </HStack>
    );
}

function CompanyPlanDetail({ plan, ...props }) {
    return (
        <VStack spacing={4} {...props}>
            <PlanProperty>
                <b>{plan.access_amount}</b>{" "}
                acessos para seu Clube de Vantagens.
            </PlanProperty>
            <PlanProperty>
                Mais de 1000 empresas, 2000 lojas e 5000 descontos.
            </PlanProperty>
            <PlanProperty>Utilização ilimitada para seus membros.</PlanProperty>
        </VStack>
    );
}

function UserPlanDetail({ plan, ...props }) {
    const { localModule, ecommerceModule, bookstoreModule, bookstoreType } = useTheme();
    return (
        <VStack spacing={4} {...props}>
            {bookstoreModule && plan.level === 1 ?
                <PlanProperty>{`Compre e receba ${bookstoreType === "book" ? "dois Livros" : "duas Revistas"} especial para você.`}</PlanProperty> : <></>}
            {bookstoreModule && plan.level === 2 ?
                <PlanProperty>{`Todo mês três ${bookstoreType === "book" ? "Livros" : "Revistas"} especiais para você.`}</PlanProperty> : <></>}
            {bookstoreModule && plan.level === 3 ?
                <PlanProperty>{`Todo mês quatro ${bookstoreType === "book" ? "Livros" : "Revistas"} especiais para você.`}</PlanProperty> : <></>}
            {bookstoreModule && plan.level === 1 ?
                <PlanProperty>{`Não gostou do livro? Você pode trocar por um acervo de mais de 50 ${bookstoreType === "book" ? "Livros" : "Revistas"}`}</PlanProperty> : <></>}
            {bookstoreModule && plan.level === 2 ?
                <PlanProperty>{`Não gostou do livro? Você pode trocar por um acervo de mais de 150 ${bookstoreType === "book" ? "Livros" : "Revistas"}`}</PlanProperty> : <></>}
            {bookstoreModule && plan.level === 3 ?
                <PlanProperty>{`Não gostou do livro? Você pode trocar por um acervo de mais de 250 ${bookstoreType === "book" ? "Livros" : "Revistas"}`}</PlanProperty>:<></>}
            {plan.access_amount && plan.access_amount > 0 && <PlanProperty>{`Garanta ${plan.access_amount} ${plan.access_amount > 1 ? "acessos" : "acesso"} à plataforma`}</PlanProperty>}
            {localModule ? <PlanProperty>Mais de 1000 descontos em lojas próximas de você.</PlanProperty> : <></>}
            {ecommerceModule ? <PlanProperty>Mais de 300 descontos em lojas online.</PlanProperty> : <></>}
        </VStack>
    );
}

export function PlanDetail({ plan, ...props }) {
    const [Component, setComponent] = useState();

    useEffect(() => {
        if (plan)
            setComponent(plan.type === "company" ? <CompanyPlanDetail plan={plan} {...props} /> :
                <UserPlanDetail plan={plan} {...props} />);
    }, [plan]);

    if (!plan || !Component) return <Center width={"100%"} {...props}>
        <CircularProgress isIndeterminate />
    </Center>;

    return Component;
}