import { axiosInstance } from "../axios";
import { sanitizeCpfOrCnpj } from "../utils";

export const loginSurf = async ({ username }) => {
  const sanitizedDocument = sanitizeCpfOrCnpj(username);
  return axiosInstance
    .post(`/surf/login/`, {
      document: sanitizedDocument,
      password: ""
    })
};
